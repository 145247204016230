/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RegisterInput } from "../../components/inputs/RegisterInput";
import { RegisterSelect } from "../../components/inputs/RegisterSelect";
import { useApiRequests } from "../../configs/API/ApiRequests";
import {
	operativitàOptions,
	ordineOptions,
	ospedaleOptions,
	titleOptions,
} from "../../configs/Constants";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { registerStep } from "../../store/atoms";
import { TRegisterForm } from "../../types";

const RegsiterForm = () => {
	const { t } = useTranslation();
	const { getProvinces, getProfession, getProvinceRegion, validateEmail } =
		useApiRequests();
	const [specializations, setspecializations] = useState([]);
	const [otherSpecializations, setotherSpecializations] = useState([]);
	const validateEmailMutation = useMutation({ mutationFn: validateEmail });

	const [registerData, setRegisterData] = useRecoilState(registerStep);

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		control,
		getValues,
		formState: { errors },
	} = useForm<TRegisterForm>({ defaultValues: registerData.formData });

	const watchProfessions = useWatch({ control, name: "profession" });
	const watchSpecialization = useWatch({ control, name: "specialization" });
	const watchProvience = useWatch({ control, name: "province_enployment" });
	const watchEmail = useWatch({ control, name: "email" });
	const watchEmailConfirm = useWatch({ control, name: "email_confirmation" });
	const watchPasword = useWatch({ control, name: "password" });
	const watchPaswordConfirm = useWatch({
		control,
		name: "password_confirmation",
	});

	const provincesQuery = useQuery({
		queryKey: ["provinces"],
		queryFn: getProvinces,
		select: (data) => {
			return Object.keys(data.data.data).map((prov: any) => {
				return { id: prov, name: data.data.data[prov] };
			});
		},
	});

	const onSubmit = (data: TRegisterForm) => {
		if (watchEmail !== watchEmailConfirm) {
			setError("email_confirmation", {
				message: String(t("register.registerForm.email_not_matched")),
			});
			return;
		}

		if (watchPasword !== watchPaswordConfirm) {
			setError("password_confirmation", {
				message: String(t("register.registerForm.password_not_matched")),
			});
			return;
		}

		validateEmailMutation.mutate(
			{ email: data.email },
			{
				onSuccess: (res) => {
					if (res) {
						setRegisterData({ step: 2, formData: data });
					}
				},
				onError: () => {
					setError("email", {
						type: "custom",
						message: String(t("register.registerForm.email_used")),
					});
				},
			}
		);
	};

	const professionQuery = useQuery({
		queryKey: ["profession"],
		queryFn: getProfession,
	});

	useEffect(() => {
		if (!watchProfessions) return;
		const selectedProfession = professionQuery.data?.data.data.find(
			(profess: any) => profess.name === watchProfessions
		);
		setspecializations(selectedProfession?.specializations || []);
		setotherSpecializations(selectedProfession?.specializations || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchProfessions]);

	useEffect(() => {
		if (!watchSpecialization) return;
		setotherSpecializations(
			specializations.filter((spec: any) => spec.name !== watchSpecialization)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchSpecialization]);

	useEffect(() => {
		if (!watchProvience) return;
		if (provincesQuery.status === "success") {
			getProvinceRegion(
				provincesQuery.data?.find(
					(item) => item.name === getValues("province_enployment")
				)?.id
			).then((res) => {
				setValue("regione_enployment", res.data.data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchProvience]);

	return (
		<form
			className="grid grid-cols-9 gap-x-4 gap-y-8 pt-11"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="sm:col-span-2 col-span-9 flex flex-col">
				<RegisterSelect
					errors={errors}
					name="title"
					register={register}
					options={titleOptions}
				/>
			</div>
			<div className="sm:col-span-7 col-span-9 flex sm:flex-row flex-col gap-4">
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput
						errors={errors}
						name="first_name"
						register={register}
					/>
				</div>
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput errors={errors} name="last_name" register={register} />
				</div>
			</div>

			<div className="col-span-9 flex sm:flex-row flex-col gap-4">
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput errors={errors} name="email" register={register} />
				</div>
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput
						errors={errors}
						name="email_confirmation"
						register={register}
					/>
				</div>
			</div>

			<div className="col-span-9 flex gap-4 sm:flex-row flex-col">
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput
						isPassword
						errors={errors}
						name="password"
						register={register}
					/>
				</div>
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterInput
						isPassword
						errors={errors}
						name="password_confirmation"
						register={register}
					/>
				</div>
			</div>

			<div className="col-span-9 flex gap-4 sm:flex-row flex-col">
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterSelect
						errors={errors}
						name="profession"
						register={register}
						options={professionQuery.data?.data.data || []}
					/>
				</div>
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterSelect
						errors={errors}
						options={ospedaleOptions}
						name="territory"
						register={register}
					/>
				</div>
			</div>

			<div className="col-span-9 flex gap-4">
				<div className="flex flex-col sm:w-1/2 w-full gap-3">
					<RegisterSelect
						errors={errors}
						options={specializations}
						name="specialization"
						register={register}
					/>
				</div>
				{specializations[1] && (
					<div className="flex flex-col sm:w-1/2 w-full gap-3">
						<RegisterSelect
							errors={errors}
							name="specialization_2"
							register={register}
							options={otherSpecializations}
							required={false}
						/>
					</div>
				)}
			</div>

			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterSelect
					errors={errors}
					name="employment"
					register={register}
					options={operativitàOptions}
				/>
			</div>
			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterSelect
					errors={errors}
					name="province_enployment"
					options={provincesQuery.data || []}
					register={register}
				/>
			</div>
			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterInput
					errors={errors}
					name="regione_enployment"
					register={register}
				/>
			</div>

			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterSelect
					errors={errors}
					options={ordineOptions}
					name="board_member"
					register={register}
				/>
			</div>

			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterSelect
					errors={errors}
					name="province_board"
					register={register}
					options={provincesQuery.data || []}
				/>
			</div>
			<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
				<RegisterInput
					errors={errors}
					name="board_number"
					register={register}
				/>
			</div>

			<div className="col-span-9 flex justify-center items-center pt-12">
				<Button type="submit" w="50%" variant="primary">
					{t("register.registerForm.next")}
				</Button>
			</div>

			<Link
				to={"/login"}
				className="col-span-9 flex justify-center items-center cursor-pointer"
			>
				<p className="text-base text-center text-[#323e53]">
					{t("register.registerForm.already_have_account")}
				</p>
			</Link>
		</form>
	);
};

export default RegsiterForm;
