import { useToast, Button } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { FadeBox } from "../../../components/boxes/FadeBox";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FaChevronLeft } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { selectedTab } from "../selectedTab";

interface ChangePasswordFormValues {
	old_password: string;
	password: string;
	password_confirmation: string;
}

export const ChangePassword = () => {
	const { changePassword } = useApiRequests();
	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: changePassword,
	});
	const { t } = useTranslation();
	const [, setselectedTap] = useRecoilState(selectedTab);

	const changePasswordSchema = yup.object().shape({
		old_password: yup
			.string()
			.required(`${t("change_password.password_required")}`)
			.min(8, "Old password must be at least 8 characters long"),
		password: yup
			.string()
			.required(`${t("change_password.password_required")}`)
			.min(8, `${t("change_password.new_password_length")}`),
		password_confirmation: yup
			.string()
			.required(`${t("change_password.password_required")}`)
			.oneOf(
				[yup.ref("password")],
				`${t("change_password.passwords_missmatch")}`
			),
	});
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ChangePasswordFormValues>({
		resolver: yupResolver(changePasswordSchema),
	});

	const toast = useToast();

	const onSubmit = (data: ChangePasswordFormValues) => {
		mutate(data, {
			onSuccess: (res) => {
				if (res) {
					toast({
						title: t("Your password has been changed"),
						status: "success",
					});
				}
			},
			onError: () => {
				setError("old_password", { message: "old password incorrect" });
			},
		});
	};

	return (
		<FadeBox className="lg:col-span-7 col-span-9 flex-col gap-[30px] bg-white px-3 py-[30px]">
			<p className="text-base font-semibold text-left text-blue-primary mb-4">
			<button className="m-2 text-xs" onClick={() => setselectedTap("")}>
						<FaChevronLeft />
					</button>
				{t("Change password")}
			</p>

			<div className="bg-[#F7FAFC] px-4 h-[99%] rounded-md pt-[30px] pb-4 mb-5 flex flex-col gap-[30px]">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="grid md:grid-cols-2 grid-cols-1 gap-y-[30px] gap-x-3"
				>
					<div className="flex flex-col gap-2 md:col-span-1">
						<label className="self-stretch flex-grow-0 flex-shrink-0 w-[410px] text-sm font-semibold text-left text-[#718096]">
							{t("change_password.old_password")}
						</label>
						<input
							type={"password"}
							className="border-[#bbbbbb59] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all"
							{...register("old_password")}
							placeholder={t("change_password.old_password") || ""}
						/>
						{errors.old_password && (
							<p className="text-red-500 text-sm -mt-1">
								{errors.old_password.message}
							</p>
						)}
					</div>
					<div className="col-span-2 grid md:grid-cols-2 grid-cols-1 gap-3">
						<div className="flex flex-col gap-2">
							<label className="self-stretch flex-grow-0 flex-shrink-0 w-[410px] text-sm font-semibold text-left text-[#718096]">
								{t("change_password.password")}
							</label>
							<input
								type={"password"}
								className="border-[#bbbbbb59] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all"
								{...register("password")}
								placeholder={t("New password") || ""}
							/>
							{errors.password && (
								<p className="text-red-500 text-sm -mt-1">
									{errors.password.message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2">
							<label className="self-stretch flex-grow-0 flex-shrink-0 w-[410px] text-sm font-semibold text-left text-[#718096]">
								{t("change_password.password_confirmation")}
							</label>
							<input
								type={"password"}
								className="border-[#bbbbbb59] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all"
								{...register("password_confirmation")}
								placeholder={t("Repeat new password") || ""}
							/>
							{errors.password_confirmation && (
								<p className="text-red-500 text-sm -mt-1">
									{errors.password_confirmation.message}
								</p>
							)}
						</div>
					</div>
					<div className="flex justify-center col-span-2 mt-5">
						<Button
							type="submit"
							isLoading={isLoading}
							bg={"#5B6575"}
							color="white"
							w={"255px"}
							textTransform="capitalize"
							m="0 auto"
						>
							<span className="xl:text-xs  text-[10px]">
								{t("Update password")}
							</span>{" "}
						</Button>
					</div>
				</form>
			</div>
		</FadeBox>
	);
};
