import { useUpdateLogo } from "./hooks/useUpdateLogo";
import AppRoutes from "./routes/AppRoutes";

function App() {
	useUpdateLogo();
	return (
		<div className="flex items-center justify-center w-full bg-gray-20 max-h-dvh">
			<div className="h-dvh md:min-h-dvh w-full">
				<AppRoutes />
			</div>
		</div>
	);
}

export default App;
