import { Button, FormLabel, Input, useToast } from "@chakra-ui/react";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";

export const resetPasswordSchema = yup
	.object()
	.shape({
		email: yup.string().email().required(),
	})
	.required();

type TResetPasswordInputs = {
	email: string;
};

const ResetPasswordForm = ({ setAction }: any) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TResetPasswordInputs>({
		resolver: yupResolver(resetPasswordSchema),
	});

	const { passwordResetRequest } = useApiRequests();

	const toast = useToast();

	const onSubmit: SubmitHandler<TResetPasswordInputs> = async (
		data: TResetPasswordInputs
	) => {
		mutate(data, {
			onSuccess: (data) => {
				if (data) {
					toast({
						status: "success",
						title: t("you recived an email"),
					});
				}
			},
			onError: () => {
				toast({
					status: "error",
					title: t("Email not exist"),
				});
			},
		});
		setAction({ formData: {}, step: 2 });
	};

	const { mutate } = useMutation({ mutationFn: passwordResetRequest });

	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-3">
				<FormLabel variant="user-outline" className="col-span-2">
					{t("forgot_password.form.email")}
				</FormLabel>
				<Input
					{...register("email")}
					variant="user-outline"
					placeholder="you@domain.com"
					className="col-span-2 placeholder:text-grap-20"
					type="email"
				/>
				{errors.email && <p className="text-red-500">{errors.email.message}</p>}
			</div>
			<div className="grid grid-cols-2 gap-3">
				<Button type="submit" className="col-span-2" variant="primary">
					{t("forgot_password.form.button")}
				</Button>
				<Button
					className="col-span-2"
					w="50%"
					py="11px"
					mx="auto"
					fontWeight="400"
					fontSize="14px"
					textTransform="capitalize"
					variant="unstyled"
					onClick={() => {
						navigate(-1);
					}}
				>
					{t("forgot_password.form.back")}
				</Button>
			</div>
		</form>
	);
};

export default ResetPasswordForm;
