/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from "@chakra-ui/react";
import { Dispatch, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { sidebarState } from "../../store/atoms";
import { MembersList } from "../../types";
import { FadeBox } from "./FadeBox";

interface MembersBoxProps {
	selectedUser?: any;
	users: MembersList;
	setUser?: Dispatch<any>;
	isAdmin?: boolean;
}

const MembersBox: FunctionComponent<MembersBoxProps> = ({
	selectedUser,
	users,
	setUser,
	isAdmin,
}) => {
	const { t } = useTranslation();
	const isOpen = useRecoilValue(sidebarState);

	return (
		<div
			onClick={setUser ? () => setUser(users) : () => null}
			key={users.id}
			className={`flex rounded ${isOpen ? "gap-[10px]" : "justify-center"} text-sm ${selectedUser?.id === users.id
					? "bg-gray-20"
					: "bg-white hover:bg-gray-50"
				} p-2 cursor-pointer select-none overflow-hidden`}
		>
			<Avatar
				color="white"
				bgColor="blue-primary"
				rounded={4}
				w={42}
				h={42}
				name={`${users.first_name} ${users.last_name}`}
				src={`${process.env.REACT_APP_MEDIA_URL}${users.photo}`}
				className="relative"
			>
				{isAdmin && (
					<div className="h-[21] w-[42px] absolute text-xs bottom-0 left-0 bg-red-500 text-white">
						{t("ADMIN")}
					</div>
				)}
				{/* {users.isOnline && <AvatarBadge boxSize='1em' bg='green.500' />} */}
			</Avatar>
			{/* lg:text-base  */}
			{isOpen && (
				<FadeBox className="!self-stretch">
					<div className="flex flex-col gap-1 h-full">
						<p className="text-xs leading-[14.4px] font-bold line-clamp-1">
							{users.first_name} {users.last_name}
						</p>
						<p className="text-[10px] font-thin text-blue-primary-50 leading-[12px] line-clamp-1">
							{users.profession}
						</p>
					</div>
				</FadeBox>
			)}
		</div>
	);
};

export default MembersBox;
