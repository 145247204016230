/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Tooltip,
	useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import attach from "../../../../../assets/svgs/chat/sender/attach.svg";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import { ChangeEvent, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { replyMassge, selectedChat } from "../../../../../store/atoms";
import MassageInput from "./MassageInput";
import { useTranslation } from "react-i18next";

interface AttachButtonProps {}

const AttachButton: React.FunctionComponent<AttachButtonProps> = () => {
	const [file, setfile] = useState<File>(undefined as any);
	const { t } = useTranslation();
	const input = useRef<any>(null);
	const [massage, setMassage] = useState("");
	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (!event?.target?.files?.length) return;
		const file = event.target.files[0];
		setfile(file);
	};
	const toast = useToast();
	const [chat] = useRecoilState(selectedChat);
	const { sendMassage } = useApiRequests();
	const [replyMassage, setReplyMassage] = useRecoilState(replyMassge);
	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: (data: any) => sendMassage(data),
		onError: (error: any) => {
			if (error?.response?.data?.code === 422) {
				toast({
					title: t("Error"),
					description: error?.response?.data?.error.attachment[0],
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
		},
	});

	const sendMassageHandler = () => {
		if (!massage) return;
		const formData = new FormData();
		formData.append("message", massage);
		formData.append("attachment", file);
		replyMassage.id && formData.append("reply_to", replyMassage.id);
		mutate(
			{
				communityId: chat.id,
				data: formData,
			},
			{
				onSuccess: () => {
					setMassage("");
					setfile(undefined as any);
					setReplyMassage({ id: "", name: "", massageImg: "" });
				},
			}
		);
	};

	return (
		<>
			<input
				onChange={handleFileChange}
				ref={input}
				className="hidden"
				type="file"
			/>
			<Tooltip label={t("Add media")}>
			<IconButton
				_active={{ bg: "gray-20" }}
				variant="white"
				bg="#F7FAFC"
				aria-label="attach"
				onClick={() => input.current.click()}
				mr={2}
				icon={
					<>
						<img className="w-5 h-5" src={attach} />
					</>
				}
			/>
			</Tooltip>
			<Modal
				isCentered
				onClose={() => setfile(undefined as any)}
				size="md"
				isOpen={file !== undefined}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody p="8" bgColor="gray-50">
						<div className="bg-white rounded-lg p-[10px] my-1 flex gap-[5px] items-center relative">
							{file && (
								<img
									className="w-12 h-12 rounded-lg"
									src={URL.createObjectURL(file)}
									alt={"link"}
								/>
							)}
							<div className="flex flex-col gap-[5px] break-words">
								{file && (
									<>
										<p className="font-semibold text-xs text-blue-primary-50 no-underline	">
											{file.name}
										</p>
										<p className="text-xs text-blue-primary-50 no-underline">
											{file.type} • {file.size / 1000} KB
										</p>
									</>
								)}
							</div>
						</div>
						<div className="flex justify-center items-center gap-[5px] pt-8">
							<MassageInput
								sendMassageHandler={sendMassageHandler}
								massage={massage}
								setMassage={setMassage}
								isLoading={isLoading}
								placeHolder={"Add a caption"}
							/>
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default AttachButton;
