import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
} from "@chakra-ui/react";
import NotificationBox from "../../components/boxes/NotificationBox";
import { useRecoilState } from "recoil";
import { notificationAtom } from "../../store/atoms";
import { useTranslation } from "react-i18next";

interface NotifcationDrawerProps {}

const NotifcationDrawer: React.FunctionComponent<
	NotifcationDrawerProps
> = () => {
	const [notification, setNotification] = useRecoilState(notificationAtom);
	const onClose = () => setNotification(false);
	const isOpen = notification;
	const { t } = useTranslation();

	return (
		<Drawer isOpen={isOpen} placement="right" size={"lg"} onClose={onClose}>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerHeader h="50px" className="flex items-center" bg="#EDF2F7">
					<DrawerCloseButton left={0} top={0} />
				</DrawerHeader>

				<DrawerBody p={0} px={{ base: "12px", lg: "0px" }}>
					<div className="flex flex-col">
						<div className="flex justify-between items-center w-[400px] mx-auto py-5">
							<span className="text-xl font-semibold text-blue-primary">
								{t("Notifications")} (3)
							</span>
							<span className="text-text-gray text-sm">
								{t("Mark all as read")}
							</span>
						</div>
						<div className=" w-[400px] mx-auto flex flex-col gap-4 ">
							<NotificationBox
								text="Philis Reign Invited you to join the private Community Development Structure"
								type="Invitation"
								time="2 days ago"
								img="https://images.unsplash.com/photo-1621574534327-8b9b7b5b0b0b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lraSUyMHN0cmVldCUyMHN0dWRpbyUyMHN0dWRpbyUyMHN0dWRpb3MlM"
								description="“Hi Nino, this Community is really interesting and you should join”"
							/>
							<NotificationBox
								text="Philis Reign Invited you to join the private Community Development Structure"
								type="Invitation"
								time="2 days ago"
								img="https://images.unsplash.com/photo-1621574534327-8b9b7b5b0b0b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lraSUyMHN0cmVldCUyMHN0dWRpbyUyMHN0dWRpbyUyMHN0dWRpb3MlM"
								description="“Hi Nino, this Community is really interesting and you should join”"
							/>

							<NotificationBox
								text="Philis Reign Invited you to join the private Community Development Structure"
								type="Invitation"
								time="2 days ago"
								img="https://images.unsplash.com/photo-1621574534327-8b9b7b5b0b0b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lraSUyMHN0cmVldCUyMHN0dWRpbyUyMHN0dWRpbyUyMHN0dWRpb3MlM"
								description="“Hi Nino, this Community is really interesting and you should join”"
							/>
						</div>
					</div>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default NotifcationDrawer;
