export function urlify(text: string) {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	text = text?.split("\n")?.join("<br>");
	return text?.replace(urlRegex, function (url) {
		return (
			'<a class="text-orange-primary" href="' +
			url +
			'"target="_blank">' +
			url +
			"</a>"
		);
	});
}

export function isUrlify(text: string) {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return urlRegex.test(text);
}

export function getUrl(text: string) {
	const urls = text.match(/\bhttps?:\/\/\S+/gi);
	if (urls) return urls[0];
}
