import { useEffect, useState } from "react";
import CategoryGikis from "./panels/CategoryGikis/CategoryGikis";
import GikisPanel from "./panels/GikisPanel";
import NewInvites from "./panels/NewInvites/NewInvites";
import CategoriesButton from "../../components/buttons/CategoriesButton";
import CategoriesMenu from "../../components/menus/CategoriesMenu";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../store/atoms";
import { useMediaQuery } from "@chakra-ui/react";
import SectionHeader from "./panels/NewInvites/SectionHeader";

import group from "../../assets/svgs/discover/group.svg";
import groupColored from "../../assets/svgs/discover/groupColored.svg";
import { useTranslation } from "react-i18next";

export type CustomWidget = {
	id: number;
	title: string;
	description: string;
	text_color: string;
	background_color: string;
	button_color: string;
	url: string;
	url_text: string;
} | null;

interface GikisProps {}

const Gikis: React.FunctionComponent<GikisProps> = () => {
	const { t } = useTranslation();

	const [activeCategory, setActiveCategory] = useState(t("gikis.allGikis"));
	const { getCategories, getCustomWidget } = useApiRequests();
	const { data } = useQuery({
		queryKey: ["categories"],
		queryFn: getCategories,
	});

	const [sideBarOpen, setSideBarOpen] = useRecoilState(sidebarState);
	const [isSmallScreen] = useMediaQuery("(max-width: 1024px)");

	const calculateGridWidth = (colsCount: number, gridCols: number) => {
		const widthOfContent = document.documentElement.clientWidth - 90;
		const colWidth = widthOfContent / gridCols;
		return colWidth * colsCount;
	};

	useEffect(() => {
		if (isSmallScreen) {
			setSideBarOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSmallScreen]);

	const { data: wc, isLoading } = useQuery({
		queryKey: ["giki-custom-widget"],
		queryFn: () => getCustomWidget(),
		select: (res) => res.data,
	});

	return (
		<div className="flex w-full self-start relative flex-col lg:flex-row overflow-x-hidden transition-all duration-300">
			<div
				className="bg-white hidden lg:flex lg:min-h-dvh transition-all duration-300 sticky top-0 flex-shrink-0"
				style={{
					width: sideBarOpen ? calculateGridWidth(2, 7) : 98,
				}}
			>
				{data && (
					<GikisPanel
						data={data}
						activeCategory={activeCategory}
						setActiveCategory={setActiveCategory}
						isLoading={isLoading}
						cData={wc as CustomWidget}
					/>
				)}
			</div>

			<div className="lg:hidden block flex-col gap-[30px] bg-white p-3 h-full transition-all duration-300">
				<SectionHeader>{t("Dashboard")}</SectionHeader>
				<div className="grid grid-cols-7 md:grid-cols-8 gap-3 py-4">
					<div className="col-span-1 md:col-span-3">
						<CategoriesButton
							isInvite
							icon={activeCategory === "gikis.allGikis" ? group : groupColored}
							isActive={activeCategory === "gikis.allGikis"}
							title={t("gikis.allGikis")}
							setCategory={setActiveCategory}
						/>
					</div>
					<div className="col-span-6 md:col-span-5 w-full relative category-menu self-stretch h-full">
						{data && (
							<CategoriesMenu
								CategoriesMenuData={data}
								category={activeCategory}
								setCategory={setActiveCategory}
							/>
						)}
					</div>
				</div>
			</div>
			<div
				className="w-full flex-1 h-dvh "
				style={{
					width: isSmallScreen
						? "100%"
						: sideBarOpen
						? calculateGridWidth(5, 7)
						: calculateGridWidth(1, 1) - 186,
				}}
			>
				{(() => {
					switch (activeCategory) {
						case t("gikis.allGikis"):
							return <NewInvites />;
						// case "All Gikis":
						//   return <AllGikis />;
						default:
							return <CategoryGikis title={activeCategory} data={data?.data} />;
					}
				})()}
			</div>
		</div>
	);
};

export default Gikis;
