import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const SettingsIcon = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("Profile")}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M15.0681 11.9998C15.0681 13.6943 13.6945 15.068 11.9999 15.068C10.3054 15.068 8.93176 13.6943 8.93176 11.9998C8.93176 10.3053 10.3054 8.93164 11.9999 8.93164C13.6945 8.93164 15.0681 10.3053 15.0681 11.9998Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M8.93814 19.3625L8.93812 19.3625C7.94001 18.9458 6.67474 18.9628 5.74081 19.7531L5.43384 20.0129C5.02551 20.3584 4.42048 20.3333 4.04224 19.955C3.664 19.5768 3.63885 18.9718 3.98439 18.5634L4.24501 18.2555C5.03781 17.3186 5.04332 16.0406 4.63163 15.0485C4.22051 14.0578 3.31378 13.1603 2.09255 13.0584L1.69306 13.025C1.16 12.9805 0.75 12.5349 0.75 12C0.75 11.4651 1.16 11.0195 1.69306 10.975L2.09353 10.9416C3.31417 10.8397 4.22061 9.94276 4.63213 8.95302C5.0448 7.96053 5.04025 6.68107 4.24667 5.74329L3.98711 5.43657C3.64137 5.028 3.66636 4.42266 4.04459 4.04397C4.42316 3.66496 5.02912 3.63959 5.43804 3.98564L5.74455 4.24501C6.68141 5.03781 7.95937 5.04332 8.95149 4.63163C9.94222 4.22051 10.8397 3.31378 10.9416 2.09255L10.975 1.69306C11.0195 1.16 11.4651 0.75 12 0.75C12.5349 0.75 12.9805 1.16 13.025 1.69306L13.0584 2.09353C13.1603 3.31417 14.0572 4.22061 15.047 4.63213C16.0395 5.0448 17.3189 5.04025 18.2567 4.24667L18.5634 3.98711C18.972 3.64137 19.5773 3.66636 19.956 4.0446C20.335 4.42316 20.3604 5.02912 20.0144 5.43804L19.755 5.74455C18.9622 6.68141 18.9567 7.95937 19.3684 8.95149C19.7795 9.94222 20.6862 10.8397 21.9074 10.9416L22.3069 10.975C22.84 11.0195 23.25 11.4651 23.25 12C23.25 12.5349 22.84 12.9805 22.3069 13.025L21.9065 13.0584C20.6858 13.1603 19.7794 14.0572 19.3679 15.047C18.9552 16.0395 18.9597 17.3189 19.7533 18.2567L20.0129 18.5634C20.3584 18.9718 20.3333 19.5768 19.955 19.955C19.5768 20.3333 18.9718 20.3584 18.5634 20.0129L18.2555 19.7523C17.3186 18.9595 16.0407 18.9539 15.0486 19.3656C14.0577 19.7768 13.1603 20.6836 13.0584 21.9049L13.0249 22.3069C12.9805 22.84 12.5349 23.25 11.9999 23.25C11.4651 23.25 11.0195 22.84 10.975 22.307L10.9416 21.9072C10.8401 20.6909 9.96103 19.7854 8.96367 19.3731L8.96358 19.373L8.93814 19.3625Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
    </Tooltip>
  );
};

export default SettingsIcon;
