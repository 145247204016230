/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MassageBubble from "./MassageBubble";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	meetingAtom,
	selectedMassage,
	userDataAtom,
} from "../../../../store/atoms";
import { Message } from "../../../../hooks/useMessageHandler";
import moment from "moment";
import { BottomOffset } from "./BottomOffset";
import { TopOffset } from "./TopOffset";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { pusherMassage } from "../../../../hooks/usePusherChannels";
import file from "../../../../assets/svgs/chat/file.svg";
import MessageSkeleton from "../../../../components/boxes/MessageSkeleton";
import { Box } from "@chakra-ui/react";
import TabsGroup from "../chatPannels/info/Tabs";
import TabsTitle from "../chatPannels/info/TabsTitle";
import chatInfo from "../modal/store";
import MediaGroup from "../chatPannels/media/MediaGroup";
import { Pagination } from "../../../../store/pagination.store";
import ConferenceGroup from "../chatPannels/conferences/ConferencesGroup";
import { useParams } from "react-router-dom";

interface ChatScreenProps {
	messages: Message[];
	pagination: Pagination;
	isLoading: boolean;
	loadMoreMessages: () => void;
	loadPreviousMessages: () => void;
	refreshMessages: () => void;
	firstLoad: () => void;
	grouppedMessages: { date: string; massages: Message[] }[] | undefined;
	goIntoMassage: (page: string, communityId?: string | undefined) => void;
	jumpToMessage: any;
	loadPage: any;
	isLoadPage: boolean;
	hundleRemoveMassage: (
		msgId: string
	) => UseMutationResult<AxiosResponse<any, any>, unknown, string, unknown>;
	pusherMassageHundler: (data: pusherMassage) => void;
	isMeetingComing?: boolean;
	commuintyInfo: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		string,
		unknown
	>;
}

const ChatScreen: React.FunctionComponent<ChatScreenProps> = ({
	firstLoad,
	isLoadPage,
	isLoading,
	loadMoreMessages,
	loadPreviousMessages,
	pagination,
	grouppedMessages,
	goIntoMassage,
	hundleRemoveMassage,
}) => {
	const { id } = useParams();
	const selected = useRecoilValue(chatInfo);
	const [userData] = useRecoilState(userDataAtom);
	const chatBox = useRef<any>();
	const [selectedIdMassage, setSelectMessage] = useRecoilState(selectedMassage);
	const isMountedRef = useRef(false);
	const [chatHeight, setChatHeight] = useState(
		document.documentElement.clientHeight
	);
	const [, setMeeting] = useRecoilState(meetingAtom);

	useEffect(() => {
		if (!isMountedRef.current) {
			firstLoad();
			isMountedRef.current = true;
		} else {
			// Effect code to run on subsequent renders
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (id) {
			setMeeting(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		const handleResize = () => {
			setChatHeight(document.documentElement.clientHeight);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (selectedIdMassage.id) {
			const element = document.getElementById(selectedIdMassage.id);
			if (element) {
				element.scrollIntoView({ behavior: "smooth", block: "center" });
				const parentEl = element?.parentNode as HTMLElement;

				if (parentEl) {
					parentEl.className += " bg-blue-primary-20 rounded-lg";
				}

				setTimeout(() => {
					if (parentEl) {
						parentEl.className = parentEl.className.replace(
							"bg-blue-primary-20 rounded-lg",
							""
						);
					}
					setSelectMessage({ id: "" });
				}, 5000);
			}
		}

		return () => {
			const element = document.getElementById(selectedIdMassage.id);
			const parentEl = element?.parentNode as HTMLElement;

			if (parentEl) {
				parentEl.className = parentEl.className.replace(
					"bg-blue-primary-20 rounded-lg",
					""
				);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIdMassage.id, grouppedMessages]);

	return (
		<Box
			className="bg-white rounded-lg flex flex-col-reverse overflow-auto w-full first:pb-2 flex-1 pb-2"
			style={{
				height: chatHeight - 168,
			}}
		>
			{isLoading ? (
				<div className="sm:px-[30px] scroll-smooth relative px-2 pb-5 shadow-inner flex gap-3 flex-col-reverse h-full overflow-auto">
					{Array.from(Array(8).keys()).map((item) => (
						<MessageSkeleton
							key={`skeleton_${item}_key-key_key`}
							isRightSide={item % 3 === 0}
						/>
					))}
				</div>
			) : (
				<>
					<div className="flex h-full">
						<div
							id="scrollableDiv"
							ref={chatBox}
							className={`flex-1 sm:px-[30px] scroll-smooth relative px-2 pb-5 flex flex-col-reverse h-full overflow-auto ${isLoading && "blur-md"
								}`}
						>
							{grouppedMessages && grouppedMessages.length > 0 && (
								<BottomOffset
									isLoading={isLoadPage}
									fetchPreviousPageMessages={loadPreviousMessages}
									pagination={pagination}
								/>
							)}
							{grouppedMessages?.map((group) => (
								<>
									{group.massages.map((message, i) => (
										<MassageBubble
											id={message.id}
											key={`${message.id}-massageBubble`}
											img={message.user.photo}
											first_name={message.user.first_name}
											last_name={message.user.last_name}
											massage={message.message}
											time={message.message_time}
											userId={message.user_id}
											nextMassageUserId={group["massages"][i + 1]?.user_id}
											prevMassageUserId={group["massages"][i - 1]?.user_id}
											isOwner={
												userData?.accessToken.tokenable_id === message.user_id
											}
											massageImg={
												message.attachment?.type === "Image"
													? message.attachment.url
													: undefined
											}
											replyData={message.replied_to && message.replied_to}
											replied_page={
												message.replied_page && message.replied_page
											}
											goIntoMassage={goIntoMassage}
											isPinned={message.is_pinned === 1}
											hundleRemoveMassage={hundleRemoveMassage}
											file={
												message.attachment?.type === "PDF" ||
													message.attachment?.type === "ZIP"
													? {
														url: message.attachment.url,
														name: message.attachment.name,
														size: message.attachment.size.toString(),
														type: message.attachment.type,
														img:
															message.attachment.type === "ZIP"
																? file
																: message.attachment.type === "PDF"
																	? file
																	: "",
													}
													: undefined
											}
										/>
									))}
									<div className="w-full flex justify-center items-center bg-gray-20 rounded my-4">
										<p className="py-2 text-xs text-center text-blue-primary">
											{moment(group.date).calendar({
												sameDay: "[Today]",
												nextDay: "[Tomorrow]",
												nextWeek: "DD/MM/YYYY",
												lastDay: "[Yesterday]",
												lastWeek: "DD/MM/YYYY",
												sameElse: "DD/MM/YYYY",
											})}
										</p>
									</div>
								</>
							))}
							<TopOffset
								isLoading={isLoadPage}
								pagination={pagination}
								fetchNextPageMessages={loadMoreMessages}
							/>
						</div>
						{selected !== "none" && (
							<div className="lg:w-[350px] md:w-[300px] overflow-y-auto border-l-[1px] border-l-[#EDF2F7] hidden lg:block">
								<TabsTitle />
								<div className="pt-5">
									{(() => {
										switch (selected) {
											case "info":
												return <TabsGroup />;
											case "media":
												return <MediaGroup />;
											case "live streaming":
												return <ConferenceGroup />;
										}
									})()}
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</Box>
	);
};

export const MemoizedChatScreen = React.memo(ChatScreen);
