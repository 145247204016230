import { FadeBox } from "../../components/boxes/FadeBox";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	currentOnlineUsersAtom,
	hasOpenChat,
	isMeeting,
	loadingChat,
	meetingAtom,
	replyMassge,
	selectedChat,
	showWelcomeFirstAccess,
	sidebarState,
	userDataAtom,
} from "../../store/atoms";
import { MemoizedChatHeader } from "./panels/chatScreen/ChatHeader";
import { MemoizedChatScreen } from "./panels/chatScreen/ChatScreen";
import MassageSender from "./panels/chatScreen/MassageSender";
import { useMediaQuery, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useMutation, useQuery } from "@tanstack/react-query";
import NoGikisBox from "../../components/boxes/statusBoxes/NoGikisBox";
import { useNavigate, useParams } from "react-router-dom";
import WelcomeGikiModal from "../../components/modals/WelcomeGikiModal";
import useMessageHandler from "../../hooks/useMessageHandler";
import { usePusherChannels } from "../../hooks/usePusherChannels";
import generalChatInfo from "./panels/chatPannels/info.atom";
import UseGetAdmin from "../../hooks/useGetAdmin";
import { useTranslation } from "react-i18next";
import NewMeetingBox from "./panels/chatScreen/NewMeetingBox";
import { ThemeProvider } from "styled-components";
import {
	lightTheme,
	MeetingProvider,
} from "amazon-chime-sdk-component-library-react";
import { MemoizedMeetWindow } from "../../components/meet/MeetWindow";
import { conferenceState } from "./conference.atom";
import { nearestConferenceAtom } from "../../store/NearesetConference";
interface ChatProps {}

const Chat: React.FunctionComponent<ChatProps> = () => {
	const { id } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const [, setIsOpenChat] = useRecoilState(hasOpenChat);
	const [, setIsLoadingChat] = useRecoilState(loadingChat);
	const [, setSelectedChat] = useRecoilState(selectedChat);
	const [, setReplyMassage] = useRecoilState(replyMassge);
	const [showWelcome, setShowWelcome] = useRecoilState(showWelcomeFirstAccess);
	const userData = useRecoilValue(userDataAtom);
	const {
		newMassage,
		subscribePusher,
		newMeeting,
		clearNewMeeting,
	} = usePusherChannels();
	const { ifUserAdmin } = UseGetAdmin();
	const [, setLocalConferenceAtom] = useRecoilState(conferenceState);
	const [, setNearestConference] = useRecoilState(nearestConferenceAtom);

	const { t } = useTranslation();
	const [showMeetBox, setShowMeetBox] = useRecoilState(isMeeting);

	const messagesHandler = useMessageHandler();
	const [, setSideBarOpen] = useRecoilState(sidebarState);
	const [generalInfo, setGeneralChatInfo] = useRecoilState(generalChatInfo);
	const [, setCommunityOnline] = useRecoilState(currentOnlineUsersAtom);

	const [isSmallScreen] = useMediaQuery(["(max-width: 1024px)"]);

	const { getMyCommunities, getCommunityInfos } = useApiRequests();
	const [meeting] = useRecoilState(meetingAtom);

	const communityInfo = useMutation({
		mutationFn: getCommunityInfos,
		mutationKey: ["communityInfo-more"],
		onSuccess: (data) => {
			const temp = data.data.data.conference_data["Nearest Conference"];
			if (temp) {
				setNearestConference(temp);
			} else {
				setNearestConference(undefined);
				clearNewMeeting();
			}
		},
	});

	const { data, refetch } = useQuery({
		queryKey: ["chats"],
		queryFn: getMyCommunities,
		select: (res) => res.data.data,
	});

	useEffect(() => {
		if (messagesHandler.isLoading) {
			setIsLoadingChat(true);
		} else {
			setIsLoadingChat(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messagesHandler.isLoading]);

	useEffect(() => {
		if (id) {
			setIsOpenChat(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		id === "" ? setIsOpenChat(true) : setIsOpenChat(false);
		if (id) {
			communityInfo
				.mutateAsync(id)
				.then((data) => {
					if (data.data.code === 404) {
						toast({
							title: t("this community doesn't exist"),
							status: "error",
							isClosable: true,
						});
						navigate("..");
					} else {
						const {
							allow_invite,
							members,
							created_on,
							name,
							image,
							type,
							users_count,
							conference_data,
						} = data.data.data;
						if (
							conference_data &&
							conference_data["Nearest Conference"] &&
							conference_data["Nearest Conference"].on_air
						) {
							setLocalConferenceAtom(conference_data["Nearest Conference"]);
						}

						const isAdmin = ifUserAdmin(
							members,
							userData?.accessToken.tokenable_id
								? userData?.accessToken.tokenable_id
								: ""
						);

						setSelectedChat((prev: any) => ({
							...prev,
							name,
							image,
							type,
							users_count,
							short_description: "",
							id,
							cover_image: data.data.data.cover_image,
						}));

						setGeneralChatInfo({
							isAdmin,
							members: members,
							refetchChats: generalInfo.refetchChats,
							allowInvites: allow_invite,
							createdOn: created_on,
						});
					}
				})
				.catch((error) => {
					if (error.response?.status === 404) {
						toast({
							title: t("this community doesn't exist"),
							status: "error",
							isClosable: true,
						});
						navigate("..");
					} else {
						toast({
							title: t("something went wrong"),
							status: "error",
							isClosable: true,
						});
					}
				});
		}
		setShowMeetBox(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalInfo.refetchChats]);

	useEffect(() => {
		return () => {
			setSelectedChat({
				id: "",
				name: "",
				image: "",
				cover_image: "",
				short_description: "",
				type: 0,
				users_count: 0,
				request_to_joined: 0,
				unread_messages: 0,
			});
			setNearestConference(undefined);
			clearNewMeeting();
			setIsLoadingChat(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (id !== "") {
			setCommunityOnline(0);
			setNearestConference(undefined);
			subscribePusher();
			setReplyMassage({ id: "", name: "", massageImg: "" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (newMassage) {
			messagesHandler.pusherMassageHundler(newMassage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newMassage]);

	useEffect(() => {
		if (isSmallScreen) {
			setSideBarOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSmallScreen]);

	return (
		<>
			{data?.length === 0 ? (
				<NoGikisBox
					text={t("You have not yet joined any Giki yet!")}
					buttonLink="/"
					buttonText={t("Start exploring Gikis!")}
				/>
			) : (
				<FadeBox key={id} className="flex flex-col h-dvh w-full max-h-dvh">
					<MemoizedChatHeader
						goIntoMassage={messagesHandler.goIntoMassage}
						commuintyInfo={communityInfo}
					/>
					{!communityInfo.isPending && meeting == null && (
						<NewMeetingBox
							commuintyInfo={communityInfo}
							showMeetBox={showMeetBox}
							newMeeting={newMeeting}
						/>
					)}
					{meeting && (
						<div className="flex flex-col bg-white pb-3">
							<ThemeProvider theme={lightTheme}>
								<MeetingProvider>
									<MemoizedMeetWindow
										clearNewMeeting={clearNewMeeting}
										commuintyInfo={communityInfo}
									/>
								</MeetingProvider>
							</ThemeProvider>
						</div>
					)}
					<MemoizedChatScreen
						commuintyInfo={communityInfo}
						isMeetingComing={
							!!communityInfo.data?.data.data.conference_data[
								"Nearest Conference"
							] && showMeetBox
						}
						{...messagesHandler}
					/>
					<MassageSender />
				</FadeBox>
			)}
			{!communityInfo.isPending && !communityInfo.error && (
				<WelcomeGikiModal
					id={showWelcome ? (id as string) : ""}
					onClose={() => {
						setShowWelcome(false);
					}}
				/>
			)}
		</>
	);
};

export default Chat;
