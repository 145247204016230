import { Button } from "@chakra-ui/react";
import FooterLinks from "../../../components/boxes/FooterLinks";
import ResetPasswordForm from "../../../components/forms/ResetPasswordForm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthenticationLayout from "../AuthenticationLayout";
interface ForgotPasswordProps {}

const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = () => {
	const { t } = useTranslation();
	const [resetPassword, setResetPassword] = useState({ step: 1, formData: {} });
	const navigate = useNavigate();

	return (
		<AuthenticationLayout>
			<div className="col-span-2 flex flex-col gap-[10px] max-w-[400px] mx-auto text-left">
				{resetPassword.step === 1 && (
					<React.Fragment>
						<h1 className="text-3xl font-bold text-orange-primary">
							{t("forgot_password.header")}
						</h1>
						<p className="text-blue-primary mt-12 mb-4">
							{t("forgot_password.description")}
						</p>
						<ResetPasswordForm setAction={setResetPassword} />
					</React.Fragment>
				)}
				{resetPassword.step === 2 && (
					<React.Fragment>
						<h1 className="text-3xl font-bold text-orange-primary">
							{t("Check your email")}
						</h1>
						<div className="flex flex-col gap-7 pt-12">
							<p className="text-blue-primary">
								<span>
									{t(
										"We have sent a password recover instructions to your email."
									)}
								</span>
							</p>
						</div>
						<Button
							onClick={() => navigate("/login")}
							variant="primary"
							mx="auto"
							className="mt-4"
						>
							{t("Go to Login")}
						</Button>
						<Button
							className="col-span-2"
							py="11px"
							mx="auto"
							onClick={() => navigate("/login")}
							fontWeight="400"
							fontSize="14px"
							textTransform="capitalize"
							color="#262626"
							variant="unstyled"
						>
							{t("Skip, I’ll confirm later")}
						</Button>
					</React.Fragment>
				)}
			</div>
			<FooterLinks />
		</AuthenticationLayout>
	);
};

export default ForgotPassword;
