import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Authentication/login/Login";
import Register from "../pages/Authentication/register/Register";
import ActiveAccount from "../pages/Authentication/active/ActiveAccount";
import ForgotPassword from "../pages/Authentication/forgot-password/ForgotPassword";
import ChangePassword from "../pages/Authentication/change-password/ChangePassword";
import About from "../pages/Authentication/about/About";
import Privacy from "../pages/Authentication/privacy/Privacy";
import Terms from "../pages/Authentication/terms/Terms";
import FAQ from "../pages/Authentication/faq/Faq";
import AlreadyLoggedInRedirect from "./AlreadyLoggedInRedirect";
import { Consent } from "../pages/Authentication/Consent";
import AppLayout from "../components/layout/app-layout";
import ProtectedRoute from "./ProtectedRoute";
import Members from "../pages/members/Members";
import Gikis from "../pages/gikis/Gikis";
import Chat from "../pages/chat/Chat";
import { Profile } from "../pages/profile/Profile";
import ChatLayout from "../pages/chat/ChatLayout";
import NoChat from "../pages/chat/NoChat";
import CarouselExample from "../components/carousel/CarouselExample";

const useRoutes = () => {
	return createBrowserRouter([
		{
			path: "*",
			element: <Login />,
		},
		{
			path: "register",
			element: <Register />,
		},
		{
			path: "user-activation",
			element: <ActiveAccount />,
		},
		{
			path: "forgot-password",
			element: <ForgotPassword />,
		},
		{
			path: "password-reset",
			element: <ChangePassword />,
		},
		{
			path: "about",
			element: <About />,
		},
		{
			path: "privacy",
			element: <Privacy />,
		},
		{
			path: "privacy-xgate",
			element: <Privacy />,
		},
		{
			path: "terms",
			element: <Terms />,
		},
		{
			path: "faq",
			element: <FAQ />,
		},
		{
			path: "consent",
			element: (
				<AlreadyLoggedInRedirect>
					<Consent />
				</AlreadyLoggedInRedirect>
			),
		},
		{
			path: "/",
			element: <AppLayout />,
			children: [
				{
					path: "members",
					element: <Members />,
				},
				{
					path: "",
					element: <ProtectedRoute />,
					children: [
						{
							path: "",
							element: <Gikis />,
						},
						{
							path: "chat",
							element: <ChatLayout />,
							children: [
								{
									path: "",
									element: <NoChat />,
								},
								{
									path: ":id",
									element: <Chat />,
								},
							],
						},
						{
							path: "profile",
							element: <Profile />,
						},
					],
				},
			],
		},
		{
			path: "/test",
			element: <CarouselExample />,
		},
	]);
};

export default useRoutes;
