import { Box, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FadeBox } from "../../components/boxes/FadeBox";
import MemberSkeleton from "../../components/boxes/MemberSkeleton";
import IntroBox from "../../components/boxes/statusBoxes/IntroBox";
import LeftMenu from "../../components/menus/LeftMenu";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { sidebarState, userSelected } from "../../store/atoms";
import MembersPanel from "./panels/MembersPanel";
import UserPanel from "./panels/UserPanel";
import { useTranslation } from "react-i18next";

interface MembersProps { }

const Members: React.FunctionComponent<MembersProps> = () => {
	const [selectedUser, setSelectedUser] = useRecoilState(userSelected);
	const { getUserMembers } = useApiRequests();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useQuery({
		queryKey: ["membersList"],
		queryFn: getUserMembers,
		select: (res) => res.data.data,
	});
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		selectedUser.id === "" ? onOpen() : onClose();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser?.id]);

	useEffect(() => {
		if (location.state !== null) {
			setSelectedUser(location.state);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state]);

	const [sideBarOpen, setSideBarOpen] = useRecoilState(sidebarState);
	const [isTabletScreen, isSmallScreen] = useMediaQuery([
		"(max-width: 768px)",
		"(max-width: 1024px)",
	]);

	useEffect(() => {
		if (isSmallScreen) {
			setSideBarOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSmallScreen]);

	return (
		<div className="flex w-full">
			<div
				className={`bg-white ${isOpen ? "block" : "hidden"} ${isTabletScreen && "!w-full"
					} md:hidden lg:block transition-all duration-300 flex-shrink-0 ${!isTabletScreen && isSmallScreen
						? 0
						: sideBarOpen
							? "w-[calc(((100vw-90px)/7)*2)] max-w-full"
							: "w-[98px]"
					}`}
			>
				<LeftMenu title={t("Members")}>
					{isLoading ? (
						<MemberSkeleton reapeted={4} />
					) : (
						<MembersPanel
							data={data}
							selectedUser={selectedUser}
							setUser={setSelectedUser}
						/>
					)}
				</LeftMenu>
			</div>

			{isOpen && (
				<FadeBox
					className="hidden md:flex lg:hidden absolute z-50 h-dvh"
					key="MassagePanels"
				>
					<div className="pl-2 pr-3 bg-gray-50 flex flex-col w-[calc(((100vw-90px)/7)*3)] max-w-full">
						<div className="py-7">
							<p className="text-blue-primary font-semibold text-xl">
								{t("Members")}
							</p>
						</div>
						{isLoading ? (
							<MemberSkeleton reapeted={4} />
						) : (
							<MembersPanel
								data={data}
								selectedUser={selectedUser}
								setUser={setSelectedUser}
							/>
						)}
					</div>
					<Box
						className="w-[calc(((100vw-90px)/7)*4)]"
						bgColor="blackAlpha.600"
					/>
				</FadeBox>
			)}

			<div
				className={`bg-white flex flex-col gap-[10px] ${!isOpen ? "block flex-1 w-[calc(((100vw-90px)/7)*4)]" : "w-full"
					} md:block h-[calc(100dvh-75px-65px)] md:!h-dvh overflow-y-scroll`}
			>
				{selectedUser.id ? (
					<UserPanel user={selectedUser} />
				) : (
					<IntroBox
						text={t("Select a member from the list to view their profile")}
					/>
				)}
			</div>
		</div>
	);
};

export default Members;
