import { Members } from "pusher-js";
import { atom } from "recoil";

type GeneralChatInfoType = {
  isAdmin: boolean;
  members: Members[];
  refetchChats: boolean;
  createdOn: string;
  allowInvites: boolean;
};

const generalChatInfo = atom<GeneralChatInfoType>({
  key: "isUserAdmin",
  default: {
    isAdmin: false,
    members: [],
    refetchChats: false,
    createdOn: "",
    allowInvites: false,
  },
});

export default generalChatInfo;
