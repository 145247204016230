import { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { sidebarState } from "../../store/atoms";

interface MemberSkeletonProps {
	reapeted?: number;
	className?: string;
}

const MemberSkeleton: FunctionComponent<MemberSkeletonProps> = ({
	reapeted,
	className,
}) => {
	const isOpen = useRecoilValue(sidebarState);

	const reapetedArr = Array.from(Array(reapeted || 1).keys());
	return (
		<div className={`flex flex-col gap-3 ${className}`}>
			{reapetedArr.map((i) => (
				<div
					key={`member_${i}`}
					className="border shadow rounded p-2 w-full mx-auto"
				>
					<div className={`animate-pulse flex ${isOpen ? "space-x-4" : "justify-center"} items-center`}>
						<div className="rounded bg-slate-700 h-[42px] w-[42px]"></div>
						{isOpen && (
							<div className="flex-1 space-y-6 py-1">
								<div className="h-2 bg-slate-700 rounded w-[100px]"></div>
								<div className="h-2 bg-slate-700 rounded w-[40px]"></div>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default MemberSkeleton;
