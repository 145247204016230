import { atom } from "recoil";

export type ConferenceType =
	| {
			id: string;
			title: string;
			description: string;
			start_date: string;
			label_start_date: string;
			duration: number;
			attendees: number;
			on_air: boolean;
			is_enrolled: boolean;
			created_by: string;
	  }
	| undefined;

export const conferenceState = atom<ConferenceType>({
	default: undefined,
	key: "conferenceStateApiState",
});
