import Close from "../../../../../components/icons/Close";
import { useRecoilState } from "recoil";
import chatInfo from "../../modal/store";

const TabsTitle = () => {
	const [selected, setSelected] = useRecoilState(chatInfo);

	return (
		<div className="flex items-center justify-between p-5 border-b-[1px] border-b-[#EDF2F7]">
			<span className="text-base text-blue-primary font-bold leading-[19.2px]">
				{selected.toLocaleUpperCase()}
			</span>
			<button
				onClick={() => setSelected("none")}
				className="!w-[30px] !h-[30px] rounded border-[1px] border-[#CBD5E0] lg:flex items-center justify-center hidden "
			>
				<Close />
			</button>
		</div>
	);
};

export default TabsTitle;
