import { Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Conferences from "../../../../../components/icons/Conferences";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import { conferenceState } from "../../../conference.atom";
import generalChatInfo from "../info.atom";

const ConferenceCard = ({
	date,
	description,
	isLive = false,
	title,
	attendees,
	confId,
	isNext = false,
	is_enrolled,
}: {
	isLive?: boolean;
	title: string;
	date: string;
	description: string;
	attendees?: number;
	isNext?: boolean;
	confId: string;
	is_enrolled: boolean;
}) => {
	const { id } = useParams();
	const toast = useToast();
	const conferenceData = useRecoilValue(conferenceState);
	const { t } = useTranslation();

	const generalInfo = useRecoilValue(generalChatInfo);
	const { deleteConference, enrollConference } = useApiRequests();
	const queryClient = useQueryClient();

	const handleEnroll = useMutation({
		mutationFn: (meetId: string) => enrollConference(meetId),
		onSuccess: async () => {
			toast({
				status: "success",
				title: t("enrolled successfully"),
			});
			await queryClient.invalidateQueries();
		},
		onError: () => {
			toast({
				status: "error",
				title: t("something went wrong"),
			});
		},
	});
	const handleDeleteMeeting = useMutation({
		mutationFn: () => deleteConference(id || "", confId),
		onSuccess: async () => {
			toast({
				status: "success",
				title: t("deleted successfully"),
			});
			await queryClient.invalidateQueries();
		},
		onError: () => {
			toast({
				status: "error",
				title: t("something went wrong"),
			});
		},
	});

	return (
		<div
			className={`rounded p-4 flex flex-col gap-[10px] ${
				isLive ? "bg-text-gray" : "bg-gray-50"
			}`}
		>
			<div className="flex gap-2">
				<div
					className={`w-[42px] h-[42px] rounded ${
						isLive && "bg-gray-50"
					} flex items-center justify-center`}
				>
					<Conferences
						fillActiveColor={"fill-blue-primary"}
						strokeActiveColor={"stroke-blue-primary"}
						fillAccentColor={"fill-white"}
					/>
				</div>
				<div
					className={`${
						!isLive ? "justify-center" : "justify-between"
					} flex flex-col self-stretch`}
				>
					<span
						className={`text-sm leading-[16.8px] ${
							isLive ? "text-white" : "text-blue-primary"
						} font-bold`}
					>
						{title}
					</span>
					{isLive && (
						<p className="text-xs leading-[18px] text-[#EDF2F7]">{date}</p>
					)}
				</div>
			</div>
			{!isLive && (
				<div className="text-xs leading-[18px] text-text-gray">
					<p>{date}</p>
					{/* <p>Duration: {duration} minutes</p> */}
					<p>
						{t("Attendees")}: {attendees}
					</p>
				</div>
			)}
			<div
				className={`text-[10px] leading-3 ${
					isLive ? "text-white" : "text-text-gray"
				}`}
			>
				{description}
			</div>
			{isNext && !generalInfo.isAdmin && (
				<Button
					className="w-fit self-end !text-white"
					size={"xs"}
					bg="orange-primary"
					isLoading={handleEnroll.isPending}
					isDisabled={is_enrolled}
					onClick={() => handleEnroll.mutateAsync(confId)}
				>
					{is_enrolled ? t("Enrolled") : t("Enroll")}
				</Button>
			)}
			{isNext && generalInfo.isAdmin && (
				<Button
					className="w-fit self-end !text-white"
					size={"xs"}
					bg="orange-primary"
					onClick={() => handleDeleteMeeting.mutateAsync()}
					isDisabled={
						handleDeleteMeeting.isPending ||
						isLive ||
						(conferenceData?.id === confId && conferenceData.on_air)
					}
				>
					{t("Delete")}
				</Button>
			)}
		</div>
	);
};

const ConferenceCardSkeleton = () => {
	return (
		<div className={`rounded p-4 flex flex-col gap-[10px] ${"bg-gray-50"}`}>
			<div className="flex gap-2">
				<div
					className={`w-[42px] h-[42px] rounded flex items-center justify-center`}
				>
					<Conferences
						fillActiveColor={"fill-blue-primary"}
						strokeActiveColor={"stroke-blue-primary"}
						fillAccentColor={"fill-white"}
					/>
				</div>
				<div className={` flex flex-col self-stretch justify-between`}>
					<span
						className={`text-sm leading-[16.8px] text-blue-primary font-bold h-4 w-20 bg-slate-400 animate-pulse duration-500 rounded`}
					/>

					<p className="text-xs leading-[18px] text-[#EDF2F7] h-4 w-32 bg-slate-400 animate-pulse duration-500 rounded" />
				</div>
			</div>
			<div className="flex flex-col gap-1">
				<div
					className={`text-[10px] leading-3 text-text-gray w-full h-4 bg-slate-400 animate-pulse duration-500 rounded`}
				/>

				<div
					className={`text-[10px] leading-3 text-text-gray w-full h-4 bg-slate-400 animate-pulse duration-500 rounded`}
				/>

				<div
					className={`text-[10px] leading-3 text-text-gray w-full h-4 bg-slate-400 animate-pulse duration-500 rounded`}
				/>
				<div
					className={`text-[10px] leading-3 text-text-gray w-full h-4 bg-slate-400 animate-pulse duration-500 rounded`}
				/>
			</div>

			<Button
				className="self-end !text-white h-6 w-16 bg-slate-400 animate-pulse duration-500 rounded"
				size={"xs"}
				bg="gray-primary"
				isDisabled={true}
			></Button>
		</div>
	);
};

export { ConferenceCardSkeleton };

export default ConferenceCard;
