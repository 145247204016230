/* eslint-disable react-hooks/exhaustive-deps */
import {
	useLocalVideo,
	useMeetingManager,
	useContentShareControls,
	useToggleLocalMute,
	useContentShareState,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import React, { useEffect, useState } from "react";
import { Tooltip, useMediaQuery } from "@chakra-ui/react";
import { MeetingAttendees } from "./MeetingAttendees";
import { useRecoilState } from "recoil";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { VscDebugDisconnect } from "react-icons/vsc";
import { AxiosError, AxiosResponse } from "axios";
import { useApiRequests } from "../../configs/API/ApiRequests";
import {
	isMeeting,
	meetingAtom,
	// selectedChat,
	userDataAtom,
} from "../../store/atoms";
import { useMeetingStatus } from "../../hooks/useMeetingStatus";
import MobileMeetHeader from "../../pages/chat/MobileMeetHeader";
import { useFullscreen } from "../../hooks/useFullscreen";
import { conferenceState } from "../../pages/chat/conference.atom";
import { useTranslation } from "react-i18next";
import { nearestConferenceAtom } from "../../store/NearesetConference";
import { useParams } from "react-router-dom";

type TNearestConference = {
	id: string;
	title: string;
	status: number;
	description: string;
	start_date: string;
	label_start_date: string;
	duration: number;
	attendees: number;
	on_air: boolean;
	is_enrolled: boolean;
	created_by: string;
};

interface MeetWindowProps {
	commuintyInfo: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		string,
		unknown
	>;
	clearNewMeeting?: () => void;
}

const MeetWindow: React.FunctionComponent<MeetWindowProps> = ({
	commuintyInfo,
	clearNewMeeting,
}) => {
	const { id } = useParams();
	const meetingManager = useMeetingManager();
	const { joinMeet, leaveMeeting, getConference, endConference } =
		useApiRequests();
	const [attendee, setAttendee] = useState<any>();
	const { toggleVideo } = useLocalVideo();
	const { toggleContentShare } = useContentShareControls();
	const { muted, toggleMute } = useToggleLocalMute();
	const { fullscreen, ref, toggle } = useFullscreen();
	// const [chat] = useRecoilState(selectedChat);
	const [meeting, setMeeting] = useRecoilState(meetingAtom);
	const [, setShowBox] = useRecoilState(isMeeting);
	const [admin, setAdmin] = useState([]);
	const [userData] = useRecoilState(userDataAtom);
	const contentShare = useContentShareState();
	const [isSmallScreen] = useMediaQuery(["(max-width: 768px)"]);
	const [, setNearestConference] = useRecoilState(nearestConferenceAtom);
	const [localConferenceInfo, setLocalConferenceAtom] =
		useRecoilState(conferenceState);

	const { t } = useTranslation();

	useEffect(() => {
		if (commuintyInfo.data?.data.data.members) {
			const admins = commuintyInfo.data?.data.data.members.filter(
				(user: any) =>
					user.id === userData?.accessToken.tokenable_id && user.is_admin === 1
			);

			setAdmin(admins);
		}
	}, [commuintyInfo.data?.data.data]);

	useEffect(() => {
		return () => {
			getNearestConference.mutateAsync().finally(() => {});
			meetingManager.leave();
			clearNewMeeting && clearNewMeeting();
		};
	}, []);

	const [chatHeight, setChatHeight] = useState(
		document.documentElement.clientHeight
	);

	const getNearestConference = useMutation({
		mutationFn: () => getConference(id || ""),
		onSuccess: (data: AxiosResponse) => {
			if (data.status === 200) {
				if (data.data.data) {
					setLocalConferenceAtom(data.data.data);
					setNearestConference(data.data.data);
				} else {
					setLocalConferenceAtom(undefined);
					setNearestConference(undefined);
				}
				setTimeout(() => {
					setShowBox(true);
				}, 300);
			}
		},
		onError: (error: AxiosError) => {
			setNearestConference(undefined);
		},
	});

	const joinMeeting = async (attendee: any) => {
		(window as any).global = window;

		const meetingSessionConfiguration = new MeetingSessionConfiguration(
			meeting,
			attendee
		);

		meetingSessionConfiguration &&
			(await meetingManager.join(meetingSessionConfiguration));

		await meetingManager.start();
	};

	const joinMeetingAttend = useMutation({
		mutationFn: (conferenceId: string) => joinMeet(conferenceId),
		onSuccess: (data: any) => {
			setAttendee(data.data.data.attend);
			setShowBox(false);
			joinMeeting(data.data.data.attend);
		},
	});

	const { meetingStatus } = useMeetingStatus({ meetingManager });

	useEffect(() => {
		// conference ended
		if (meetingStatus === 3) {
			setShowBox(false);
			getNearestConference.mutateAsync().catch((E) => {});
		}
	}, [meetingStatus]);

	useEffect(() => {
		if (meeting !== null) return;
		meetingManager.leave();
	}, [meeting]);

	const handleCloseMeeting = useMutation({
		mutationFn: () =>
			leaveMeeting({
				id: attendee.AttendeeId,
				MeetId: localConferenceInfo?.id || "",
			}),
		onSuccess: () => {
			getNearestConference.mutateAsync().finally(() => {
				setMeeting(null);
				meetingManager.leave();
			});
		},
	});

	const handleDeleteMeeting = useMutation({
		mutationFn: () => endConference(localConferenceInfo?.id || ""),
		onSuccess: () => {
			getNearestConference.mutateAsync().finally(() => {
				setMeeting(null);
				clearNewMeeting && clearNewMeeting();
				meetingManager.leave();
			});
		},
		onError: async () => {
			getNearestConference.mutateAsync().finally(() => {
				setMeeting(null);
				clearNewMeeting && clearNewMeeting();
				meetingManager.leave();
			});
		},
	});

	const [cameraActive, setCameraActive] = useState(false);

	const toggleShareScreen = () => {
		toggleContentShare();
		// setIsSharing(!isSharing)
	};

	useEffect(() => {
		getNearestConference
			.mutateAsync()
			.then((data: { data: { data: TNearestConference } }) => {
				if (data.data && data.data.data.on_air) {
					setLocalConferenceAtom(data.data.data);
					joinMeetingAttend.mutate(data.data.data.id);
				}
			})
			.catch((err) => {
				setLocalConferenceAtom(undefined);
			})
			.finally(() => {});

		const handleResize = () => {
			setChatHeight(document.documentElement.clientHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div
				ref={ref}
				className={
					"bg-[#171923] flex-col justify-between  rounded-b-xl flex md:pb-4"
				}
				style={{
					height: meeting && !isSmallScreen && (chatHeight - 168) / 2,
				}}
			>
				{isSmallScreen && !fullscreen ? (
					<div className="flex items-center justify-between bg-blue-primary p-5">
						<MobileMeetHeader />
						<div
							className={`flex gap-3 !max-h-fit !shadow-none !bg-transparent !h-fit ${
								admin.length > 0 ? "ml-auto" : ""
							}`}
						>
							<Tooltip label={t("End call")}>
								<button
									onClick={() => {
										handleCloseMeeting.mutate();
									}}
									className="w-6 h-6 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-white bg-[#c53030]"
								>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.94301 4.11934L11.2763 2.78601M11.2763 2.78601L12.6097 1.45268M11.2763 2.78601L9.94301 1.45268M11.2763 2.78601L12.6097 4.11934M2.6097 0.786011C1.87332 0.786011 1.27637 1.38296 1.27637 2.11934V2.78601C1.27637 8.30886 5.75352 12.786 11.2764 12.786H11.943C12.6794 12.786 13.2764 12.1891 13.2764 11.4527V9.26652C13.2764 8.97956 13.0927 8.7248 12.8205 8.63406L9.82489 7.63552C9.51015 7.53061 9.16616 7.67309 9.01779 7.96983L8.26537 9.47467C6.63555 8.73984 5.32253 7.42682 4.5877 5.79701L6.09255 5.04459C6.38929 4.89622 6.53177 4.55223 6.42686 4.23749L5.42832 1.24186C5.33757 0.969631 5.08281 0.786011 4.79586 0.786011H2.6097Z"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</Tooltip>

							<Tooltip label={muted ? t("Unmute") : t("Mute")}>
								<button
									onClick={() => {
										toggleMute();
									}}
									className={`w-6 h-6 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-[#171923] ${
										!muted ? "bg-[#e2e8f0]" : "bg-[#4A5568]"
									}`}
								>
									{muted ? (
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.6666 7.33333C12.6666 9.91066 10.5772 12 7.99992 12M7.99992 12C5.42259 12 3.33325 9.91066 3.33325 7.33333M7.99992 12V14.6667M7.99992 14.6667H5.33325M7.99992 14.6667H10.6666M7.99992 9.33333C6.89535 9.33333 5.99992 8.4379 5.99992 7.33333V3.33333C5.99992 2.22876 6.89535 1.33333 7.99992 1.33333C9.10449 1.33333 9.99992 2.22876 9.99992 3.33333V7.33333C9.99992 8.4379 9.10449 9.33333 7.99992 9.33333Z"
												stroke="#718096"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M14.3988 1L1.00012 14.3987"
												stroke="#718096"
												strokeWidth="1.2"
												strokeLinecap="round"
											/>
										</svg>
									) : (
										<svg
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g clipPath="url(#clip0_1729_19523)">
												<path
													d="M12.9427 8.11934C12.9427 10.6967 10.8534 12.786 8.27604 12.786M8.27604 12.786C5.69871 12.786 3.60938 10.6967 3.60938 8.11934M8.27604 12.786V15.4527M8.27604 15.4527H5.60938M8.27604 15.4527H10.9427M8.27604 10.1193C7.17147 10.1193 6.27604 9.22391 6.27604 8.11934V4.11934C6.27604 3.01477 7.17147 2.11934 8.27604 2.11934C9.38061 2.11934 10.276 3.01477 10.276 4.11934V8.11934C10.276 9.22391 9.38061 10.1193 8.27604 10.1193Z"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</g>
											<defs>
												<clipPath id="clip0_1729_19523">
													<rect
														width="16"
														height="16"
														fill="white"
														transform="translate(0.276367 0.786011)"
													/>
												</clipPath>
											</defs>
										</svg>
									)}{" "}
								</button>
							</Tooltip>

							{admin.length > 0 && (
								<Tooltip label={t("Share Screen")}>
									<button
										disabled
										className={`w-6 h-6 flex-1 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-[#171923] ${
											!contentShare.tileId ? "bg-[#4A5568]" : "bg-[#e2e8f0]"
										}`}
									>
										<svg
											width="13"
											height="13"
											viewBox="0 0 13 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M11.6094 4.11935L11.6094 3.45268C11.6094 2.34811 10.7139 1.45268 9.60937 1.45268L2.94271 1.45268C1.83814 1.45268 0.942708 2.34811 0.942708 3.45268L0.942708 4.11935M3.60937 6.78602L6.27604 4.11935M6.27604 4.11935L8.94271 6.78602M6.27604 4.11935L6.27604 12.1193"
												stroke="#718096"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</Tooltip>
							)}
							<Tooltip label={t("full screen")}>
								<button
									className="w-6 h-6 hover:bg-opacity-70 transition-all duration-500 rounded-lg flex justify-center items-center bg-[#4A5568]"
									onClick={toggle}
								>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.49612 0.786011H12.4961V4.78601"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M4.99998 12.814L1 12.814L1 8.81403"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M12.4961 8.77639L12.4961 12.7764L8.49609 12.7764"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M1 5.01664L1 1.01666L5 1.01666"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
									</svg>
								</button>
							</Tooltip>
							{admin.length > 0 && (
								<Tooltip label={t("End meeting")}>
									<button
										className="w-6 h-6 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-white bg-[#c53030]"
										onClick={() => handleDeleteMeeting.mutate()}
									>
										<VscDebugDisconnect />
									</button>
								</Tooltip>
							)}
						</div>
					</div>
				) : (
					<>
						<MeetingAttendees isFullScreen={fullscreen} />
						<div className="flex justify-center items-center h-fit flex-shrink-0">
							<div
								className={`flex gap-3 !max-h-fit !shadow-none !bg-transparent !h-fit !min-h-fit ${
									admin.length > 0 ? "ml-auto" : ""
								}`}
							>
								<Tooltip label={t("End call")}>
									<button
										onClick={() => {
											handleCloseMeeting.mutate();
										}}
										className="w-12 h-12 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-white bg-[#c53030]"
									>
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.94301 4.11934L11.2763 2.78601M11.2763 2.78601L12.6097 1.45268M11.2763 2.78601L9.94301 1.45268M11.2763 2.78601L12.6097 4.11934M2.6097 0.786011C1.87332 0.786011 1.27637 1.38296 1.27637 2.11934V2.78601C1.27637 8.30886 5.75352 12.786 11.2764 12.786H11.943C12.6794 12.786 13.2764 12.1891 13.2764 11.4527V9.26652C13.2764 8.97956 13.0927 8.7248 12.8205 8.63406L9.82489 7.63552C9.51015 7.53061 9.16616 7.67309 9.01779 7.96983L8.26537 9.47467C6.63555 8.73984 5.32253 7.42682 4.5877 5.79701L6.09255 5.04459C6.38929 4.89622 6.53177 4.55223 6.42686 4.23749L5.42832 1.24186C5.33757 0.969631 5.08281 0.786011 4.79586 0.786011H2.6097Z"
												stroke="white"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</Tooltip>

								<Tooltip label={t("toggle video")}>
									<button
										onClick={() => {
											setCameraActive(!cameraActive);
											toggleVideo();
										}}
										className={`w-12 h-12 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-[#171923] ${
											cameraActive ? "bg-[#e2e8f0]" : "bg-[#4A5568]"
										} `}
									>
										{cameraActive ? (
											<svg
												width="14"
												height="10"
												viewBox="0 0 14 10"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M9.27637 3.45268L12.3116 1.93508C12.7548 1.71345 13.2764 2.03578 13.2764 2.53137V7.04065C13.2764 7.53624 12.7548 7.85857 12.3116 7.63694L9.27637 6.11934M2.6097 8.78601H7.94303C8.67941 8.78601 9.27637 8.18906 9.27637 7.45268V2.11934C9.27637 1.38296 8.67941 0.786011 7.94303 0.786011H2.6097C1.87332 0.786011 1.27637 1.38296 1.27637 2.11934V7.45268C1.27637 8.18906 1.87332 8.78601 2.6097 8.78601Z"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											<svg
												width="17"
												height="16"
												viewBox="0 0 17 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10.2764 6.66667L13.3116 5.14907C13.7548 4.92744 14.2764 5.24977 14.2764 5.74536V10.2546C14.2764 10.7502 13.7548 11.0726 13.3116 10.8509L10.2764 9.33333M3.6097 12H8.94303C9.67941 12 10.2764 11.403 10.2764 10.6667V5.33333C10.2764 4.59695 9.67941 4 8.94303 4H3.6097C2.87332 4 2.27637 4.59695 2.27637 5.33333V10.6667C2.27637 11.403 2.87332 12 3.6097 12Z"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										)}
									</button>
								</Tooltip>

								<Tooltip label={muted ? t("Unmute") : t("Mute")}>
									<button
										onClick={() => {
											toggleMute();
										}}
										className={`w-12 h-12 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-[#171923] ${
											!muted ? "bg-[#e2e8f0]" : "bg-[#4A5568]"
										}`}
									>
										{muted ? (
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.6666 7.33333C12.6666 9.91066 10.5772 12 7.99992 12M7.99992 12C5.42259 12 3.33325 9.91066 3.33325 7.33333M7.99992 12V14.6667M7.99992 14.6667H5.33325M7.99992 14.6667H10.6666M7.99992 9.33333C6.89535 9.33333 5.99992 8.4379 5.99992 7.33333V3.33333C5.99992 2.22876 6.89535 1.33333 7.99992 1.33333C9.10449 1.33333 9.99992 2.22876 9.99992 3.33333V7.33333C9.99992 8.4379 9.10449 9.33333 7.99992 9.33333Z"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M14.3988 1L1.00012 14.3987"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
												/>
											</svg>
										) : (
											<svg
												width="17"
												height="17"
												viewBox="0 0 17 17"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_1729_19523)">
													<path
														d="M12.9427 8.11934C12.9427 10.6967 10.8534 12.786 8.27604 12.786M8.27604 12.786C5.69871 12.786 3.60938 10.6967 3.60938 8.11934M8.27604 12.786V15.4527M8.27604 15.4527H5.60938M8.27604 15.4527H10.9427M8.27604 10.1193C7.17147 10.1193 6.27604 9.22391 6.27604 8.11934V4.11934C6.27604 3.01477 7.17147 2.11934 8.27604 2.11934C9.38061 2.11934 10.276 3.01477 10.276 4.11934V8.11934C10.276 9.22391 9.38061 10.1193 8.27604 10.1193Z"
														stroke="#718096"
														strokeWidth="1.2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</g>
												<defs>
													<clipPath id="clip0_1729_19523">
														<rect
															width="16"
															height="16"
															fill="white"
															transform="translate(0.276367 0.786011)"
														/>
													</clipPath>
												</defs>
											</svg>
										)}{" "}
									</button>
								</Tooltip>

								{admin.length > 0 && (
									<Tooltip label={t("Share Screen")}>
										<button
											onClick={() => {
												toggleShareScreen();
											}}
											className={`w-12 h-12 flex-1 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-[#171923] ${
												!contentShare.tileId ? "bg-[#4A5568]" : "bg-[#e2e8f0]"
											}`}
										>
											<svg
												width="13"
												height="13"
												viewBox="0 0 13 13"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M11.6094 4.11935L11.6094 3.45268C11.6094 2.34811 10.7139 1.45268 9.60937 1.45268L2.94271 1.45268C1.83814 1.45268 0.942708 2.34811 0.942708 3.45268L0.942708 4.11935M3.60937 6.78602L6.27604 4.11935M6.27604 4.11935L8.94271 6.78602M6.27604 4.11935L6.27604 12.1193"
													stroke="#718096"
													strokeWidth="1.2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</Tooltip>
								)}
								<Tooltip label={t("full screen")}>
									<button
										className="w-12 h-12 hover:bg-opacity-70 transition-all duration-500 rounded-lg flex justify-center items-center bg-[#4A5568]"
										onClick={toggle}
									>
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8.49612 0.786011H12.4961V4.78601"
												stroke="white"
												strokeWidth="1.2"
												strokeLinecap="round"
											/>
											<path
												d="M4.99998 12.814L1 12.814L1 8.81403"
												stroke="white"
												strokeWidth="1.2"
												strokeLinecap="round"
											/>
											<path
												d="M12.4961 8.77639L12.4961 12.7764L8.49609 12.7764"
												stroke="white"
												strokeWidth="1.2"
												strokeLinecap="round"
											/>
											<path
												d="M1 5.01664L1 1.01666L5 1.01666"
												stroke="white"
												strokeWidth="1.2"
												strokeLinecap="round"
											/>
										</svg>
									</button>
								</Tooltip>
							</div>

							{admin.length > 0 && (
								<Tooltip label={t("End meeting")}>
									<button
										className="ml-auto w-12 h-12 hover:bg-opacity-70 transition-all duration-500 flex justify-center items-center rounded-lg text-white bg-[#c53030] mr-4"
										onClick={() => handleDeleteMeeting.mutate()}
									>
										<VscDebugDisconnect />
									</button>
								</Tooltip>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export const MemoizedMeetWindow = React.memo(MeetWindow);
