import React from "react";

export const GikiSkeleton = () => {
  return (
    <div className='rounded animate-pulse shadow-lg pb-3'>
      <div className='flex flex-col w-full gap-[10px] py-2 px-4'>
        <div className='flex gap-2'>
          <div className='flex items-center justify-between w-full flex-1'>
            <div className='w-12 h-12 rounded border-2 border-white bg-slate-300 ' />
            <div className='self-stretch flex flex-col justify-between'>
              <div className='w-7 h-7 rounded border-2 border-white bg-slate-300 self-end' />
              <div className='w-14 h-3 rounded border-2 border-white bg-slate-300 ' />
            </div>
          </div>
        </div>
        <p className='text-blue-primary font-semibold h-5 bg-slate-300 rounded'></p>
        <p className='text-xs h-3 bg-slate-300 rounded'></p>
        <p className='text-xs h-3 bg-slate-300 rounded'></p>
        <div className='w-24 h-10 rounded border-2 border-white bg-slate-300 ' />
      </div>
    </div>
  );
};
