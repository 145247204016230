import { Avatar } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import {
	currentOnlineUsersAtom,
	selectedChat,
} from "../../../../../../store/atoms";
import EditCommunityAdmin from "../../../../../../components/modals/EditCommunityAdmin";
import generalChatInfo from "../../info.atom";
import { useTranslation } from "react-i18next";

const image = require("../../../../../../assets/dummy.png");

const Info = () => {
	const chat = useRecoilValue(selectedChat);
	const communityOnline = useRecoilValue(currentOnlineUsersAtom);
	const generalInfo = useRecoilValue(generalChatInfo);
	const { t } = useTranslation();

	return (
		<div className="flex-1 flex flex-col gap-5 h-full !py-0">
			<img
				src={
					chat.cover_image
						? `${process.env.REACT_APP_MEDIA_URL}${chat.cover_image}`
						: image
				}
				className="w-full max-h-[123px] object-cover"
				alt=""
			/>
			<div className="px-4 flex flex-col gap-4 flex-1">
				<div className="flex gap-[10px]">
					<Avatar
						src={`${process.env.REACT_APP_MEDIA_URL}${chat.image}`}
						className="!w-16 !h-16 rounded aspect-square "
						rounded={"4px"}
						name={chat.name}
					/>
					<div className="self-stretch flex flex-col justify-between">
						<h3 className="line-clamp-1">{chat.name}</h3>
						<div className="flex items-center gap-[10px]">
							<p className="bg-gray-20 text-[10px] text-blue-primary rounded py-[1px] px-2 ">
								{communityOnline} {t("online")}
							</p>{" "}
							<p className="text-[10px] text-[#718096] leading-[15px]">
								{chat.users_count} {t("Members")}
							</p>
						</div>
						<p className="text-[10px] text-[#CBD5E0] leading-[15px]">
							{t("Created on")} {generalInfo.createdOn}
						</p>
					</div>
				</div>
				<p className="text-xs leading-[14.4px] line-clamp-3">
					{chat.short_description}.
				</p>
			</div>
			{generalInfo.isAdmin && (
				<div className="flex flex-col gap-[10px] px-4">
					<EditCommunityAdmin />
					{/* <button className='w-full text-center bg-text-gray hover:bg-gray-300 transition-all duration-300 text-white text-xs leading-[14.4px] p-4 rounded'>
            Request deactivation of this Giki
          </button> */}
				</div>
			)}
		</div>
	);
};

export default Info;
