/* eslint-disable jsx-a11y/alt-text */
import noFound from "../../../assets/svgs/chat/404.svg";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FadeBox } from "../FadeBox";

interface NoGikisBoxProps {
	text: string;
	buttonText: string;
	buttonLink: string;
}

const NoGikisBox: React.FunctionComponent<NoGikisBoxProps> = ({
	text,
	buttonText,
	buttonLink,
}) => {
	const navigate = useNavigate();
	return (
		<FadeBox className="flex-col justify-center items-center h-full hidden md:flex">
			<div className="flex flex-col max-w-[300px]">
				<img className="w-[170px] mx-auto" src={noFound} />
				<p className="text-center text-blue-primary font-semibold pb-5">
					{text}
				</p>

				<Button
					variant="primary"
					onClick={() => {
						navigate(buttonLink);
					}}
				>
					{buttonText}
				</Button>
			</div>
		</FadeBox>
	);
};

export default NoGikisBox;
