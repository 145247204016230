/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Button, Divider, IconButton } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import {
	invitationsNumberAtom,
	selectedCategoryIdAtom,
	sidebarState,
} from "../../store/atoms";

interface CategoriesButtonProps {
	isActive?: boolean;
	title: string;
	icon: string | null;
	requests?: number;
	setCategory?: Dispatch<SetStateAction<string>>;
	hasBorder?: boolean;
	isInvite?: boolean;
	fromApi?: boolean;
	id?: number;
}

const CategoriesButton: React.FunctionComponent<CategoriesButtonProps> = ({
	isActive,
	icon,
	title,
	setCategory,
	hasBorder,
	isInvite,
	fromApi = false,
	id,
}) => {
	const [, setCategoryId] = useRecoilState(selectedCategoryIdAtom);
	const [invitationsCount] = useRecoilState(invitationsNumberAtom);
	const [openSideBar] = useRecoilState(sidebarState);

	const { t } = useTranslation();

	const isThereAnInvite = isInvite && (
		<div className="flex items-center gap-[5px] text-xs font-normal">
			{invitationsCount.length > 0 && (
				<svg
					width="8"
					height="8"
					viewBox="0 0 8 8"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="4.19887" cy="4.00002" r="3.5995" fill="#FF8C1E" />
				</svg>
			)}
			{openSideBar &&
				invitationsCount.length > 0 &&
				`${invitationsCount.length} ${t("request")}`}
		</div>
	);

	return (
		<div className="flex flex-col w-full">
			{hasBorder && <Divider className="mb-4" />}

			<Button
				onClick={() => {
					setCategory && setCategory(title);
					id && setCategoryId(id);
				}}
				textTransform="capitalize"
				variant={isActive ? "heaveyBlue" : "white"}
				display={{ base: "none", md: "flex" }}
				_hover={{ bg: !isActive ? "gray-50" : "" }}
				alignItems="center"
				className="!rounded !h-[48px]"
			>
				<div className={`flex w-full ${openSideBar ? "gap-3" : "justify-center"} text-xs lg:text-sm items-center`}>
					<div className="relative">
						{fromApi ? (
							<Avatar
								className={`aspect-square !h-[24px] !w-[24px] object-cover`}
								rounded={4}
								src={`${process.env.REACT_APP_MEDIA_URL}${icon}`}
								name={title}
							/>
						) : (
							icon && <img className={`w-[24px] aspect-square`} src={icon} />
						)}

						{!openSideBar && (
							<span className="absolute bottom-0 left-1/2 -translate-x-1/2">
								{isThereAnInvite}
							</span>
						)}
					</div>

					{openSideBar && title}
				</div>
				{isInvite && openSideBar && (
					<div className="flex items-center gap-[5px] text-xs font-normal">
						{invitationsCount.length > 0 && (
							<svg
								width="8"
								height="8"
								viewBox="0 0 8 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="4.19887" cy="4.00002" r="3.5995" fill="#FF8C1E" />
							</svg>
						)}

						{invitationsCount.length > 0 &&
							`${invitationsCount.length} ${t("request")}`}
					</div>
				)}
			</Button>

			<IconButton
				display={{ base: "flex", md: "none" }}
				variant={isActive ? "heaveyBlue" : "white"}
				className="!rounded"
				aria-label="Invites"
				onClick={() => {
					setCategory && setCategory(title);
				}}
				icon={
					<>
						{icon && <img src={icon} />}{" "}
						{invitationsCount.length > 0 && (
							<div className="bg-orange-primary rounded-full absolute p-[3px] text-xs -top-2 -right-2 text-white">
								{invitationsCount?.length > 0 ? invitationsCount.length : " "}
							</div>
						)}
					</>
				}
			/>
			{hasBorder && <Divider className="pt-2 mb-2" />}
		</div>
	);
};

export default CategoriesButton;
