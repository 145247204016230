const PinIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1550_11975)">
				<path
					d="M4.23149 12.2046L6.38312 10.0639L6.58683 10.0724L8.48383 11.9597C8.52627 12.002 8.57295 12.0442 8.62388 12.0737C8.78515 12.1708 8.94217 12.1455 9.07373 12.0104C9.43446 11.6346 9.56601 11.1659 9.60845 10.6635C9.64665 10.199 9.59997 9.74305 9.51085 9.29126C9.49811 9.22371 9.51509 9.18149 9.56177 9.13504C9.8546 8.84793 10.1474 8.55659 10.436 8.26526C10.4827 8.21881 10.5209 8.16392 10.5761 8.09637C10.6694 8.19348 10.7373 8.26948 10.8095 8.34126C11.0004 8.53126 11.1914 8.53126 11.3824 8.34126L12.4179 7.31103C12.6089 7.12103 12.6173 6.8128 12.4264 6.6228C11.3739 5.57146 10.3172 4.52012 9.26046 3.473C9.08646 3.29566 8.89124 3.29566 8.713 3.473C8.33954 3.84033 7.96608 4.21189 7.59687 4.58345C7.41438 4.76923 7.41438 4.95501 7.59687 5.13657C7.67325 5.21257 7.76238 5.28012 7.84725 5.3519C7.46955 5.72346 7.1258 6.06546 6.7778 6.40746C6.7481 6.43702 6.68868 6.44546 6.64624 6.43702C6.13274 6.34413 5.61923 6.29769 5.09724 6.36102C4.67285 6.41591 4.28242 6.54258 3.95988 6.82969C3.72647 7.03658 3.72223 7.22658 3.94291 7.44614C4.58373 8.0837 5.22455 8.71704 5.86537 9.3546V9.56149L3.74345 11.6726C3.70525 11.7106 3.66706 11.7486 3.63735 11.7908C3.55672 11.9048 3.53974 12.0273 3.60764 12.154C3.6713 12.2722 3.76891 12.3355 3.90896 12.3397C4.04052 12.344 4.14237 12.2975 4.23149 12.2046Z"
					stroke="#718096"
					strokeWidth="1.2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1550_11975">
					<rect
						width="12"
						height="12"
						fill="white"
						transform="translate(2.56543 2.34)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default PinIcon;
