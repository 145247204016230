import { useTranslation } from "react-i18next";
import IntroBox from "../../components/boxes/statusBoxes/IntroBox";

const NoChat = () => {
	const { t } = useTranslation();

	return (
		<IntroBox text={t("Select a Giki from the list to continue interacting")} />
	);
};

export default NoChat;
