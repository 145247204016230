import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import { useQuery } from "@tanstack/react-query";
// import { useApiRequests } from "configs/API/ApiRequests";
// import UseGetAdmin from "hooks/useGetAdmin";
// import { useRecoilState } from "recoil";
// import { selectedChat, userDataAtom } from "store/atoms";
// import { Members as MembersType } from "types";
// import generalChatInfo from "../info.atom";
import Info from "./InfoPanel/Info";
import Members from "./Members/Members";
import PinnedPanel from "./Pinned/PinnedPanel";

// blue-primary: "#6091e6"
// blue-primary-20: "#D6D8DD"
// blue-primary-50: "#989EA9"
// blue-primary-80: "#5B6575"
// gray-20: "#E2E8F0"
// gray-50: "#F7FAFC"
// orange-primary: "#FF8C1E"
// orange-primary-20: "#FFE8D2"
// orange-primary-50: "#FFE8D2"
// orange-primary-80: "#FFA34B"
// text-gray: "#718096"

const TabsGroup = () => {
	const { t } = useTranslation();

	return (
		<Tabs variant={"gikiThemed"} className="h-full !flex flex-col">
			<TabList px="16px">
				<Tab>{"Info"}</Tab>
				<Tab>{t("Members")}</Tab>
				<Tab>{t("Pinned")}</Tab>
				{/* <Tab>{"Mute"}</Tab> */}
			</TabList>
			<TabPanels className="h-full flex-1 overflow-y-auto">
				<TabPanel className="h-full">
					<Info />
				</TabPanel>
				<TabPanel className="h-full overflow-y-auto">
					<Members />
				</TabPanel>
				<TabPanel className="h-full overflow-y-auto">
					<PinnedPanel />
				</TabPanel>
				{/* <TabPanel className="h-full overflow-y-auto">
					<MutePanel />
				</TabPanel> */}
			</TabPanels>
		</Tabs>
	);
};

export default TabsGroup;
