import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Conferences from "../../../../components/icons/Conferences";
import { useRecoilState } from "recoil";
import ConferenceGroup from "../chatPannels/conferences/ConferencesGroup";
import chatInfo from "./store";
import { useTranslation } from "react-i18next";

const ConferencesControl = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useRecoilState(chatInfo);
	const { t } = useTranslation();

  const [isLgScreen] = useMediaQuery(["(min-width: 1024px)"]);

  const handleOpen = () => {
    if (isLgScreen) {
      setSelected("live streaming");
      onClose();
    } else {
      setSelected("none");
      onOpen();
    }
  };

  return (
    <>
    <Tooltip label={t("Live streaming")}>
      <button
        onClick={handleOpen}
        className={`w-6 md:w-12 h-6 md:h-12 flex items-center justify-center rounded ${
          selected === "live streaming" && "bg-blue-primary"
        }`}
      >
        <Conferences
          fillActiveColor={selected === "live streaming" ? "fill-white" : "fill-blue-primary"}
          strokeActiveColor={selected === "live streaming" ? "stroke-white" : "stroke-blue-primary"}
          fillAccentColor={selected === "live streaming" ? "fill-white" : "fill-blue-primary"}
        />
      </button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className='flex flex-col h-[80vh]'>
          <ModalHeader className='!text-base text-blue-primary'>{t("Live Streaming")}</ModalHeader>
          <ModalCloseButton color={"#CBD5E0"} className='border-[2px] rounded border-[#CBD5E0]' />
          <ModalBody px={0} className='h-full overflow-hidden'>
            <ConferenceGroup />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConferencesControl;
