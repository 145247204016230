import Counter from "../../components/counter/Counter";
import { useCountdown } from "./hook/userCountDown";

const CountDownTimer = ({ time }: { time: string }) => {
  const [days, hours, minutes, seconds] = useCountdown(time);

  return (
    <div className='flex items-center'>
      {days >= 0 && <><Counter value={days} />
      :</>}
      {hours >= 0 && <><Counter value={hours} />
      :</>}
      {minutes >= 0 && <><Counter value={minutes} />
      :</>}
      {seconds >= 0 && <Counter value={seconds} />}
    </div>
  );
};

export default CountDownTimer;
