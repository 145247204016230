import { Button, Spinner } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useAxiosInstance } from "../../configs/API/AxiosInstance";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../store/atoms";

export const Consent = () => {
	const navigate = useNavigate();

	const { consentApi } = useApiRequests();

	const { setAuthToken } = useAxiosInstance();

	const [, setUserData] = useRecoilState(userDataAtom);
	const [ps, setPs] = useState("");

	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: consentApi,
		onSuccess: (data) => {
			setUserData(null);
			setAuthToken(data.data.data.plainTextToken);
			setUserData(data.data.data);
			navigate("/dashboard");
			window.location.reload();
		},
	});

	const { t } = useTranslation();

	useEffect(() => {
		fetch("/ps.txt")
			.then((r) => r.text())
			.then((text) => {
				setPs(text);
			});
	}, []);

	return (
		<div className="flex justify-center items-center">
			<div className="bg-white my-14 pb-10 container flex flex-col">
				<div className="w-full text-center">
					<h3 className="text-primary text-3xl py-10 font-semibold">
						{t("Privacy Policy Consent")}
					</h3>
					<p className="text-base font-semibold pb-9">
						{t("Please accept website privacy policy to continue")}
					</p>
				</div>

				<div className="container max-w-[600px] mx-auto shadow-3xl rounded-lg p-9 flex flex-col gap-7">
					<textarea
						rows={8}
						defaultValue={ps}
						className=" w-full pl-3 text-base rounded-md border-2  shadow-md
                  focus:shadow-sm border-[#bbbbbb59] outline-none duration-500 transition-all"
					/>
					<Button
						spinner={<Spinner />}
						height={"60px"}
						type="submit"
						onClick={() => mutate()}
						isLoading={isLoading}
						variant={"primary"}
					>
						{t("MAKE CONSENT")}
					</Button>
				</div>
			</div>
		</div>
	);
};
