import { Spinner } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { BsCheck } from "react-icons/bs";
import { Users } from "./InviteNewUser";

type Props = {
  users: { user_id: string; full_name: string; selected: boolean }[];
  isLoading: boolean;
  isDisabled: boolean;
  setSelectedUser: Dispatch<SetStateAction<Users>>;
};

const UserMapper = ({ users, isLoading, setSelectedUser, isDisabled }: Props) => {
  const handleUserSelect = (user: { user_id: string; full_name: string; selected: boolean }) => {
    if (!isDisabled) {
      if (user.selected) {
        setSelectedUser((selected) => selected.filter((u) => u.user_id !== user.user_id));
      } else {
        setSelectedUser((selected) => [...selected, { ...user, selected: true }]);
      }
    }
  };

  return isLoading ? (
    <div className='flex items-center justify-center'>
      <Spinner size='sm' />
    </div>
  ) : (
    <div className='flex items-center gap-[5px] flex-wrap px-5'>
      {users.map((user) => (
        <p
          key={user.user_id}
          className={`${
            user.selected ? "bg-blue-primary text-white" : "bg-gray-50 text-blue-primary-50"
          } text-xs leading-[18px] p-[6px] rounded flex items-center gap-1 cursor-pointer`}
          onClick={() => handleUserSelect(user)}
        >
          <p>{user.full_name}</p>
          {user.selected && <BsCheck />}
        </p>
      ))}
    </div>
  );
};

export default UserMapper;
