const cover1 = require('../assets/covers/01.png')
const cover2 = require('../assets/covers/02.png')
const cover3 = require('../assets/covers/03.png')
const cover4 = require('../assets/covers/04.png')
const cover5 = require('../assets/covers/05.png')
const cover6 = require('../assets/covers/06.png')
const cover7 = require('../assets/covers/07.png')
const cover8 = require('../assets/covers/08.png')
const cover9 = require('../assets/covers/09.png')
const cover10 = require('../assets/covers/10.png')
const cover11 = require('../assets/covers/11.png')
const cover12 = require('../assets/covers/12.png')

export const useGetRandomCover = () => {
  const coverImages = [
    cover1,
    cover2,
    cover3,
    cover4,
    cover5,
    cover6,
    cover7,
    cover8,
    cover9,
    cover10,
    cover11,
    cover12,
  ]

  // const randomIndex = Math.floor(Math.random() * coverImages.length)
  const randomImage = coverImages[2]

  return { randomImage }
}
