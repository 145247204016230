import {
	Modal,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import FriendsSmall from "../../../../../components/icons/FriendsSmall";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import UserMapper from "./UserMapper";
import { useTranslation } from "react-i18next";

export type Users = {
	user_id: string;
	full_name: string;
	selected: boolean;
}[];

const InviteNewUser = ({ id }: { id: string }) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { getUnsubscribedMembers, inviteUsers } = useApiRequests();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [users, setUsers] = useState<Users>([]);
	const [selectedUsers, setSelectedUsers] = useState<Users>([]);

	const { mutateAsync, isPending: isLoadingMutation } = useMutation({
		mutationFn: () =>
			inviteUsers(
				id,
				selectedUsers.map((u) => u.user_id)
			),
		onSuccess: () => {
			setSelectedUsers([]);
			setUsers((prev) => prev.map((item) => ({ ...item, selected: false })));
			toast({
				title: "Users Invited Successfully",
				status: "success",
				isClosable: true,
				duration: null,
			});
		},
		onError: () => {
			toast({
				title: "Something Went Wrong",
				status: "error",
				isClosable: true,
			});
		},
	});

	const { isLoading, isSuccess, data } = useQuery({
		queryKey: [`unsubscribed-members-${id}`],
		queryFn: () => getUnsubscribedMembers(id),
	});

	useEffect(() => {
		if (!isLoading && isSuccess) {
			if (data.data && data.data.length > 0) {
				setUsers(
					data.data.map((u: { user_id: string; full_name: string }) => ({
						user_id: u.user_id,
						full_name: u.full_name,
						selected: false,
					}))
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isSuccess]);

	useEffect(() => {
		const ids = selectedUsers.map((item) => item.user_id);
		setUsers((prev) => {
			return prev.map((item) =>
				ids.includes(item.user_id)
					? { ...item, selected: true }
					: { ...item, selected: false }
			);
		});
	}, [selectedUsers]);

	return (
		<>
			<button
				onClick={onOpen}
				className="w-full text-center bg-text-gray hover:bg-gray-300 transition-all duration-300 text-white text-xs leading-[14.4px] p-4 rounded"
			>
				{t("Invite new members")}
			</button>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent className="h-1/2">
					<div className="bg-white flex flex-col gap-[10px] rounded overflow-hidden pb-5 h-full overflow-y-scroll">
						<div className="px-4 py-[10px] bg-[#4A5568] flex items-center gap-[10px] ">
							<p className="w-[54px] h-[54px] flex items-center justify-center">
								<FriendsSmall className="stroke-white" />
							</p>
							<h3 className="text-gray-50 font-bold text-sm leading-[16.8px]">
								{t("Invite new members")}
							</h3>
						</div>
						<div className="px-5">
							<div className="flex gap-5">
								<ReactSelect
									isMulti
									name="full_name"
									value={selectedUsers}
									id="user_id"
									isSearchable
									options={users}
									className="basic-multi-select flex-1 !overflow-hidden !h-min"
									onChange={(e) => {
										const ids = e.map((i) => i.user_id);
										setSelectedUsers(
											users.filter((u) => ids.includes(u.user_id))
										);
									}}
									classNamePrefix="select"
									getOptionValue={(option) => `${option.user_id}`}
									getOptionLabel={(option) => `${option.full_name}`}
									isDisabled={isLoadingMutation}
								/>
								<button
									disabled={isLoadingMutation || selectedUsers.length < 1}
									onClick={() => mutateAsync()}
									className="disabled:bg-blue-primary-50 bg-blue-primary hover:bg-blue-primary-80 rounded text-white px-4 py-[6px] transition-colors duration-300 !h-fit"
								>
									{t("Send")}
								</button>
							</div>
						</div>
						<UserMapper
							isDisabled={isLoadingMutation}
							isLoading={isLoading}
							users={users}
							setSelectedUser={setSelectedUsers}
						/>
					</div>
				</ModalContent>
			</Modal>
		</>
	);
};

export default InviteNewUser;
