import MassagesBox from "../../../components/boxes/MassagesBox";
import LeftMenu from "../../../components/menus/LeftMenu";
import React from "react";
import { Chat } from "../../../types";
import { Link } from "react-router-dom";
import MemberSkeleton from "../../../components/boxes/MemberSkeleton";
import { useRecoilValue } from "recoil";
import { sidebarState } from "../../../store/atoms";
import { useTranslation } from "react-i18next";
interface MassagesPanelProps {
	data?: Chat[];
	isLoading: boolean;
}

const MassagesPanel: React.FunctionComponent<MassagesPanelProps> = ({
	data,
	isLoading,
}) => {
	const [input] = React.useState<string>("");
	const sideBarOpen = useRecoilValue(sidebarState);
	const { t } = useTranslation();

	const orderedData =
		data && data?.length > 0
			? data?.sort(
				(a, b) =>
					new Date(b.last_message?.updated_at || "").valueOf() -
					new Date(a.last_message?.updated_at || "").valueOf()
			)
			: [];

	return (
		<LeftMenu title={t("Messages")}>
			<>
				<div className="flex flex-col gap-3">
					{orderedData?.length === 0 && !isLoading ? (
						sideBarOpen && (
							<p className="text-sm text-center px-6">
								{t(
									"You have not yet joined any Giki yet! Start exploring Giki"
								)}{" "}
								<Link className="text-orange-primary font-semibold" to="/">
									{t("here")}
								</Link>
							</p>
						)
					) : (
						<>
							{isLoading ? (
								<MemberSkeleton reapeted={4} />
							) : (
								<>
									{input
										? orderedData
											?.filter((item) =>
												item.name.toLowerCase().includes(input.toLowerCase())
											)
											?.map((chat: Chat) => (
												<MassagesBox key={chat.id} {...chat} />
											))
										: orderedData?.map((chat: Chat) => (
											<MassagesBox key={chat.id} {...chat} />
										))}
								</>
							)}
						</>
					)}
				</div>
			</>
		</LeftMenu>
	);
};

export default MassagesPanel;
