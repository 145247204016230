import { useMediaQuery } from "@chakra-ui/react";
import {
	ContentShare,
	useContentShareState,
	useRosterState,
} from "amazon-chime-sdk-component-library-react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../store/atoms";
import { Attendee } from "./Attendee";

export const MeetingAttendees = ({
	isFullScreen,
}: {
	isFullScreen: boolean;
}) => {
	const contentShare = useContentShareState();
	const { roster } = useRosterState();
	const [selectedView, setselectedView] = useState<string | undefined>();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [isTablet] = useMediaQuery("(max-width: 1024px)");
	const [sideBarOpen] = useRecoilState(sidebarState);

	const [chatHeight, setChatHeight] = useState(
		document.documentElement.clientHeight
	);

	const [chatWidth, setChatWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setChatHeight(document.documentElement.clientHeight);
			const container = document.getElementById("videoInnerContainer");

			if (container) {
				setChatWidth(
					container.clientWidth > 768
						? container.clientWidth - 8 * 3
						: container.clientWidth - 8 * 2
				);
			}
		};

		window.addEventListener("resize", handleResize);

		const container = document.getElementById("videoInnerContainer");

		if (container) {
			setChatWidth(
				container.clientWidth > 768
					? container.clientWidth - 8 * 3
					: container.clientWidth - 8 * 2
			);
		}

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const container = document.getElementById("videoInnerContainer");

		if (container) {
			setTimeout(() => {
				setChatWidth(
					container.clientWidth > 768
						? container.clientWidth - 8 * 3
						: container.clientWidth - 8 * 2
				);
			}, 200);
		}
	}, [sideBarOpen, contentShare.tileId]);

	return (
		<div
			className="bg-transparent rounded-lg self-stretch h-full"
			style={{
				height:
					!isMobile && !isFullScreen
						? (chatHeight - 168) / 2 - 80
						: "calc(100% - 100px)",
			}}
		>
			<div
				className={`flex items-center gap-2 p-2 h-full self-stretch w-full  ${
					contentShare.tileId ? (isFullScreen ? "flex-col" : "flex-row") : ""
				}`}
				style={{ background: "transparent", gap: "15px" }}
			>
				{contentShare.tileId && (
					<div
						className={`cursor-pointer w-full flex-1 h-full self-stretch`}
						// onClick={() => setselectedView('contentShare')}
					>
						<ContentShare className="rounded-xl" nameplate="Content share" />
					</div>
				)}

				<div
					className={`flex items-center gap-2 
						${
							contentShare.tileId
								? isFullScreen
									? "flex-row overflow-x-scroll w-full"
									: "flex-col overflow-y-scroll h-full"
								: "flex-row overflow-y-scroll flex-wrap self-stretch !items-start !justify-start w-full content-start"
						}
						`}
					id="videoInnerContainer"
				>
					{isTablet &&
						!contentShare.tileId &&
						Object.values(roster).map(({ chimeAttendeeId, externalUserId }) => {
							return (
								<Attendee
									key={chimeAttendeeId}
									selectedView={selectedView}
									setselectedView={setselectedView}
									externalUserId={externalUserId}
									chimeAttendeeId={chimeAttendeeId}
									contentShareTileId={contentShare.tileId}
									width={chatWidth > 768 ? chatWidth / 3 : chatWidth / 2}
								/>
							);
						})}

					{!isTablet &&
						Object.values(roster).map(({ chimeAttendeeId, externalUserId }) => {
							return (
								<Attendee
									key={chimeAttendeeId}
									selectedView={selectedView}
									setselectedView={setselectedView}
									externalUserId={externalUserId}
									chimeAttendeeId={chimeAttendeeId}
									contentShareTileId={contentShare.tileId}
									width={chatWidth > 768 ? chatWidth / 3 : chatWidth / 2}
								/>
							);
						})}
				</div>
			</div>
		</div>
	);
};
