import React from "react";
import { Tooltip } from '@chakra-ui/react'
import { useTranslation } from "react-i18next";

const Home = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("Dashboard")}>
    <svg
      width='26'
      height='25'
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10.8182 23.7304V17.1304H15.1819V23.7304'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.27271 14.3804V23.7304H21.7273V14.3804'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 8.33035C24.9788 9.22626 24.6058 10.0771 23.9629 10.6958C23.32 11.3146 22.4598 11.6507 21.5713 11.6303C21.0122 11.6344 20.4605 11.5022 19.9631 11.245C19.4656 10.9878 19.0372 10.6132 18.7142 10.153C18.3884 10.6102 17.9593 10.9827 17.4625 11.2396C16.9657 11.4965 16.4154 11.6305 15.8571 11.6305C15.2988 11.6305 14.7485 11.4965 14.2516 11.2396C13.7548 10.9827 13.3258 10.6102 13 10.153C12.6742 10.6102 12.2452 10.9827 11.7484 11.2396C11.2515 11.4965 10.7012 11.6305 10.1429 11.6305C9.58458 11.6305 9.03427 11.4965 8.53746 11.2396C8.04065 10.9827 7.61162 10.6102 7.28582 10.153C6.96285 10.6132 6.5344 10.9878 6.03694 11.245C5.53949 11.5022 4.98776 11.6344 4.42873 11.6303C3.5402 11.6507 2.67998 11.3146 2.03708 10.6958C1.39418 10.0771 1.02117 9.22626 1 8.33035L5.36364 1.73035H20.6364L25 8.33035Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
    </Tooltip>
  );
};

export default Home;
