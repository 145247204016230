import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { selectedChat } from "../store/atoms";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FieldValues, UseFormReset } from "react-hook-form";

const useMeetingHandler = ({ reset }: { reset: UseFormReset<FieldValues> }) => {
	const toast = useToast();
	const { scheduleConference } = useApiRequests();
	const [chat] = useRecoilState(selectedChat);
	const [meetingId] = useState<any>();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const scheduleMeeting = useMutation({
		mutationFn: (data: any) => {
			const start_date = moment(data.start_date);
			const end_time = data.end_date as string | undefined | null;

			const end_date = moment(
				start_date.format("YYYY-MM-DD") + " " + end_time,
				"YYYY-MM-DD HH:mm"
			);

			console.log(end_date);

			data.end_date = moment(end_date).format("YYYY-MM-DDTHH:mm");

			return scheduleConference({ id: chat.id, data });
		},
		onSuccess: async (data: any) => {
			toast({
				title: "Conferenza creata con successo",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			reset();
			return await queryClient.invalidateQueries();
		},
		onError: (e: AxiosError) => {
			if (e.response?.status === 422) {
				if ((e.response.data as any).data === "unavailable_slot") {
					toast({
						title: t("unavailable_slot"),
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				} else {
					toast({
						title: t("Something Went Wrong"),
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				}
			} else {
				toast({
					title: t("Something Went Wrong"),
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
		},
	});

	const onSubmit = (data: any) => {
		scheduleMeeting.mutate(data);
	};

	return {
		onSubmit: onSubmit,
		meetingId: meetingId,
		isScheduledLoading: scheduleMeeting.isPending,
	};
};

export default useMeetingHandler;
