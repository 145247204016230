import { Button, Input, Textarea, useToast } from "@chakra-ui/react";
import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useMeetingHundler from "../../../../../hooks/useMeetingHundler";
import moment from "moment";

const CreateNewConference = () => {
	const toast = useToast();
	const { register, handleSubmit, reset } = useForm();
	const { t } = useTranslation();

	const { onSubmit, isScheduledLoading } = useMeetingHundler({ reset });
	const currentDateTime = new Date();

	const handle = (e: FormEvent) => {
		e.preventDefault();
		const data = new FormData(e.target as any);
		const startDate = data.get("start_date") as string | undefined | null;

		const start_date = moment(startDate);
		const end_time = data.get("end_date") as string | undefined | null;
		const end_date = moment(
			start_date.format("YYYY-MM-DD") + " " + end_time,
			"YYYY-MM-DD HH:mm"
		);

		if (new Date() > new Date(startDate || "")) {
			toast({
				title: t("Can't create a conference in past time or date"),
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} else if (end_date.isBefore(start_date) || end_date.isSame(startDate)) {
			toast({
				title: t("Start Date Can't be after End Date"),
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} else {
			handleSubmit(onSubmit)(e);
		}
	};

	return (
		<form
			onSubmit={handle}
			className="flex flex-col gap-5 justify-center items-center w-full px-4 py-4 mx-auto"
		>
			<Input
				disabled={isScheduledLoading}
				{...register("title", { required: true })}
				border="solid 2px #bbbbbb59"
				borderColor="#bbbbbb59"
				variant="register-outline"
				className=" placeholder:text-grap-20"
				placeholder={t("Enter a title for the conference")}
				borderRadius={4}
				required
			/>
			<Textarea
				disabled={isScheduledLoading}
				{...register("description", { required: true })}
				border="solid 2px #bbbbbb59"
				borderColor="#bbbbbb59"
				variant="register-outline"
				className=" placeholder:text-grap-20"
				placeholder={t("Enter a description")}
				borderRadius={4}
				rows={2}
				resize="none"
				required
			/>
			<div className="flex flex-col gap-2 w-full">
				<span className="text-text-gray text-xs whitespace-nowrap">
					{t("Start Date")}:
				</span>
				<Input
					disabled={isScheduledLoading}
					{...register("start_date", { required: true })}
					border="solid 2px #bbbbbb59"
					borderColor="#bbbbbb59"
					variant="register-outline"
					className=" placeholder:text-text-gray ml-auto"
					type="datetime-local"
					textColor={"GrayText"}
					placeholder={t("Select a the date of conference")}
					borderRadius={4}
					required
					min={currentDateTime.toISOString()}
					lang="it"
				/>
			</div>
			<div className="flex flex-col gap-2 w-full">
				<span className="text-text-gray text-xs whitespace-nowrap">
					{t("End Date")}:
				</span>
				<Input
					disabled={isScheduledLoading}
					{...register("end_date", { required: true })}
					border="solid 2px #bbbbbb59"
					borderColor="#bbbbbb59"
					variant="register-outline"
					className=" placeholder:text-text-gray ml-auto"
					type="time"
					textColor={"GrayText"}
					// placeholder={t("Select a the end date of conference")}
					borderRadius={4}
					required
					lang="it"
				/>
			</div>
			<Button
				isLoading={isScheduledLoading}
				type="submit"
				className="w-full text-center transition-all duration-300 !text-white !text-xs leading-[14.4px] p-4 rounded"
				bg="orange-primary"
			>
				{t("Create conference")}
			</Button>
		</form>
	);
};

export default CreateNewConference;
