import { useRecoilState } from "recoil";
import { userSelected } from "../../../store/atoms";

const BackButton = () => {
	const [, setSelectedUser] = useRecoilState(userSelected);

	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="mt-2 ml-2 cursor-pointer stroke-blue-primary"
			onClick={() =>
				setSelectedUser({
					id: "",
					first_name: "",
					last_name: "",
					photo: "",
					profession: "",
				})
			}
		>
			<path
				d="M13.25 15.8327L7.41667 9.99935L13.25 4.16602"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default BackButton;
