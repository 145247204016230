import NewInvitesBox from "./NewInvitesBox";
import GikisBox from "../GikisBox";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
// import ConferencesBanners from "../ConfrencesBanners";
import AllCommunities from "./AllCommunities";
import ConferencesBanners from "../ConferencesBanners";

interface NewInvitesProps {}

const NewInvites: React.FunctionComponent<NewInvitesProps> = () => {
	const { getSuggestedGikis } = useApiRequests();
	const { data, isLoading } = useQuery({
		queryKey: ["gikis.recommendations"],
		queryFn: getSuggestedGikis,
		select: (res: any) => res.data.data,
	});

	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-[30px] bg-white p-5 transition-all duration-300 max-w-full min-h-dvh">
			<NewInvitesBox />
			<ConferencesBanners />
			<GikisBox
				isLoading={isLoading}
				title={t("gikis.recommendations")}
				data={data}
			/>
			<AllCommunities />
		</div>
	);
};

export default NewInvites;
