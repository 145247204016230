/* eslint-disable no-lone-blocks */
import { Avatar, Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { Invitation } from "../../types";

interface InviteCardProps {
	data: Invitation;
}

const InviteCard: React.FunctionComponent<InviteCardProps> = ({ data }) => {
	const { acceptInvitations, cancelInvitations } = useApiRequests();
	const acceptMutation = useMutation({ mutationFn: acceptInvitations });
	const cancelMutation = useMutation({ mutationFn: cancelInvitations });
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const toast = useToast();

	const handleAccept = () => {
		acceptMutation.mutate(data.id, {
			onSuccess: async () => {
				toast({
					status: "success",
					title: "Invitation Accepted",
					duration: 1500,
					isClosable: true,
				});
				return await queryClient.invalidateQueries();
			},
		});
	};

	const handleCancel = () => {
		cancelMutation.mutate(data.id, {
			onSuccess: async () => {
				toast({
					status: "success",
					title: "Invitation Canceled",
					duration: 1500,
					isClosable: true,
				});
				return await queryClient.invalidateQueries();
			},
		});
	};

	return (
		<div className="bg-gray-50 rounded p-4 flex flex-col gap-[10px] justify-between w-full hover:bg-[#F7FAFC] text-blue-primary !self-stretch h-full">
			<div className="flex flex-col gap-[10px]  ">
				<div className="flex items-center gap-[10px]">
					<Avatar
						rounded="lg"
						opacity={0.6}
						w="54px"
						className="rounded-lg"
						borderRadius={"lg"}
						h="54px"
						fontSize="20px"
						name={data.community_name}
						src={`${process.env.REACT_APP_MEDIA_URL}${data.community_image}`}
					/>
					<div className="flex flex-col">
						<h3 className="text-xs font-bold leading-[14.4px]">
							{data.sender_name}
						</h3>
						<span className="text-xs leading-[18px]">
							{t("Invited you to join")}
						</span>
					</div>
				</div>
				<p className="text-sm leading-[16.8px] font-bold">
					{data.community_name}
				</p>
				<div className="lg:text-xs text-sm leading-[18px] line-clamp-4">
					{data.community_description}
				</div>
			</div>
			<div className="flex justify-between">
				<Button
					isLoading={acceptMutation.isPending}
					fontSize={{ base: "16px", lg: "14px" }}
					onClick={handleAccept}
					bg="orange-primary"
					_hover={{ bg: "blue-primary-80" }}
					width="fit-content"
					variant="primary"
					className="!font-light"
				>
					{t("Accept")}
				</Button>
				<Button
					isLoading={cancelMutation.isPending}
					fontSize={{ base: "16px", lg: "14px" }}
					onClick={handleCancel}
					bg="transparent"
					_hover={{ bg: "blue-primary-80" }}
					width="fit-content"
					variant="primary"
					className="!font-light !text-black hover:!text-white"
				>
					{t("Decline")}
				</Button>
			</div>
		</div>
	);
};

export default InviteCard;

{
	/* <div className='flex flex-col gap-[6px]'>
  <div className='flex gap-2'>
    <span className='lg:text-[10px] text-sm'>{data.community_members_number} Members</span>
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 8V9M3 11H9C9.55228 11 10 10.5523 10 10V7C10 6.44772 9.55228 6 9 6H3C2.44772 6 2 6.44772 2 7V10C2 10.5523 2.44772 11 3 11ZM8 6V4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4V6H8Z'
        stroke='#323E53'
        strokeLinecap='round'
      />
    </svg>
  </div>
  <p className='font-semibold lg:text-base text-xl'>{data.community_name}</p>
</div> 
<p className='text-xs'>{data.community_description}</p>
<div className='flex items-center gap-2 lg:text-xs text-sm'>
  <Avatar size='sm' src={profile} />
  <p>
    <span className='font-semibold'>{data.sender_name}</span> Invited you to join Giki
  </p>
</div>
<div className='flex gap-[10px]'>
  <Button
    isLoading={cancelMutation.isLoading}
    fontSize={{ base: '16px', lg: '14px' }}
    onClick={handleCancel}
    bg='blue-primary-50'
    _hover={{ bg: 'blue-primary-80' }}
    variant='primary'
  >
    Decline
  </Button>
  <Button
    isLoading={acceptMutation.isLoading}
    fontSize={{ base: '16px', lg: '14px' }}
    onClick={handleAccept}
    bg='orange-primary'
    _hover={{ bg: 'blue-primary-80' }}
    variant='primary'
  >
    Accept
  </Button>
</div>*/
}
