import { MeetingManager, MeetingStatus } from 'amazon-chime-sdk-component-library-react';
import { useEffect, useState } from 'react';


export const useMeetingStatus = ({meetingManager} : {meetingManager: MeetingManager}) => {

  const [meetingStatus, setMeetingStatus] = useState(
    () => meetingManager.meetingStatus
  );

  useEffect(() => {
    const callback = (updatedMeetingStatus: MeetingStatus): void => {
      setMeetingStatus(updatedMeetingStatus);
    };
    meetingManager.subscribeToMeetingStatus(callback);

    return (): void => {
      meetingManager.unsubscribeFromMeetingStatus(callback);
    };
  }, []);

  return {meetingStatus};
};
