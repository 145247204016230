import GikiCard from "../boxes/GikiCard";
import { Carousel, CarouselContent, CarouselItem } from "./Embla";

const CarouselExample = () => {
	return (
		<div className="flex justify-center mt-10 container mx-auto px-5">
			<Carousel className="w-full">
				<CarouselContent className="-ml-1">
					{Array.from({ length: 5 }).map((_, index) => (
						<CarouselItem
							key={index}
							className="pl-4 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
						>
							<GikiCard
								data={{
									cover_image: "",
									id: "1",
									image: "",
									is_joined: 1,
									name: "Hans",
									request_to_joined: 1,

									short_description:
										"Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis eos vero placeat, temporibus quia expedita voluptatem aperiam quos molestiae? Facilis nihil distinctio rerum eligendi, nisi repellendus commodi. Excepturi, consequuntur rem.",
									type: 1,
									users_count: 10,
								}}
								coverImage={"coverImage"}
								img={"img"}
							/>
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
		</div>
	);
};

export default CarouselExample;
