const ConferenceSkeleton = () => {
  return (
    <div className='px-[10px] py-5 rounded animate-pulse shadow-lg flex flex-col md:flex-row gap-5 md:justify-between'>
      <div className='h-[35px] w-[131px] bg-slate-300 hidden md:block' />
      <div className='flex flex-col md:flex-1 gap-[2px] justify-between'>
        <div className='flex items-center justify-between'>
          <div className='w-12 h-[14px] bg-slate-300' />
          <div className='w-[100px] h-[14px] bg-slate-300' />
        </div>
        <div className='bg-slate-300 h-4 w-32' />
      </div>
      <div className='flex items-center justify-between'>
        <div className='h-[35px] w-[102px] bg-slate-300' />
        <div className='h-[35px] w-[131px] bg-slate-300 md:hidden' />
      </div>
    </div>
  );
};

export default ConferenceSkeleton;
