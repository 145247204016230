import React from "react";
import AuthenticationLayout from "../AuthenticationLayout";
import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../../../components/forms/ChangePasswordForm";
import FooterLinks from "../../../components/boxes/FooterLinks";
interface ChangePasswordProps {}

const ChangePassword: React.FunctionComponent<ChangePasswordProps> = () => {
	const { t } = useTranslation();

	return (
		<AuthenticationLayout>
			<div className="col-span-2 flex flex-col gap-[10px] max-w-[400px] mx-auto text-left">
				<React.Fragment>
					<h1 className="text-3xl font-bold text-orange-primary">
						{t("change_password.header")}
					</h1>
					<p className="text-blue-primary mt-12 mb-4">
						{t("change_password.description")}
					</p>
					<ChangePasswordForm />
				</React.Fragment>
			</div>
			<FooterLinks />
		</AuthenticationLayout>
	);
};

export default ChangePassword;
