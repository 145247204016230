import { Avatar, AvatarGroup } from "@chakra-ui/react";
import { useRosterState } from "amazon-chime-sdk-component-library-react";

const MobileMeetHeader = () => {
	const { roster } = useRosterState();

	return (
		<div className="">
			<AvatarGroup size="sm" max={2}>
				{Object.values(roster).map(({ chimeAttendeeId, externalUserId }) => {
					const fullName =
						externalUserId?.split("|")[0] ||
						"" + externalUserId?.split("|")[1] ||
						"";
					return <Avatar name={fullName} key={chimeAttendeeId} />;
				})}
			</AvatarGroup>
		</div>
	);
};

export default MobileMeetHeader;
