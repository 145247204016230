/* eslint-disable react/no-children-prop */
import { Button, Checkbox } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import ModalsWrapper from "../../components/modals/ModalsWrapper";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { registerStep } from "../../store/atoms";
import { useTranslation } from "react-i18next";

interface PolicyFormProps {}

const PolicyForm: React.FunctionComponent<PolicyFormProps> = () => {
	const [xgatePS, setxgatePS] = useState("");
	const [registerData, setRegisterData] = useRecoilState(registerStep);
	const { registerApi } = useApiRequests();
	const { mutate } = useMutation({ mutationFn: registerApi });
	const { register, handleSubmit } = useForm();
	const { t } = useTranslation();

	useEffect(() => {
		fetch("/xgate.txt")
			.then((r) => r.text())
			.then((text) => {
				setxgatePS(text);
			});
	}, []);

	const onSubmit = (data: FieldValues) => {
		mutate(registerData.formData, {
			onSuccess: () => setRegisterData({ ...registerData, step: 3 }),
			onError: (error) => console.log(error),
		});
	};

	return (
		<form
			className="flex flex-col gap-4 pt-12"
			onSubmit={handleSubmit(onSubmit)}
		>
			<Checkbox alignItems="start" required {...register("first-checkbox")}>
				Esprimo il mio consenso all’invio da parte di Axenso di comunicazioni
				aventi contenuto informativo, medico-scientifico, o divulgativo proprio
				o di altri clienti
			</Checkbox>
			<Checkbox alignItems="start" required {...register("second-checkbox")}>
				Esprimo il mio consenso alla raccolta di informazioni relative
				all’attivà professionale e ai relativi interessi per sviluppare, fornire
				e migliorare i servizi informativi e medico-scientifici offerti
			</Checkbox>
			<div className="flex gap-2">
				<ModalsWrapper
					ButtonLabel="Privacy Policy Xgate"
					title="Privacy Policy Xgate"
					children={xgatePS}
				/>
				|
				<ModalsWrapper
					ButtonLabel="Privacy Policy Sito"
					title="Privacy Policy Sito"
					children={xgatePS}
				/>
			</div>
			<div className="col-span-9 flex flex-col justify-center items-center py-5 gap-2">
				<Button type="submit" w="50%" variant="primary">
					{t("register.registerForm.submit_register")}
				</Button>
				<Button
					onClick={() => {
						setRegisterData({ ...registerData, step: 1 });
					}}
					w="50%"
					py="11px"
					mx="auto"
					fontWeight="600"
					fontSize="14px"
					variant="unstyled"
					textTransform="capitalize"
				>
					{t("register.registerForm.back")}
				</Button>
			</div>
		</form>
	);
};

export default PolicyForm;
