import { Button, FormLabel, Input, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

interface ChangePasswordFormProps {}
interface ResetPasswordFormValues {
	password: string;
	password_confirmation: string;
}

const ChangePasswordForm: React.FunctionComponent<
	ChangePasswordFormProps
> = () => {
	const { t } = useTranslation();
	const navigat = useNavigate();
	const toast = useToast();
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");
	const { passwordReset } = useApiRequests();
	const { mutate } = useMutation({
		mutationFn: passwordReset,
	});

	const resetPasswordSchema = yup.object().shape({
		password: yup
			.string()
			.required(`${t("change_password.password_required")}`)
			.min(8, `${t("change_password.new_password_length")}`),
		password_confirmation: yup
			.string()
			.required(`${t("change_password.password_required")}`)
			.oneOf(
				[yup.ref("password")],
				`${t("change_password.passwords_missmatch")}`
			),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordFormValues>({
		resolver: yupResolver(resetPasswordSchema),
	});

	const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (
		data: ResetPasswordFormValues
	) => {
		mutate(
			{ ...data, token: code },
			{
				onSuccess: (data) => {
					if (data) {
						toast({
							status: "success",
							title: t("Password reset successfully"),
						});
						navigat("/login");
					}
				},
				onError: () => {
					toast({
						status: "error",
						title: t("Something goes wrong"),
					});
				},
			}
		);
	};
	return (
		<form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-3">
				<FormLabel variant="user-outline" className="col-span-2">
					{t("change_password.password")}
				</FormLabel>
				<Input
					{...register("password")}
					variant="user-outline"
					placeholder="******"
					className="col-span-2 placeholder:text-grap-20"
					type="password"
				/>
				{errors.password && (
					<p className="text-red-500">{errors.password.message}</p>
				)}
			</div>
			<div className="grid grid-cols-2 gap-3">
				<FormLabel variant="user-outline" className="col-span-2">
					{t("change_password.password_confirmation")}
				</FormLabel>
				<Input
					{...register("password_confirmation")}
					variant="user-outline"
					placeholder="******"
					className="col-span-2 placeholder:text-grap-20"
					type="password"
				/>
				{errors.password_confirmation && (
					<p className="text-red-500">{errors.password_confirmation.message}</p>
				)}
			</div>
			<div className="grid grid-cols-2 gap-3">
				<Button type="submit" className="col-span-2" variant="primary">
					{t("change_password.button")}
				</Button>
			</div>
		</form>
	);
};

export default ChangePasswordForm;
