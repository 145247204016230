import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../store/atoms";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAxiosInstance } from "../configs/API/AxiosInstance";
import { useApiRequests } from "../configs/API/ApiRequests";
import * as yup from "yup";
import { TLoginInputs } from "../types";

export const loginSchema = yup
	.object()
	.shape({
		email: yup.string().required(),
		password: yup.string().required(),
	})
	.required();

export const useLogin = () => {
	const { loginApi } = useApiRequests();
	const { setAuthToken } = useAxiosInstance();
	const [, setUserData] = useRecoilState(userDataAtom);
	const { t } = useTranslation();

	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: loginApi,
		onSuccess: (res) => {
			if (res) {
				setUserData(null);
				setAuthToken(res?.data.plainTextToken);
				setUserData(res?.data);
				if (res.data.accessToken.abilities[0] === "make_consent")
					navigate("/consent");
				else navigate("/");
			}
		},
		onError: (error) => {
			if ((error as any)?.response.status === 404) {
				toast({
					status: "error",
					title: t("No User Found"),
					isClosable: true,
				});
			}
			if (
				(error as any)?.response.status === 401 ||
				(error as any)?.response.status === 422
			) {
				toast({
					status: "error",
					title:
						'Ops, le credenziali inserite non sono corrette! Se non ti ricordi la password, clicca su "Recupera password?"',
					isClosable: true,
				});
			}
			if (
				(error as any)?.response.status === 403 &&
				(error as any)?.response.data.error === "not-verified"
			) {
				toast({
					status: "error",
					title:
						"Il tuo account non è attivo! Clicca sulla mail che ti abbiamo inviato in fase di registrazione",
					isClosable: true,
				});
			}
			if (
				(error as any)?.response.status === 403 &&
				(error as any)?.response?.data?.error === "not-active"
			) {
				toast({
					status: "error",
					title:
						"Ops, sembra che il tuo account non sia verificato. Contattaci all`indirizzo di customercare",
					isClosable: true,
				});
			}
		},
	});

	const toast = useToast();

	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TLoginInputs>({
		resolver: yupResolver(loginSchema),
	});

	const onSubmit: SubmitHandler<TLoginInputs> = (data: TLoginInputs) =>
		mutate({ ...data, device_name: "web" });

	const useLogout = () => {
		setUserData(null);
		setAuthToken("");
		window.location.replace("/login");
	};

	return { onSubmit, register, handleSubmit, isLoading, errors, useLogout };
};
