import { TLoginInputs, TRegisterForm } from "../../types";
import { useAxiosInstance } from "./AxiosInstance";

export const useApiRequests = () => {
	const { axiosClient } = useAxiosInstance();

	return {
		registerApi: (data: TRegisterForm) =>
			axiosClient.post("/user/register", data),

		validateEmail: (data: { email: string }) =>
			axiosClient.post("/user/validate-email", { email: data.email }),

		validateFiscalCode: (data: any) =>
			axiosClient.post("/user/validate-fiscal", data),

		loginApi: (data: TLoginInputs & { device_name: string }) =>
			axiosClient.post("/user/login", data),

		passwordResetRequest: (data: { email: string }) =>
			axiosClient.post("user/password-reset-request", { email: data.email }),

		passwordReset: (data: any) => axiosClient.post("user/password-reset", data),

		activateUser: (data: { code: string }) =>
			axiosClient.post("/user/activate", data),

		consentApi: () => axiosClient.post("/user/consent"),

		getProfession: async () => axiosClient.get("/data/professions"),

		getCountries: async () => axiosClient.get("/data/countries"),

		getProvinces: async () => axiosClient.get("/data/provinces"),

		getSSOconfig: async () => axiosClient.get("/data/sso-config"),

		getProvinceCities: async (prov: string) =>
			await axiosClient.get(`/data/cities/${prov}`),

		getProvinceRegion: async (prov: string) =>
			axiosClient.get(`/data/region/${prov}`),

		getProfile: async () => await axiosClient.get("/user/profile"),

		getNotifications: async () => await axiosClient.get("/user/notifications"),

		setNotifications: async ({ status, notification }: any) =>
			await axiosClient.post("/user/notifications", { notification, status }),

		changePassword: async (data: any) =>
			await axiosClient.post("/user/change-password", data),

		updateProfile: async (data: any) =>
			await axiosClient.post("/user/profile/update", data),

		getUserInvitations: async () =>
			await axiosClient.get("user/my-invitations"),
		acceptInvitations: async (invitation: number) =>
			await axiosClient.post("/community/accept-invitation", { invitation }),
		cancelInvitations: async (invitation: number) =>
			await axiosClient.post("/community/cancel-invitation", { invitation }),

		getTrending: async () => axiosClient.get("community/trending"),
		getLatest: async () => axiosClient.get("community/latest"),
		getFeatured: async () => axiosClient.get("community/get-featured"),
		getMyCommunities: async () => axiosClient.get("user/my-communities"),
		getAllCommunities: async (page: number) =>
			axiosClient.get(`community?page=${page}`),
		getCategories: async () => axiosClient.get("community/categories"),
		getCommunityDetails: async (id: string) =>
			axiosClient.get(`community/${id}/details`),

		getLatestCommunitiesByCategory: async (id: number) =>
			axiosClient.get(`community/${id}/communities/new`),
		getAllCommunitiesByCategory: async (id: number) =>
			axiosClient.get(`community/${id}/communities/all`),

		getCommunityMembers: async (id: string) =>
			axiosClient.get(`community/${id}/users`),
		suggestNewCommunity: async (data: any) =>
			axiosClient.post("community/suggest", data),

		joinCommunity: (communityId: string) =>
			axiosClient.post("/community/join", { communityId }),
		cancelOrExitCommunity: (communityId: string) =>
			axiosClient.delete(`community/${communityId}/exit`),

		searchGikis: ({
			keyword,
			category,
		}: {
			keyword: string;
			category?: number;
		}) => axiosClient.post("/community/search", { keyword, category }),

		// Chat
		sendMessage: (communityId: string, data: any) =>
			axiosClient.post(`/conversation/${communityId}/message/send`, data),

		getPinnedMessages: (communityId: string) =>
			axiosClient.get(`/conversation/${communityId}/message/pin`),

		pinMessage: ({
			communityId,
			messageId,
		}: {
			communityId: string;
			messageId: string;
		}) =>
			axiosClient.post(`/conversation/${communityId}/message/pin`, {
				message_id: messageId,
			}),

		unpinMessage: ({
			communityId,
			messageId,
		}: {
			communityId: string;
			messageId: string;
		}) =>
			axiosClient.post(`/conversation/${communityId}/message/unpin`, {
				message_id: messageId,
			}),

		pinConversation: (communityId: string) =>
			axiosClient.post(`/conversation/${communityId}/pin`),

		inviteUser: (data: { community_id: string; receiver_email: string }) =>
			axiosClient.post("/community/invite/user", data),

		inviteUsers: (community_id: string, users: string[]) => {
			return axiosClient.post(`/community/${community_id}/invite`, { users });
		},

		deleteConversation: (id: string) =>
			axiosClient.delete(`/conversation/${id}/delete`),

		muteConversation: ({
			communityId,
			mute,
		}: {
			communityId: string;
			mute: number;
		}) => axiosClient.post(`/conversation/${communityId}/mute`, { mute }),

		unpinConversation: (communityId: string) =>
			axiosClient.post(`/conversation/${communityId}/unpin`),

		getMedia: (communityId: string) =>
			axiosClient.get(`/conversation/${communityId}/media`),

		getDocuments: (communityId: string) =>
			axiosClient.get(`/conversation/${communityId}/documents`),

		getLinks: (communityId: string) =>
			axiosClient.get(`/conversation/${communityId}/links`),

		writeCommunityReport: (communityId: string, data: any) =>
			axiosClient.post(`/conversation/${communityId}/write-report`, data),

		searchConversationMessages: (communityId: string, data: any) =>
			axiosClient.post(`/community/${communityId}/message/search`, data),

		searchAllCommunityMessages: (data: any) =>
			axiosClient.post("/community/message/search", data),

		getUserMembers: () => axiosClient.get("/user/list"),

		getUserDetails: (userId: string) =>
			axiosClient.get(`/user/${userId}/details`),

		getConversation: ({ communityId, page }: any) =>
			axiosClient.get(
				`/community/${communityId}/conversation?per_page=10&page=${page}`
			),

		sendMassage: ({ communityId, data }: any) =>
			axiosClient.post(`/conversation/${communityId}/message/send`, data),

		getCommunityInfos: (communityId: string) =>
			axiosClient.get(`/community/${communityId}/more-info`),

		getConversationMedia: (
			communityId: string,
			type: "links" | "media" | "documents",
			page: number
		) => axiosClient.get(`/conversation/${communityId}/${type}?page=${page}`),

		searchCoversationMessages: ({
			communityId,
			keyword,
			type,
		}: {
			communityId: string;
			keyword: string;
			type: number;
		}) =>
			axiosClient.post(`/community/${communityId}/message/search`, {
				keyword: keyword,
				type: type,
			}),

		getAllConferences: async () => axiosClient.get("/conference"),

		scheduleConference: async ({ id, data }: { id: string; data: any }) =>
			axiosClient.post(`/community/${id}/conference/schedule`, data),

		getConference: async (id: string) =>
			axiosClient.get(`/user/${id}/get-nearest-conference`),

		enrollConference: async (MeetId: string) =>
			axiosClient.post(`/user/${MeetId}/enroll-me`),

		joinMeet: async (conferenceId: string) =>
			axiosClient.post(`/user/${conferenceId}/join-meeting`),

		leaveMeeting: ({ MeetId, id }: { MeetId: string; id: string }) =>
			axiosClient.delete(`/user/${MeetId}/${id}/leaveMeeting`),

		deleteMeetig: async ({ MeetId, id }: { MeetId: string; id: string }) =>
			axiosClient.delete(`/community/${id}/meeting/${MeetId}/delete-meeting`),

		startMeeting: async ({
			conferenceId,
			id,
		}: {
			conferenceId: string;
			id: string;
		}) =>
			axiosClient.post(
				`/community/${id}/conference/${conferenceId}/start-meeting`
			),

		deleteUser: async ({ id, userId }: { id: string; userId: string }) =>
			axiosClient.post(`/community/${id}/user/${userId}/remove`),

		updateCommunity: async ({ id, data }: { id: string; data: any }) =>
			axiosClient.post(`/community/${id}/edit`, data),

		getVendorSettings: () => axiosClient.get("/vendor/init"),

		searchUsers: (keyword: string) =>
			axiosClient.post("/user/search", {
				keyword: keyword,
			}),

		searchMyCommunity: (data: { keyword: string; type: number }) =>
			axiosClient.post("/community/my-community/search", data),

		getConfigs: () => axiosClient.get("/vendor/configurations"),

		getSuggestedGikis: () => axiosClient.get("/community/suggested"),

		getUnsubscribedMembers: (id: string) =>
			axiosClient.get(`/community/${id}/unsubscribed_users`),

		getCustomWidget: () => axiosClient.get("/user/widget"),

		getUpcomingConferences: (id: string) =>
			axiosClient.get(`/community/${id}/conferences/upcoming`),
		getPastConferences: (id: string) =>
			axiosClient.get(`/community/${id}/conferences/past`),
		getCommunityConferences: (id: string) =>
			axiosClient.get(`/community/${id}/conferences`),

		iNeedHelp: (message: string) => {
			const formData = new FormData();

			formData.set("message", message);

			return axiosClient.post(`/vendor/help`, formData);
		},

		getMessageImage: (id: string) => {
			return `/conversation/message/${id}/attachment`;
		},

		deleteConference: (community: string, conference: string) => {
			return axiosClient.post(
				`community/${community}/conference/${conference}/delete`
			);
		},

		endConference: (conference: string) => {
			return axiosClient.post(`/community/${conference}/end`);
		},

		singleInvite: (community: string, name: string, email: string) => {
			return axiosClient.post(`/community/${community}/single-invite`, {
				name,
				email,
			});
		},

		getMeetingInfo: (conferenceId: string) => {
			return axiosClient.get(`/user/${conferenceId}/meeting-info`);
		},
	};
};
