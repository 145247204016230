import { Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
const FriendsSmall = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("Members")}>
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.81829 6.99987C3.61338 6.99987 2.63647 6.02296 2.63647 4.81805C2.63647 3.61314 3.61338 2.63623 4.81829 2.63623C6.0232 2.63623 7.00011 3.61314 7.00011 4.81805C7.00011 6.02296 6.0232 6.99987 4.81829 6.99987Z'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M6.45455 23.3634H5.00825C3.97384 23.3634 3.11011 22.5746 3.01646 21.5445L2.71065 18.1806C2.66552 17.6842 2.3308 17.2616 1.8579 17.1039V17.1039C1.34557 16.9332 1 16.4537 1 15.9137V11.9089C1 10.704 1.97691 9.72705 3.18182 9.72705H6.45455C7.24764 9.72705 7.942 10.1509 8.32382 10.7836'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M21.1818 6.99987C22.3867 6.99987 23.3636 6.02296 23.3636 4.81805C23.3636 3.61314 22.3867 2.63623 21.1818 2.63623C19.9769 2.63623 19 3.61314 19 4.81805C19 6.02296 19.9769 6.99987 21.1818 6.99987Z'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M19.5455 23.3634H20.9918C22.0262 23.3634 22.89 22.5746 22.9836 21.5445L23.2894 18.1806C23.3346 17.6842 23.6693 17.2616 24.1422 17.1039V17.1039C24.6545 16.9332 25.0001 16.4537 25.0001 15.9137V11.9089C25.0001 10.704 24.0232 9.72705 22.8183 9.72705H19.5455C18.7525 9.72705 18.0581 10.1509 17.6763 10.7836'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M13 7.54545C11.1924 7.54545 9.72729 6.08036 9.72729 4.27273C9.72729 2.46509 11.1924 1 13 1C14.8077 1 16.2727 2.46509 16.2727 4.27273C16.2727 6.08036 14.8077 7.54545 13 7.54545Z'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M15.8801 23.1663C15.7937 24.2029 14.9271 25.0002 13.887 25.0002H12.1129C11.0728 25.0002 10.2062 24.2029 10.1199 23.1663L9.81657 19.5268C9.76315 18.8858 9.30763 18.3499 8.68358 18.1939V18.1939C8.01467 18.0266 7.54541 17.4256 7.54541 16.7361V12.4548C7.54541 11.2499 8.52232 10.2729 9.72723 10.2729H16.2727C17.4776 10.2729 18.4545 11.2499 18.4545 12.4548V16.7361C18.4545 17.4256 17.9852 18.0266 17.3163 18.1939V18.1939C16.6923 18.3499 16.2368 18.8858 16.1833 19.5268L15.8801 23.1663Z'
        className={className}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
    </Tooltip>
  );
};

export default FriendsSmall;
