// import SearchBox from "../SearchBox";
// import NewGikisBox from "../NewGikisBox";
import GikisBox from "../GikisBox";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
	searchAllGikisResultAtom,
	selectedCategoryIdAtom,
} from "../../../../store/atoms";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { FadeBox } from "../../../../components/boxes/FadeBox";
// import { SearchResult } from "../SearchResult";
import { useEffect } from "react";
import { Avatar } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import AllGikis from "../AllGikis/AllGikis";
interface CategoryGikisProps {
	title: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
}

const CategoryGikis: React.FunctionComponent<CategoryGikisProps> = ({
	title,
	data,
}) => {
	const [categoryId] = useRecoilState(selectedCategoryIdAtom);
	const { t } = useTranslation();
	const { getAllCommunitiesByCategory } =
		useApiRequests();
	const allGikisQuery = useQuery({
		queryKey: ["gikis", categoryId],
		queryFn: ({ queryKey }) => getAllCommunitiesByCategory(Number(queryKey[1])),
		select: (res) => res.data.data,
	});

	// const latestGikisQuery = useQuery({
	// 	queryKey: ["gikis", categoryId],
	// 	queryFn: ({ queryKey }) =>
	// 		getLatestCommunitiesByCategory(Number(queryKey[1])),
	// 	select: (res) => res.data.data,
	// });

	// const [searchResults] = useRecoilState(searchAllGikisResultAtom);
	const resetSearchAtom = useResetRecoilState(searchAllGikisResultAtom);

	useEffect(() => {
		return () => {
			resetSearchAtom();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectedCategory = data.find((item) => item.name === title);

	return (
		<div className="flex flex-col gap-[30px] bg-white p-3 pt-0 transition-all duration-300 w-full min-h-dvh">
			{/* <SearchBox discripton='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut...' /> */}
			{/* {searchResults.keyWord ? (
        <SearchResult />
      ) : ( */}
			<FadeBox className="flex flex-col gap-9">
				<div className="flex items-center gap-[10px] p-4 border-b-[1px] border-b-gray-50">
					<Avatar
						src={`${process.env.REACT_APP_MEDIA_URL}${selectedCategory?.image}`}
						name={selectedCategory?.name}
						w={"48px"}
						h={"48px"}
						rounded={4}
					/>
					<div className="flex flex-col gap-[2px]">
						<h2 className="text-xs font-bold text-blue-primary leading-[14.4px]">
							{title}
						</h2>
						<div className="flex items-center gap-[10px] text-[10px]">
							{allGikisQuery.isLoading ? (
								<p className="text-[#718096] h-[15px] w-16 rounded-4 bg-slate-400 animate-pulse duration-300"></p>
							) : (
								<p className="text-[#718096]">
									{selectedCategory.count} {t("Members")}
								</p>
							)}
						</div>
					</div>
				</div>
				{/* {!latestGikisQuery.isLoading && !!latestGikisQuery.data.length ? (
					<NewGikisBox
						isLoading={latestGikisQuery.isLoading}
						data={latestGikisQuery.data}
						title={title}
					/>
				) : (
					<></>
				)} */}
				{/* All Gikis */}
				<GikisBox
					isLoading={allGikisQuery.isLoading}
					data={allGikisQuery.data}
					title={t("All") + ` ${title}`}
				/>
			</FadeBox>
			{/* // )} */}
		</div>
	);
};

export default CategoryGikis;
