import React from "react";
import PinnedButton from "./PinnedButton";
import PinnedSkeleton from "./PinnedSkeleton";

type Props = {
  isLoading: boolean;
  data: {
    id: number;
    name: string;
    type: "Image";
    user: {
      id: string;
      sso_id: number;
      photo: null | string;
      first_name: string;
      last_name: string;
    };
    attachment_date: string;
    size: number;
    url: string;
  }[];
};

const AllDocuments = ({ isLoading, data }: Props) => {
  return (
    <div>
      <div className='flex flex-col gap-5 px-5'>
        {data.length > 0 &&
          data.map((item) => (
            <PinnedButton
              content={item.name}
              size={item.size}
              type={"file"}
              link={item.url}
              key={item.id}
            />
          ))}
        {isLoading && (
          <>
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
          </>
        )}
      </div>
    </div>
  );
};

export default AllDocuments;
