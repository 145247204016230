import { useEffect } from 'react'

export const useUpdateLogo = () => {
  const logo = localStorage.getItem('logo')

  useEffect(() => {
    // Function to change the favicon
    const changeFavicon = () => {
      const link = document.querySelector('link[rel*=\'icon\']')
      if (link) {
        // Remove the existing favicon
        link.parentNode?.removeChild(link)
      }

      const newFavicon = document.createElement('link')
      newFavicon.rel = 'icon'
      newFavicon.href = `${process.env.REACT_APP_MEDIA_URL}${logo}` // Set this to the URL of your new favicon
      document.head.appendChild(newFavicon)
    }

    // Call the changeFavicon function when the logo changes
    changeFavicon()
  }, [logo])

  return
}
