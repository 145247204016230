import GikiCard from "../../../components/boxes/GikiCard";

import img from "../../../assets/svgs/gikiImg.svg";
import { GikiSkeleton } from "../../../components/boxes/GikiSkeleton";
import { Giki } from "../../../types";
import { NoGikis } from "../../../components/SVGs/NoGikis";
// import SectionHeader from "./NewInvites/SectionHeader";
import { FadeBox } from "../../../components/boxes/FadeBox";
// import {
// 	Carousel,
// 	CarouselContent,
// 	CarouselItem,
// 	CarouselNext,
// 	CarouselPrevious,
// } from "../../../components/carousel/Embla";

interface GikisBoxProps {
	title: string;
	data: Giki[];
	isLoading?: boolean;
}

const GikisBox: React.FunctionComponent<GikisBoxProps> = ({
	title,
	data,
	isLoading,
}) => {
	return (
		<div className="pb-5">
			<div className="pb-4">
				{/* <SectionHeader>{title}</SectionHeader> */}
			</div>
			{!data?.length && !isLoading ? (
				<NoGikis title={title} />
			) : (
				<>
					{isLoading ? (
						// <Carousel className="w-full">
						// 	<CarouselContent className="-ml-1">
						// 		{Array.from({ length: 5 }).map((_, index) => (
						// 			<CarouselItem
						// 				key={index}
						// 				className="pl-4 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
						// 			>
						// 				<GikiSkeleton />
						// 			</CarouselItem>
						// 		))}
						// 	</CarouselContent>
						// </Carousel>
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
							{Array.from({ length: 5 }).map((_, index) => (
								<GikiSkeleton key={index} />
							))}
						</div>
					) : (
						<FadeBox>
							{/* <Carousel className="w-full">
								<div className="flex items-center absolute right-0 -top-11 gap-5">
									<CarouselPrevious className="relative flex items-center justify-center border-2 translate-x-0 translate-y-0 rotate-0" />
									<CarouselNext className="relative flex items-center justify-center border-2 translate-x-0 translate-y-0 rotate-0" />
								</div>
								<CarouselContent className="-ml-1">
									{data?.map((giki) => (
										<CarouselItem
											key={giki.id}
											className="pl-4 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
										>
											<GikiCard
												data={giki}
												coverImage={giki.cover_image || ""}
												img={img}
											/>
										</CarouselItem>
									))}
								</CarouselContent>
							</Carousel> */}
							<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
								{data?.map((giki) => (
									<GikiCard
										key={giki.id}
										data={giki}
										coverImage={giki.cover_image || ""}
										img={img}
									/>
								))}
							</div>
						</FadeBox>
					)}
				</>
			)}
		</div>
	);
};

export default GikisBox;
