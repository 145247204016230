import { Members } from '../types'

const UseGetAdmin = () => {
    
  const ifUserAdmin = (members: Members[], currentUserId: string) => {
    const admin = members.filter((member) => member.is_admin === 1)
    const adminId = admin.map((admin) => admin.id)
    const isAdmin = adminId.includes(currentUserId)

    return isAdmin
  }

  return { ifUserAdmin }
}

export default UseGetAdmin
