import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { massagesArr } from "../store/atoms";
import paginationStore from "../store/pagination.store";

const useJumpToMessage = ({ page }: { page: number }) => {
	const { id } = useParams();
	const [, setPagination] = useRecoilState(paginationStore);
	const [, setMessages] = useRecoilState(massagesArr);

	const { getConversation } = useApiRequests();

	const jumpToMessage = useMutation({
		mutationFn: () => getConversation({ page, communityId: id }),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSuccess: (data: any) => {
			const messagesData = data.data;

			setMessages(messagesData.messages.data);
			setPagination((prevPagination) => ({
				...prevPagination,
				currentPage: messagesData.messages.current_page,
				totalPages: Math.ceil(messagesData.messages.total / 10),
				currentNext: messagesData.messages.current_page,
				currentPrev: messagesData.messages.current_page,
			}));
		},
	});

	return {
		jumpToMessage,
	};
};

export default useJumpToMessage;
