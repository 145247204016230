import { memo } from 'react'
import { FadeBox } from './FadeBox'

interface MessageSkeletonProps {
  isRightSide?: boolean
}

const MessageSkeleton: React.FunctionComponent<MessageSkeletonProps> = ({ isRightSide }) => {
  const width = Math.floor(Math.random() * 100) + 150
  const item2Width = Math.floor(Math.random() * 100) + 150

  return (
    <FadeBox
      className={`flex items-center mt-4 space-x-3 ${
        isRightSide ? 'ml-auto flex-row-reverse' : ''
      }`}
    >
      <svg
        className='w-10 h-10 text-gray-200 dark:text-gray-400 '
        aria-hidden='true'
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        viewBox='0 0 20 20'
      >
        <path d='M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z' />
      </svg>
      <div
        className={`bg-white hover:bg-[#cbd5e0] relative rounded-lg ${
          isRightSide ? 'rounded-rl-none' : 'rounded-tl-none'
        } p-[10px]`}
      >
        <div
          style={{ width: width }}
          className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 mb-2'
        ></div>
        <div
          style={{ width: item2Width }}
          className='h-2 bg-gray-200 rounded-full dark:bg-gray-400'
        ></div>
      </div>
    </FadeBox>
  )
}

export default memo(MessageSkeleton)
