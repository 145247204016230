import { Message } from "../hooks/useMessageHandler";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { Chat, Giki, TRegisterForm, TUserData } from "../types";
const { persistAtom } = recoilPersist();

export const registerStep = atom<{
	step: number;
	formData: TRegisterForm;
}>({
	key: "registerStep",
	default: {
		step: 1,
		formData: {
			title: "",
			first_name: "",
			last_name: "",
			email: "",
			email_confirmation: "",
			password: "",
			password_confirmation: "",
			profession: "",
			territory: "",
			specialization: "",
			specialization_2: "",
			province_enployment: "",
			employment: "",
			regione_enployment: "",
			board_member: "",
			board_number: "",
			province_board: "",
		},
	},
});

export const userDataAtom = atom<TUserData | null>({
	key: "userDataAtom",
	default: null,
	effects_UNSTABLE: [persistAtom],
});

export const selectedCategoryIdAtom = atom<number>({
	key: "selectedCategoryIdAtom",
	default: 0,
});

export const searchAllGikisResultAtom = atom<{
	gikis: Giki[];
	keyWord: string;
}>({
	key: "searchAllGikisResult",
	default: {
		gikis: [],
		keyWord: "",
	},
});

export const invitationsNumberAtom = atom<number[]>({
	key: "invitationsNumberAtom",
	default: [],
});

export const selectedChat = atom<Chat>({
	key: "selectedChat",
	default: {
		id: "",
		name: "",
		image: "",
		cover_image: "",
		short_description: "",
		type: 0,
		users_count: 0,
		request_to_joined: 0,
		unread_messages: 0,
	},
});

export const isSelectedChat = atom({
	key: "isSelectedChat",
	default: false,
});

export const loadingChat = atom<boolean>({
	key: "loading-chat",
	default: false,
});

export const userSelected = atom({
	key: "userSelected",
	default: {
		first_name: "",
		id: "",
		last_name: "",
		photo: "",
		profession: "",
	},
});

export const replyMassge = atom<any>({
	key: "replyMassge",
	default: {
		id: "",
		massageImg: "",
		name: "",
		massage: "",
	},
});

export const selectedMassage = atom({
	key: "selctedMassage",
	default: {
		id: "",
	},
});

export const serchedMassage = atom({
	key: "serchedMassage",
	default: 0,
});

export const isMeeting = atom({
	key: "isMeeting",
	default: true,
});

export const meetingAtom = atom<any>({
	key: "meetingAtom",
	default: null,
});

export const currentNearestConference = atom<{
	id: string;
	title: string;
	status: number;
	description: string;
	start_date: string;
	label_start_date: string;
	duration: number;
	attendees: number;
	on_air: boolean;
	is_enrolled: boolean;
	created_by: string;
} | null>({
	key: "currentNearestConference",
	default: null,
});

export const notificationAtom = atom<any>({
	key: "notificationAtom",
	default: false,
});
export const massagesArr = atom<Message[]>({
	key: "massagesArr",
	default: [],
});

export const superSearchAtom = atom({
	key: "superSearchAtom",
	default: false,
});

export const currentOnlineUsersAtom = atom({
	key: "currentOnlineUsersAtom",
	default: 0,
});

export const sidebarState = atom<boolean>({
	key: "sidebarStateAtom",
	default: true,
});

export const hasOpenChat = atom<boolean>({
	key: "hasOpenChat",
	default: false,
});

export const topBarMobileMenuState = atom<boolean>({
	key: "topBarMobileMenuState",
	default: false,
});

export const showWelcomeFirstAccess = atom<boolean>({
	key: "showWelcomeFirstAccess",
	default: false,
});
