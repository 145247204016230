import {
	FormControl,
	Input,
	FormLabel,
	Textarea,
	Button,
	useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FadeBox } from "../../../components/boxes/FadeBox";
import { RegisterInput } from "../../../components/inputs/RegisterInput";
import { RegisterSelect } from "../../../components/inputs/RegisterSelect";
import { UpdateProfileImage } from "../../../components/UpdateProfileImage";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import {
	titleOptions,
	ospedaleOptions,
	operativitàOptions,
	ordineOptions,
} from "../../../configs/Constants";
import { t } from "i18next";
import {} from "postcss";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { TUpdateProfile } from "../../../types";
import { FaChevronLeft } from "react-icons/fa";
import { selectedTab } from "../selectedTab";
import { useRecoilState } from "recoil";

export const Info = ({ profileData, photo }: any) => {
	const { getProvinces, getProfession, getProvinceRegion, updateProfile } =
		useApiRequests();
	const [specializations, setspecializations] = useState([]);
	const [otherSpecializations, setotherSpecializations] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
		getValues,
	} = useForm<TUpdateProfile>({ defaultValues: profileData });
	const queryClient = useQueryClient();
	const [, setselectedTap] = useRecoilState(selectedTab);

	const watchProfessions = useWatch({ control, name: "profession" });
	const watchSpecialization = useWatch({ control, name: "specialization" });
	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: updateProfile,
	});
	const toast = useToast();
	const provincesQuery = useQuery({
		queryKey: ["provinces"],
		queryFn: getProvinces,
		select: (data: any) => {
			return Object.keys(data.data.data).map((prov: any) => {
				return { id: prov, name: data.data.data[prov] };
			});
		},
	});
	const professionQuery = useQuery({
		queryKey: ["profession"],
		queryFn: getProfession,
	});
	const watchProvience = useWatch({ control, name: "province_enployment" });

	useEffect(() => {
		if (watchProfessions) {
			const selectedProfession = professionQuery.data?.data.data.find(
				(profess: any) => profess.name === watchProfessions
			);
			setspecializations(selectedProfession?.specializations || []);
			setotherSpecializations(selectedProfession?.specializations || []);
		}
	}, [watchProfessions, professionQuery.data?.data.data]);

	useEffect(() => {
		setValue("specialization", profileData.specialization);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specializations.length]);

	useEffect(() => {
		professionQuery.data?.data.data &&
			setValue("profession", profileData.profession);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [professionQuery.data?.data.data]);

	useEffect(() => {
		if (watchSpecialization) {
			setotherSpecializations(
				specializations.filter((spec: any) => spec.name !== watchSpecialization)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchSpecialization]);

	useEffect(() => {
		if (
			getValues("province_enployment") &&
			provincesQuery.status === "success"
		) {
			// const provinceCode = professionQuery.data?.data.map((item: ) => )
			getProvinceRegion(
				provincesQuery.data?.find(
					(item) => item.name === getValues("province_enployment")
				)?.id
			).then((res) => {
				if (res.data.data) {
					setValue("regione_enployment", res.data.data);
				} else {
					setValue("regione_enployment", "");
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchProvience]);

	useEffect(() => {
		setValue("province_board", profileData.province_board);
		setValue("province_enployment", profileData.province_enployment);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [provincesQuery.data?.length]);

	const onSubmit = (data: TUpdateProfile) => {
		const formData = new FormData();

		for (const key in data) {
			formData.append(`profile[${key}]`, data[key as keyof typeof data]);
		}

		if (selectedFile) formData.append("photo", selectedFile);

		mutate(formData, {
			onSuccess: () => {
				toast({
					title: "Success",
					description: "Profile updated successfully",
					status: "success",
					duration: 3000,
				});
				queryClient.invalidateQueries();
			},
			onError: () => {
				toast({
					title: "Error",
					description: "Something went wrong",
					status: "error",
					duration: 3000,
				});
			},
		});
	};

	return (
		<FadeBox className="lg:col-span-7 col-span-9 flex-col gap-[30px] bg-white px-3 py-[30px] max-h-dvh overflow-y-auto">
			<form onSubmit={handleSubmit(onSubmit)}>
				<p className="text-/tweaks.sh font-semibold text-left text-blue-primary mb-4">
					<button className="m-2 text-xs" onClick={() => setselectedTap("")}>
						<FaChevronLeft />
					</button>
					{t("Personal Info")}
				</p>
				<div className="bg-[#F7FAFC] px-4 rounded-md pt-[30px] pb-4 mb-5 flex flex-col gap-[30px]">
					<UpdateProfileImage
						photo={photo}
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
					/>

					<div className="grid md:grid-cols-5 grid-cols-1 gap-3">
						<div className="col-span-1">
							<RegisterSelect
								errors={errors}
								name="title"
								register={register}
								options={titleOptions}
							/>
						</div>
						<div className="col-span-2">
							<RegisterInput
								errors={errors}
								name="first_name"
								register={register}
							/>
						</div>
						<div className="col-span-2">
							<RegisterInput
								errors={errors}
								name="last_name"
								register={register}
							/>
						</div>
					</div>

					<div className="grid md:grid-cols-2 grid-cols-1 gap-3">
						<RegisterInput errors={errors} name="email" register={register} />

						<RegisterInput
							isRequired={false}
							errors={errors}
							name="phone_number"
							register={register}
						/>
					</div>

					<FormControl>
						<div className="flex justify-between">
							<FormLabel variant="register-outline" mb={"12px"}>
								{t("register.registerForm.bio")}
							</FormLabel>
						</div>

						<Textarea
							{...register("bio")}
							defaultValue={""}
							border="solid 1px #bbbbbb59"
							placeholder={t("register.registerForm.bio_placeholder") || ""}
							variant="user-outline"
							className="placeholder:text-grap-20"
							fontSize="12px"
						/>
					</FormControl>

					<FormControl>
						<div className="flex justify-between">
							<FormLabel variant="register-outline" mb={"12px"}>
								{t("register.registerForm.social_links")}
							</FormLabel>
						</div>

						<div className="flex flex-col gap-3">
							<div className="flex gap-5 items-center">
								<Input
									defaultValue={""}
									border="solid 1px #bbbbbb59"
									placeholder={"Website: https://www.google.com/"}
									variant="user-outline"
									{...register("linkedin")}
									className="placeholder:text-grap-20"
									fontSize="12px"
								/>
							</div>

							<div className="flex gap-5 items-center">
								<Input
									defaultValue={""}
									border="solid 1px #bbbbbb59"
									placeholder={t(
										"Email (this email can be different from the one registered)"
									)}
									variant="user-outline"
									{...register("secondary_email")}
									className="placeholder:text-grap-20"
									fontSize="12px"
								/>
							</div>

							<div className="flex gap-5 items-center">
								<Input
									defaultValue={""}
									border="solid 1px #bbbbbb59"
									placeholder={"LinkedIn"}
									{...register("linkedin")}
									variant="user-outline"
									className="placeholder:text-grap-20"
									fontSize="12px"
								/>
							</div>
						</div>
					</FormControl>
				</div>

				<p className="text-base font-semibold text-left text-blue-primary mb-4">
					{t("Professional Info")}
				</p>

				<div className="bg-[#F7FAFC] px-4 rounded-md pt-[30px] pb-4 mb-5 grid grid-cols-9  gap-[30px]">
					<div className="col-span-9 flex gap-4 sm:flex-row flex-col">
						<div className="flex flex-col sm:w-1/2 w-full gap-3">
							<RegisterSelect
								errors={errors}
								name="profession"
								register={register}
								options={professionQuery.data?.data.data || []}
							/>
						</div>
						<div className="flex flex-col sm:w-1/2 w-full gap-3">
							<RegisterSelect
								errors={errors}
								options={ospedaleOptions}
								name="territory"
								register={register}
							/>
						</div>
					</div>

					<div className="col-span-9 flex gap-4">
						{specializations.length > 0 && (
							<div className="flex flex-col sm:w-1/2 w-full gap-3">
								<RegisterSelect
									errors={errors}
									options={specializations}
									name="specialization"
									register={register}
								/>
							</div>
						)}
						{specializations[1] && (
							<div className="flex flex-col sm:w-1/2 w-full gap-3">
								<RegisterSelect
									errors={errors}
									name="specialization_2"
									register={register}
									options={otherSpecializations}
									required={false}
								/>
							</div>
						)}
					</div>

					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterSelect
							errors={errors}
							name="employment"
							register={register}
							options={operativitàOptions}
						/>
					</div>
					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterSelect
							errors={errors}
							name="province_enployment"
							options={provincesQuery.data || []}
							register={register}
						/>
					</div>
					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterInput
							errors={errors}
							name="regione_enployment"
							register={register}
						/>
					</div>

					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterSelect
							errors={errors}
							options={ordineOptions}
							name="board_member"
							register={register}
						/>
					</div>

					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterSelect
							errors={errors}
							name="province_board"
							register={register}
							options={provincesQuery.data || []}
						/>
					</div>
					<div className="sm:col-span-3 col-span-9 flex flex-col gap-3">
						<RegisterInput
							errors={errors}
							name="board_number"
							register={register}
						/>
					</div>
				</div>
				<div className="flex w-full justifiy-center">
					<Button
						isLoading={isLoading}
						disabled={isLoading}
						bg={"#5B6575"}
						type="submit"
						color="white"
						w={"255px"}
						m="0 auto"
						textTransform="capitalize"
					>
						{t("update_profile.save_changes")}
					</Button>
				</div>
			</form>
		</FadeBox>
	);
};
