import Digit from "./Digit";
// credits to buildui.com

const Counter = ({ value }: { value: number }) => {
  return (
    <div className='flex overflow-hidden rounded leading-none text-[20px] font-bold'>
      <Digit place={10} value={value} />
      <Digit place={1} value={value} />
    </div>
  );
};

export default Counter;
