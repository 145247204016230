import React from "react";

const Folder = ({className}: {className: string}) => {
  return (
    <svg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.1187 0.666748C1.86404 0.666748 0.830566 1.81003 0.830566 3.198V16.1355C0.830566 17.5235 1.86404 18.6667 3.1187 18.6667H18.8814C20.1361 18.6667 21.1695 17.5235 21.1695 16.1355V6.0105C21.1695 4.62253 20.1361 3.47925 18.8814 3.47925H11.0219L8.74867 1.38416C8.24619 0.92106 7.61319 0.666748 6.95907 0.666748H3.1187ZM3.1187 2.35425H6.95907C7.25682 2.35425 7.54373 2.46976 7.77244 2.68054L9.55408 4.323L7.77244 5.96545C7.54373 6.17623 7.25682 6.29175 6.95907 6.29175H2.35599V3.198C2.35599 2.72184 2.68828 2.35425 3.1187 2.35425ZM11.0219 5.16675H18.8814C19.3118 5.16675 19.6441 5.53434 19.6441 6.0105V16.1355C19.6441 16.6117 19.3118 16.9792 18.8814 16.9792H3.1187C2.68828 16.9792 2.35599 16.6117 2.35599 16.1355V7.97925H6.95907C7.61319 7.97925 8.24619 7.72494 8.74867 7.26184L11.0219 5.16675Z'
        className={className}
      />
    </svg>
  );
};

export default Folder;
