const Conferences = ({
  strokeActiveColor,
  fillAccentColor,
  fillActiveColor,
}: {
  strokeActiveColor: string;
  fillActiveColor: string;
  fillAccentColor: string;
}) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1817 10.9093C13.1817 11.562 12.6527 12.0911 11.9999 12.0911C11.3472 12.0911 10.8181 11.562 10.8181 10.9093C10.8181 10.2567 11.3472 9.72754 11.9999 9.72754C12.6527 9.72754 13.1817 10.2567 13.1817 10.9093Z'
        className={strokeActiveColor}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <mask id='path-2-inside-1_1416_1966' className={fillAccentColor}>
        <path d='M18.1717 16.4846C19.0016 15.3457 19.5001 13.9994 19.6121 12.5947C19.7241 11.19 19.4452 9.78165 18.8063 8.52564C18.1674 7.26962 17.1934 6.21489 15.9921 5.47819C14.7908 4.74149 13.4091 4.35156 11.9999 4.35156C10.5907 4.35156 9.20897 4.74149 8.00768 5.47819C6.80639 6.21489 5.83239 7.26962 5.19348 8.52564C4.55457 9.78165 4.27568 11.19 4.38769 12.5947C4.49969 13.9994 4.99821 15.3457 5.82807 16.4846' />
      </mask>
      <path
        d='M16.5553 15.3068L15.3775 16.9232L18.6103 19.2788L19.7881 17.6624L16.5553 15.3068ZM4.21166 17.6624L5.38945 19.2788L8.62228 16.9232L7.44449 15.3068L4.21166 17.6624ZM19.7881 17.6624C20.8353 16.2252 21.4644 14.5263 21.6058 12.7536L17.6184 12.4357C17.5358 13.4725 17.1678 14.4662 16.5553 15.3068L19.7881 17.6624ZM21.6058 12.7536C21.7471 10.981 21.3952 9.20384 20.5889 7.61885L17.0237 9.43242C17.4952 10.3595 17.7011 11.3989 17.6184 12.4357L21.6058 12.7536ZM20.5889 7.61885C19.7827 6.03387 18.5536 4.7029 17.0377 3.77326L14.9465 7.18313C15.8332 7.72688 16.5521 8.50537 17.0237 9.43242L20.5889 7.61885ZM17.0377 3.77326C15.5217 2.84361 13.7782 2.35156 11.9999 2.35156V6.35156C13.04 6.35156 14.0599 6.63937 14.9465 7.18313L17.0377 3.77326ZM11.9999 2.35156C10.2216 2.35156 8.47803 2.84361 6.96212 3.77326L9.05324 7.18313C9.93991 6.63937 10.9598 6.35156 11.9999 6.35156V2.35156ZM6.96212 3.77326C5.44621 4.7029 4.2171 6.03387 3.41086 7.61885L6.9761 9.43242C7.44767 8.50537 8.16658 7.72688 9.05324 7.18313L6.96212 3.77326ZM3.41086 7.61885C2.60461 9.20384 2.25268 10.981 2.39401 12.7536L6.38136 12.4357C6.29869 11.3989 6.50453 10.3595 6.9761 9.43242L3.41086 7.61885ZM2.39401 12.7536C2.53535 14.5263 3.16445 16.2252 4.21166 17.6624L7.44449 15.3068C6.83198 14.4662 6.46403 13.4725 6.38136 12.4357L2.39401 12.7536Z'
        className={fillActiveColor}
        mask='url(#path-2-inside-1_1416_1966)'
      />
      <mask id='path-4-inside-2_1416_1966' className={fillAccentColor}>
        <path d='M18.1189 22.3222C20.3812 20.9813 22.1402 18.9343 23.1257 16.4962C24.1111 14.058 24.2683 11.3637 23.5731 8.82748C22.8779 6.29128 21.3687 4.05368 19.2778 2.45878C17.1868 0.863875 14.6298 0 12 0C9.37018 0 6.81318 0.863875 4.72222 2.45878C2.63126 4.05368 1.12213 6.29128 0.426912 8.82748C-0.268305 11.3637 -0.111111 14.058 0.87432 16.4962C1.85975 18.9343 3.61885 20.9813 5.88109 22.3222' />
      </mask>
      <path
        d='M17.0991 20.6018L15.3786 21.6216L17.4182 25.0625L19.1387 24.0427L17.0991 20.6018ZM12 0V-2V0ZM4.86129 24.0427L6.58176 25.0625L8.62136 21.6216L6.90089 20.6018L4.86129 24.0427ZM19.1387 24.0427C21.778 22.4783 23.8303 20.0902 24.98 17.2456L21.2714 15.7467C20.4502 17.7785 18.9843 19.4843 17.0991 20.6018L19.1387 24.0427ZM24.98 17.2456C26.1296 14.4011 26.313 11.2577 25.5019 8.29875L21.6442 9.35621C22.2236 11.4697 22.0926 13.715 21.2714 15.7467L24.98 17.2456ZM25.5019 8.29875C24.6908 5.33983 22.9302 2.72929 20.4907 0.868568L18.0648 4.04898C19.8073 5.37807 21.0649 7.24273 21.6442 9.35621L25.5019 8.29875ZM20.4907 0.868568C18.0513 -0.99215 15.0681 -2 12 -2V2C14.1915 2 16.3224 2.7199 18.0648 4.04898L20.4907 0.868568ZM12 -2C8.93189 -2 5.94873 -0.99215 3.50927 0.868568L5.93516 4.04898C7.67763 2.7199 9.80847 2 12 2V-2ZM3.50927 0.868568C1.06982 2.72929 -0.690842 5.33983 -1.50193 8.29875L2.35576 9.35621C2.9351 7.24273 4.1927 5.37807 5.93516 4.04898L3.50927 0.868568ZM-1.50193 8.29875C-2.31302 11.2577 -2.12963 14.4011 -0.979954 17.2456L2.72859 15.7467C1.90741 13.715 1.77642 11.4697 2.35576 9.35621L-1.50193 8.29875ZM-0.979954 17.2456C0.169721 20.0902 2.22201 22.4783 4.86129 24.0427L6.90089 20.6018C5.01569 19.4843 3.54978 17.7785 2.72859 15.7467L-0.979954 17.2456Z'
        className={fillActiveColor}
        mask='url(#path-4-inside-2_1416_1966)'
      />
      <path
        d='M11.0595 16.8184H11.0597L12.9405 16.8184L12.9407 16.8184C13.1025 16.8183 13.2626 16.8515 13.4111 16.9159C13.5596 16.9803 13.6933 17.0745 13.8038 17.1927L14.5151 16.5271L13.8038 17.1927C13.9144 17.3108 13.9996 17.4505 14.054 17.6029C14.1084 17.7553 14.1309 17.9172 14.1201 18.0787C14.1201 18.0787 14.1201 18.0787 14.1201 18.0787L13.8541 22.0665C13.8191 22.5918 13.3828 23 12.8563 23H11.1439C10.6174 23 10.1811 22.5918 10.1461 22.0665L9.88006 18.0787C9.88006 18.0787 9.88006 18.0787 9.88005 18.0787C9.8693 17.9172 9.89182 17.7553 9.94622 17.6029C10.0006 17.4505 10.0857 17.3108 10.1963 17.1927C10.3069 17.0745 10.4406 16.9803 10.5891 16.9159C10.7375 16.8515 10.8977 16.8183 11.0595 16.8184Z'
        className={strokeActiveColor}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};

export default Conferences;
