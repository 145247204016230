/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
	Button,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import forWho from "../../assets/svgs/discover/popup/who.svg";
import IconButtonList from "../../components/buttons/IconButtonList";
import info from "../../assets/svgs/discover/popup/info.svg";
import date from "../../assets/svgs/discover/popup/date.svg";
import user from "../../assets/svgs/discover/popup/user.svg";
import cased from "../../assets/svgs/discover/popup/case.svg";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface GikiModalProps {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	id: string;
	coverImage?: string;
}

const GikiModal: React.FunctionComponent<GikiModalProps> = ({
	isOpen,
	onClose,
	onOpen,
	id,
	coverImage,
}) => {
	const { getCommunityDetails } = useApiRequests();
	const navigate = useNavigate();
	const { data } = useQuery({
		queryKey: ["gikiDetails"],
		queryFn: () => getCommunityDetails(id),
		select: (res) => res.data.data,
	});

	const { joinCommunity, cancelOrExitCommunity } = useApiRequests();

	const queryClient = useQueryClient();
	const joinMutation = useMutation({ mutationFn: joinCommunity });
	const cancelMutation = useMutation({ mutationFn: cancelOrExitCommunity });

	const handleJoin = () => {
		joinMutation.mutate(id);
		queryClient.invalidateQueries({ queryKey: ["gikiDetails"] });
	};
	const handleCancel = () => {
		cancelMutation.mutate(id);
		queryClient.invalidateQueries({ queryKey: ["gikiDetails"] });
	};

	useEffect(() => {
		if (joinMutation.isSuccess) {
			navigate("/chat", { state: { ...data } });
		}
	}, [joinMutation.isSuccess]);

	if (!data) return <></>;

	return (
		<Modal scrollBehavior="inside" size="6xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent aria-modal>
				<ModalHeader>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody px="30px">
					<div className="bg-gray-50 rounded-lg">
						<img
							onClick={onOpen}
							className="w-full rounded-lg object-cover"
							src={
								data.cover_image
									? `${process.env.REACT_APP_MEDIA_URL}${data.cover_image}`
									: coverImage
							}
						/>
						<div className="flex flex-col lg:flex-row items-center">
							<div className="flex flex-col w-full gap-[10px] py-4 px-4">
								<div
									onClick={onOpen}
									className="flex gap- items-center relative"
								>
									<img
										className="w-36 h-36 rounded-lg border-2 border-white -translate-y-[3.5rem] lg:absolute object-cover"
										src={`${process.env.REACT_APP_MEDIA_URL}${data.image}`}
									/>
									<p
										onClick={onOpen}
										className="text-blue-primary text-xl ml-2 lg:ml-40 font-semibold"
									>
										{data.name}{" "}
									</p>
									<span className="font-bold px-4"> .</span>
									<span className="text-sm">{data.users_count} Members </span>
								</div>

								<p className="" onClick={onOpen}>
									{data.short_description}
								</p>
								<Divider
									w={{ base: "auto", lg: "821px" }}
									borderColor="text-gray"
								/>

								<div className="grid grid-cols-2 gap-y-5  py-7">
									<div className="flex gap-2 col-span-1">
										<IconButtonList
											title="Who is it for?"
											icon={forWho}
											items={[data?.user_target]}
										/>
									</div>
									<div className="flex gap-2 col-span-1">
										<IconButtonList
											title="What to expect?"
											icon={info}
											items={[data.expectations]}
										/>
									</div>
									<div className="flex gap-2 col-span-1">
										<IconButtonList
											title="Created on:"
											icon={date}
											items={[data.created_on]}
										/>
									</div>
									<div className="flex gap-2 col-span-1">
										<IconButtonList
											isAdmins
											title="Admin:"
											icon={user}
											items={[data?.admin_name]}
										/>
									</div>
									<div className="flex gap-2 col-span-1">
										<IconButtonList
											title="Activities:"
											icon={cased}
											items={[data.activities]}
											isHTML
										/>
									</div>
								</div>
								<Divider
									w={{ base: "auto", lg: "821px" }}
									borderColor="text-gray"
								/>

								<div className="py-7">
									<p className="font-semibold py-4">About</p>
									<p>{data.description}</p>
								</div>
								<Divider
									w={{ base: "auto", lg: "821px" }}
									borderColor="text-gray"
								/>
							</div>
							{data.request_to_joined === 1 && (
								<Button
									alignSelf="start"
									mt="27px"
									fontSize="14px"
									bg="orange-primary"
									variant="primary"
									isLoading={cancelMutation.isPending}
									disabled={cancelMutation.isPending}
								>
									<span
										className="font-semibold text-white text-xs"
										onClick={handleCancel}
									>
										Request Pending
									</span>
								</Button>
							)}
							{data.request_to_joined === 0 && (
								<Button
									alignSelf="start"
									mt="27px"
									fontSize="14px"
									bg="orange-primary"
									variant="primary"
									isLoading={joinMutation.isPending}
									disabled={joinMutation.isPending}
								>
									<span
										className="font-semibold text-xs text-white"
										onClick={handleJoin}
									>
										Join Now
									</span>
								</Button>
							)}
						</div>
					</div>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default GikiModal;
