import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import { useState } from "react";
import { useParams } from "react-router-dom";

const usePinHundler = (id: string, isPin: boolean) => {
  const { pinMessage, unpinMessage } = useApiRequests();
  const { id: chatId } = useParams();
  const [isPinned, setIsPinned] = useState(isPin);

  const hundlePin = useMutation({
    mutationFn: () => pinMessage({ communityId: chatId as string, messageId: id }),
    onSuccess: () => {
      setIsPinned(true);
    },
  });

  const hundleUnpin = useMutation({
    mutationFn: () => unpinMessage({ communityId: chatId as string, messageId: id }),
    onSuccess: () => {
      setIsPinned(false);
    },
  });

  return { hundlePin, isPinned, hundleUnpin };
};

export default usePinHundler;
