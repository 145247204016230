import SmallJoinButton from "../../components/buttons/SmallJoinButton";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isSelectedChat } from "../../store/atoms";
import { Giki } from "../../types";
import { useTranslation } from "react-i18next";

type Props = {
	giki: Giki;
};

const AllGikisCard = ({ giki }: Props) => {
	const { name, short_description, users_count, is_joined } = giki;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [, setIsSelected] = useRecoilState(isSelectedChat);

	return (
		<div
			className={`p-5 rounded bg-[#F7FAFC] flex flex-col gap-5 self-stretch h-full justify-between ${
				giki.is_joined === 1 && "cursor-pointer"
			}`}
			onClick={() => {
				if (giki.is_joined === 1) {
					setIsSelected(true);
					navigate(`/chat/${giki.id}`, { state: { ...giki } });
				}
			}}
		>
			<div className="flex flex-col gap-[10px]">
				<span className="text-xs text-blue-primary leading-[14.4px]">
					{users_count} {t("Members")}
				</span>
				<h3 className="line-clamp-4 text-sm text-blue-primary leading-[16.8px] font-bold">
					{name}
				</h3>
				<p className="text-xs text-blue-primary leading-[14.4px] line-clamp-4">
					{short_description || "No Description Available"}
				</p>
			</div>
			<SmallJoinButton data={giki} type={is_joined} />
		</div>
	);
};

const AllGikisCardSkeleton = () => {
	return (
		<div className="rounded animate-pulse p-5 bg-gray-50">
			<div className="flex flex-col w-full gap-[10px] py-2 px-4">
				<div className="w-12 h-[14px] rounded border-2 border-white bg-slate-300 " />
				<div className="flex flex-col gap-[2px]">
					<p className="text-blue-primary font-semibold h-[15.8px] bg-slate-300 rounded"></p>
					<p className="text-blue-primary font-semibold h-[15.8px] w-[50%] bg-slate-300 rounded"></p>
				</div>
				<div className="flex flex-col gap-[2px]">
					<p className="text-xs h-3 bg-slate-300 rounded"></p>
					<p className="text-xs h-3 bg-slate-300 w-[30%] rounded"></p>
				</div>
				<div className="w-24 h-3 rounded border-2 border-white bg-slate-300" />
			</div>
		</div>
	);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { AllGikisCard, AllGikisCardSkeleton };
