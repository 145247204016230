import { extendTheme } from "@chakra-ui/react";
import { fetchColorsFromAPI } from "../utility/fetchColorsFromAPI";
import buttonsVariants from "./variants/buttons";
import InputVariants from "./variants/inputs";
import LabelsVariants from "./variants/labels";
import SelectVariants from "./variants/selects";
import { tabsTheme } from "./variants/tabs";
import TextAreaVariants from "./variants/textarea";

export const setColorsInTheme = async () => {
	const colorData = await fetchColorsFromAPI();
	// Extend the theme with the fetched colors
	const theme = extendTheme({
		fonts: {
			heading: "Helvetica",
			body: "Helvetica",
		},
		colors: {
			...colorData,
		},
		components: {
			Button: {
				baseStyle: {
					_focus: { boxShadow: "none" },
					textTransform: "uppercase",
				},
				variants: {
					...buttonsVariants,
				},
			},
			Input: {
				variants: {
					...InputVariants,
				},
			},
			FormLabel: {
				variants: {
					...LabelsVariants,
				},
			},
			Select: {
				variants: {
					...SelectVariants,
				},
			},
			TextArea: {
				variants: {
					...TextAreaVariants,
				},
			},
			Tabs: tabsTheme,
		},
	});

	return theme;
};
