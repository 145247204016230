/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import JoinArrow from "../../components/icons/JoinArrow";
import { useApiRequests } from "../../configs/API/ApiRequests";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedChat } from "../../store/atoms";
import { Giki } from "../../types";
import { useTranslation } from "react-i18next";

interface JoinButtonProps {
	type?: number;
	data: Giki;
}

const SmallJoinButton: React.FunctionComponent<JoinButtonProps> = ({
	type,
	data,
}) => {
	const { onOpen } = useDisclosure();

	const { joinCommunity } = useApiRequests();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const joinMutation = useMutation({
		mutationFn: joinCommunity,
		onSuccess: async () => await queryClient.invalidateQueries(),
	});
	const [, setChat] = useRecoilState(selectedChat);
	const { t } = useTranslation();

	const handleJoin = () => {
		joinMutation.mutate(data.id);
	};

	useEffect(() => {
		if (joinMutation.isSuccess) {
			queryClient.invalidateQueries().finally(() => {
				navigate(`/chat/${data.id}`, { state: { ...data } });
			});
		}
	}, [joinMutation.isSuccess]);

	return (
		<React.Fragment>
			<div
				onClick={onOpen}
				className="flex items-center w-fit cursor-pointer select-none gap-1"
			>
				{type === 1 && (
					<>
						<span
							className="font-semibold text-orange-primary lg:text-xs text-lg"
							onClick={() => {
								setChat({
									id: data.id,
									image: data.image,
									name: data.name,
									users_count: data.users_count,
									type: data.type,
									cover_image: data.cover_image,
									short_description: data.short_description || "",
								});
								navigate(`/chat/${data.id}`, { state: { ...data } });
							}}
						>
							{t("Go to chat")}
						</span>
					</>
				)}
				{type === 0 && (
					<button
						onClick={!joinMutation.isPending ? handleJoin : () => null}
						className="flex items-center gap-[3px] text-xs leading-[14.4px] text-primary-blue"
					>
						<span className="font-semibold lg:text-xs text-sm">
							{t("Join Now")}
						</span>
						{joinMutation.isPending ? <Spinner size="xs" /> : <JoinArrow />}
					</button>
				)}
			</div>
		</React.Fragment>
	);
};

export default SmallJoinButton;
