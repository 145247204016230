import Card from "../../../../components/cards/AllGikisCard";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";

type AllCommunitiesResponse = {
	id: string;
	name: string;
	image: string;
	cover_image: null | string;
	short_description: null | string;
	type: number;
	users_count: number;
	request_to_joined: number;
	unread_messages: number;
	mute: number;
	is_joined: 0 | 1 | 2;
	last_message: {
		id: number;
		message: string;
		user_id: string;
		conversation_id: number;
		reply_to: null | string;
		content_type: string;
		attachment_id: null | string;
		sent_at: string;
		created_at: string;
		updated_at: string;
		laravel_through_key: string;
		message_date: string;
		message_time: string;
		page_number: number;
	};
}[];

const AllCommunities = () => {
	const { getAllCommunities } = useApiRequests();

	const [allCommunities, setAllCourses] = useState<AllCommunitiesResponse>([]);
	const [page, setPage] = useState(1);

	const { ref, inView } = useInView();

	const { data, isLoading, isSuccess } = useQuery({
		queryKey: ["all-gikis", page],
		queryFn: ({ queryKey }) => getAllCommunities(+queryKey[1]),
		select: (res) => res.data,
	});

	useEffect(() => {
		if (!isLoading && isSuccess) {
			setAllCourses((prev) => [...prev, ...data.data]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isSuccess]);

	useEffect(() => {
		inView && setPage((prev) => prev + 1);
	}, [inView]);

	const { t } = useTranslation();

	return (
		<>
			<SectionHeader>{t("gikis.allGikis")}</SectionHeader>
			<div className="grid gap-5 grid-cols-12">
				{/* // ! add loading cards bellow */}
				{isLoading && allCommunities.length === 0
					? [...Array(8)].map((a) => (
							<div className="col-span-12 sm:col-span-6 md:col-span-4" key={a}>
								<Card.AllGikisCardSkeleton />
							</div>
					  ))
					: allCommunities &&
					  allCommunities.length > 0 &&
					  allCommunities.map((gik, i: number) => {
							return (
								<div
									className="col-span-12 sm:col-span-6 md:col-span-4"
									key={i}
								>
									<Card.AllGikisCard
										giki={{
											cover_image: gik.cover_image,
											id: gik.id,
											image: gik.image,
											name: gik.name,
											request_to_joined: gik.request_to_joined,
											short_description: gik.short_description,
											type: gik.type,
											users_count: gik.users_count,
											is_joined: gik.is_joined,
										}}
									/>
								</div>
							);
					  })}
				{isLoading &&
					allCommunities.length > 0 &&
					[...Array(8)].map((a) => (
						<div className="col-span-12 sm:col-span-6 md:col-span-4" key={a}>
							<Card.AllGikisCardSkeleton />
						</div>
					))}
				{page < data?.last_page && !isLoading && <div ref={ref}>{""}</div>}
			</div>
		</>
	);
};

export default AllCommunities;
