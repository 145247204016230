import { Switch } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { FadeBox } from "../../../components/boxes/FadeBox";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import { selectedTab } from "../selectedTab";

export const Notifications = () => {
	const { getNotifications, setNotifications } = useApiRequests();
	const [, setselectedTap] = useRecoilState(selectedTab);
	const { data } = useQuery({
		queryKey: ["notifications"],
		queryFn: getNotifications,
	});
	const { mutate } = useMutation({ mutationFn: setNotifications });
	const { t } = useTranslation();

	return (
		<FadeBox className="lg:col-span-7 col-span-9 flex-col gap-[30px] bg-white px-3 py-[30px]">
			<p className="text-base font-semibold text-left text-[#323e53] mb-4">
				<button className="m-2 text-xs" onClick={() => setselectedTap("")}>
					<FaChevronLeft />
				</button>
				{t("Notifications")}
			</p>

			<div className="flex flex-col justify-start items-center left-0 top-8 gap-[30px] h-[99%] px-4 py-[30px] rounded-lg bg-[#f7fafc]">
				{data?.data?.map((notificaton: any, i: any) => (
					<>
						<div className="flex justify-between items-start self-stretch relative gap-3">
							<div className="flex flex-col justify-start items-start relative gap-2">
								<p className=" flex-shrink-0 sm:text-base text-sm font-semibold text-left text-[#323e53]">
									{notificaton.title}{" "}
								</p>
								<p className="self-stretch md:text-sm text-xs text-left text-[#323e53]">
									{notificaton.description}
								</p>
							</div>
							<Switch
								defaultChecked={notificaton.status === 1}
								onChange={(e) => {
									mutate({
										notification: notificaton.id,
										status: e.target.checked ? 1 : 0,
									});
								}}
							/>
						</div>
						<svg
							height={2}
							viewBox="0 0 829 2"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="self-stretch"
							preserveAspectRatio="none"
						>
							<path d="M829 1H0" stroke="#E2E8F0" />
						</svg>
					</>
				))}
			</div>
		</FadeBox>
	);
};
