import { atom } from "recoil";

type ChatInfoTypes = "info" | "media" | "live streaming" | "none";

const chatInfo = atom<ChatInfoTypes>({
  key: "chatInfoTypes",
  default: "none",
});

export default chatInfo;
