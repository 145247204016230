/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useRef } from "react";
import { motion } from "framer-motion";

export const FadeBox = ({
  children,
  className,
  id,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  id?: string;
  onClick?: () => void;
}) => {
  const boxRef = useRef(null);

  useEffect(() => {
    const boxElement = boxRef.current;
    (boxElement as any).style.opacity = 1;

    return () => {
      (boxElement as any).style.opacity = 0;
    };
  }, []);

  return (
    <motion.div
      onClick={onClick ? onClick : () => null}
      ref={boxRef}
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} // Exit animation
      transition={{ duration: 0.4 }}
      id={id ? id : ""}
    >
      {children}
    </motion.div>
  );
};
