import { useRecoilState, useRecoilValue } from "recoil";
import {
	isSelectedChat,
	loadingChat,
	selectedChat,
	sidebarState,
} from "../../store/atoms";
import { Chat } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { Avatar } from "@chakra-ui/react";
import { FadeBox } from "./FadeBox";
import MessageBoxLastMessage from "./MessageBoxLastMessage";
import { Link } from "react-router-dom";
interface MassagesBoxProps extends Chat {
	isPinned?: boolean;
	isMuted?: boolean;
	unreadMassages?: string;
	isLocked?: boolean;
}

const MassagesBox: React.FunctionComponent<MassagesBoxProps> = ({
	image,
	name,
	users_count,
	unreadMassages,
	id,
	isLocked,
	cover_image,
	short_description,
	last_message,
	type,
}) => {
	const [chat, setChat] = useRecoilState(selectedChat);
	const [, isSelected] = useRecoilState(isSelectedChat);
	const [sideBarOpen] = useRecoilState(sidebarState);
	const queryClient = useQueryClient();
	const isLoadingChat = useRecoilValue(loadingChat);

	return (
		<Link
			onClick={() => {
				setChat({
					id,
					image,
					name,
					users_count,
					type,
					cover_image,
					short_description,
				});
				isSelected(true);
				queryClient.invalidateQueries({
					queryKey: ["CommunityMembers", chat.id],
				});
			}}
			to={`${id}`}
			className={`${chat?.id === id ? "bg-gray-20" : "bg-white hover:bg-gray-50"
				} p-[6px] select-none cursor-pointer flex  rounded ${isLoadingChat ? "pointer-events-none opacity-70" : ""
				} ${!sideBarOpen ? "items-center justify-center" : "justify-between"}`}
			aria-disabled={isLoadingChat}
		>
			<div className={`flex ${sideBarOpen ? "gap-[10px]" : "justify-center"} items-center`}>
				<div className="flex items-center justify-center h-[42px] w-[42px]">
					<Avatar
						className={`aspect-square h-[42px] w-[42px] rounded-md object-cover border-[2px] ${unreadMassages ? "border-orange-primary" : "border-transparent"
							}`}
						rounded={4}
						src={`${process.env.REACT_APP_MEDIA_URL}${image}`}
						name={name}
					/>
				</div>
				{sideBarOpen && (
					<FadeBox>
						<div
							className={`flex flex-col pr-2 mr-auto justify-between gap-1 self-stretch !h-full`}
						>
							<p className="font-semibold text-sm flex items-center gap-2 text-blue-primary">
								<span className="line-clamp-1">{name} </span>
								{isLocked && (
									<svg
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5 6.5V7.5M2 9.5H8C8.55228 9.5 9 9.05228 9 8.5V5.5C9 4.94772 8.55228 4.5 8 4.5H2C1.44772 4.5 1 4.94772 1 5.5V8.5C1 9.05228 1.44772 9.5 2 9.5ZM7 4.5V2.5C7 1.39543 6.10457 0.5 5 0.5C3.89543 0.5 3 1.39543 3 2.5V4.5H7Z"
											stroke="#4A5568"
											strokeLinecap="round"
										/>
									</svg>
								)}
							</p>

							{last_message ? (
								last_message.created_at && (
									<MessageBoxLastMessage date={last_message.created_at} />
								)
							) : (
								<div className="invisible">0</div>
							)}
						</div>
					</FadeBox>
				)}
			</div>
		</Link>
	);
};

export default MassagesBox;

// <Modal isOpen={isOpen} onClose={onClose}>
//   <ModalOverlay />
//   <ModalContent>
//     <ModalHeader> Your are in a meeting. Do you want to leave it?</ModalHeader>
//     <ModalBody>
//       <Box className='flex justify-end gap-2 w-full'>
//         <Button
//           colorScheme='blue'
//           mr={3}
//           onClick={() => {
//             setChat({ id, image, name, users_count });
//             isSelected(true);
//             // clearNewConference && clearNewConference();
//             // clearNewMeeting && clearNewMeeting();
//             queryClient.invalidateQueries(["CommunityMembers", chat.id]);
//             setMeeting(null);
//             onClose();
//           }}
//         >
//           Leave
//         </Button>
//         <Button colorScheme='red' mr={3} onClick={onClose}>
//           Close
//         </Button>
//       </Box>
//     </ModalBody>
//   </ModalContent>
// </Modal>
