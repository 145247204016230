import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Giki } from "../types";

type Props = {
	id: string;
} & (
	| {
			navigateOnSuccess: false;
	  }
	| {
			navigateOnSuccess: true;
			data: Giki;
	  }
);

const useJoinCommunity = (props: Props) => {
	const { id, navigateOnSuccess } = props;
	const { joinCommunity, cancelOrExitCommunity } = useApiRequests();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const joinMutation = useMutation({ mutationFn: joinCommunity });
	const cancelMutation = useMutation({ mutationFn: cancelOrExitCommunity });
	const toast = useToast();

	const handleJoin = async () => {
		joinMutation
			.mutateAsync(id)
			.then(() => {
				queryClient.invalidateQueries({
					queryKey: ["gikis", "all-conference"],
				});
			})
			.catch((err) => {
				if (err.response.data.error) {
					toast({
						title: "Something Went Wrong",
						description: err.response.data.error,
						status: "error",
					});
				} else {
					toast({
						title: "Something Went Wrong",
						description: "",
						status: "error",
					});
				}
			});
	};

	const handleCancel = () => {
		cancelMutation.mutate(id);
		queryClient.invalidateQueries({ queryKey: ["gikis", "all-conference"] });
	};

	useEffect(() => {
		if (joinMutation.isSuccess && navigateOnSuccess) {
			navigate(`/chat/${props.id}`, { state: { ...props.data } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joinMutation.isSuccess]);

	return { handleCancel, handleJoin, joinMutation };
};

export default useJoinCommunity;
