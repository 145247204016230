import {
	Avatar,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface WelcomeGikiModalProps {
	onClose: () => void;
	id: string;
}

const WelcomeGikiModal: React.FunctionComponent<WelcomeGikiModalProps> = ({
	onClose,
	id,
}) => {
	const { getCommunityDetails } = useApiRequests();
	const { t } = useTranslation();

	const {
		data,
		isPending: isLoading,
		mutateAsync,
		error,
	} = useMutation({
		mutationKey: ["gikiDetails", id],
		mutationFn: () => getCommunityDetails(id),
	});

	useEffect(() => {
		if (id !== "") mutateAsync();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Modal
			isCentered
			size="2xl"
			isOpen={id !== "" && !error ? true : false}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody px="50px">
					<div className="flex flex-col justify-center items-center gap-[16px] pb-16 pt-8">
						<Avatar
							size="2xl"
							borderRadius="8px"
							name={data?.data.data.name}
							src={`${process.env.REACT_APP_MEDIA_URL}${data?.data.data.image}`}
						/>
						{isLoading ? (
							<div className="animate-pulse flex space-x-4 items-center w-full">
								<div className="flex-1 space-y-6 py-1">
									<div className="h-2 bg-slate-700 rounded"></div>
								</div>
							</div>
						) : (
							<p className="text-blue-primary text-xl text-center">
								{t("Welcome to")}{" "}
								<span className="font-semibold">{data?.data.data?.name}</span>{" "}
							</p>
						)}

						{isLoading ? (
							<>
								<div className="animate-pulse flex space-x-4 items-center w-full">
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
								</div>{" "}
								<div className="animate-pulse flex space-x-4 items-center w-full">
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
									<div className="flex-1 space-y-6 py-1">
										<div className="h-2 bg-slate-700 rounded"></div>
									</div>
								</div>
							</>
						) : (
							<p className="text-blue-primary text-center px-10">
								{data?.data.data.description}
							</p>
						)}

						<Button
							onClick={onClose}
							w="50%"
							bgColor="orange-primary"
							variant="primary"
						>
							{t("Start Giking!")}
						</Button>
					</div>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default WelcomeGikiModal;
