/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useApiRequests } from "../../../../../../configs/API/ApiRequests";
import PinnedButton, { FileType } from "./PinnedButton";
import PinnedSkeleton from "./PinnedSkeleton";
import { useParams } from "react-router-dom";

type PinnedMessage = {
	message: string;
	message_id: number;
	pin_date: string;
	page_number: number;
	owner: {
		id: string;
		name: string;
		photo: string;
	};
	user: {
		id: string;
		name: string;
		photo: string;
	};
	attachment: {
		id: number;
		attachmentable_id: number;
		file: string;
		type: string;
		created_at: string;
		name: string;
		size: number;
		url: string;
	} | null;
};

const PinnedPanel = () => {
	const { getPinnedMessages } = useApiRequests();
	const { id } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ["media", id],
		queryFn: () => getPinnedMessages(id!),
		select: (data: any) => data?.data,
	});

	return (
		<div className="flex flex-col gap-5 px-5">
			{isLoading ? (
				<>
					<PinnedSkeleton />
					<PinnedSkeleton />
					<PinnedSkeleton />
					<PinnedSkeleton />
				</>
			) : (
				<>
					{data &&
						data.length > 0 &&
						data.map((item: PinnedMessage) => (
							<PinnedButton
								key={item.message_id}
								messageId={item.message_id}
								name={item.message}
								type={
									item?.attachment
										? (item?.attachment.type as FileType)
										: "Link"
								}
								size={item?.attachment ? item.attachment.size : undefined}
								page={item.page_number}
							/>
						))}
				</>
			)}
		</div>
	);
};

export default PinnedPanel;
