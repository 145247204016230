/* eslint-disable jsx-a11y/alt-text */
import { IconButton, List, ListItem } from '@chakra-ui/react'
import React from 'react'

interface IconButtonListProps {
  icon: string
  ariaLabel?: string
  items: any
  title: string
  isHTML?: boolean
  isAdmins?: boolean
}

const IconButtonList: React.FunctionComponent<IconButtonListProps> = ({
  icon,
  items,
  ariaLabel,
  title,
  isHTML,
  isAdmins,
}) => {
  return (
    <React.Fragment>
      <IconButton
        className='py-2'
        aria-label={ariaLabel ? ariaLabel : ''}
        bg='blue-primary'
        icon={<img src={icon} />}
      />
      <div>
        <p className='text-sm'>{title}</p>
        {!isAdmins ? (
          <>
            {isHTML ? (
              <div dangerouslySetInnerHTML={{ __html: items[0] }} />
            ) : (
              <>
                {items.length === 1 ? (
                  items.map((item: any, i: number) => (
                    <p className='px-1' key={`item-${i}`}>
                      {item}
                    </p>
                  ))
                ) : (
                  <List listStyleType='disc' className='relative  left-4'>
                    {items.map((item: any, i: number) => (
                      <ListItem className='px-1' key={`item-${i}`}>
                        {item}
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
          </>
        ) : (
          <List listStyleType='none' className='relative  left-4'>
            {items[0].map((item: any, i: any) => (
              <ListItem textDecoration="none" className='px-1' key={`item-${i}`}>
                {item}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </React.Fragment>
  )
}

export default IconButtonList
