import { Button } from "@chakra-ui/react";
import { useLogin } from "../../../hooks/useLogin";
import { Link } from "react-router-dom";
import { IoExitOutline } from "react-icons/io5";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../../store/atoms";
import { useTranslation } from "react-i18next";

export const SectionsMenu = ({ selectedTap, setselectedTap }: any) => {
	const [sideBarOpen] = useRecoilState(sidebarState);
	const { t } = useTranslation();

	const { useLogout } = useLogin();
	return (
		<div className="flex flex-col h-full gap-3 pb-5">
			<Button
				textTransform="capitalize"
				justifyContent={"start"}
				onClick={() => setselectedTap("info")}
				className={`flex items-center ${sideBarOpen ? "gap-3" : "gap-0"}`}
				fontSize={"14px"}
				borderRadius={"8px"}
				background={selectedTap === "info" ? "#E2E8F0" : "white"}
				_hover={{ bg: "gray-20" }}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
						stroke="#718096"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
						stroke="#718096"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<span
					className={`${
						sideBarOpen ? "block" : "hidden"
					} overflow-hidden line-clamp-1`}
				>
					{t("Profile info")}
				</span>
			</Button>
			<Button
				background={selectedTap === "changePassword" ? "#E2E8F0" : "white"}
				onClick={() => setselectedTap("changePassword")}
				textTransform="capitalize"
				justifyContent={"start"}
				fontSize={"14px"}
				borderRadius={"8px"}
				className={`flex items-center ${sideBarOpen ? "gap-3" : "gap-0"}`}
				_hover={{ bg: "gray-20" }}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.9999 11C11.9999 14.5172 10.9911 17.7988 9.24707 20.5712M5.80688 18.5304C5.82459 18.5005 5.84273 18.4709 5.8613 18.4413C7.2158 16.2881 7.99991 13.7418 7.99991 11C7.99991 8.79086 9.79077 7 11.9999 7C14.209 7 15.9999 8.79086 15.9999 11C15.9999 12.017 15.9307 13.0186 15.7966 14M13.6792 20.8436C14.2909 19.6226 14.7924 18.3369 15.1707 17M19.0097 18.132C19.6547 15.8657 20 13.4732 20 11C20 6.58172 16.4183 3 12 3C10.5429 3 9.17669 3.38958 8 4.07026M3 15.3641C3.64066 14.0454 4 12.5646 4 11C4 9.54285 4.38958 8.17669 5.07026 7"
						stroke="#718096"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<span
					className={`${
						sideBarOpen ? "block" : "hidden"
					} overflow-hidden line-clamp-1`}
				>
					{t("Change password")}
				</span>
			</Button>
			{/* <Button
				textTransform="capitalize"
				background={selectedTap === "notifications" ? "#E2E8F0" : "white"}
				onClick={() => setselectedTap("notifications")}
				justifyContent={"start"}
				fontSize={"14px"}
				borderRadius={"8px"}
				className={`flex items-center ${sideBarOpen ? "gap-3" : "gap-0"}`}
				_hover={{ bg: "gray-20" }}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 17 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.6776 12.1327C15.2176 11.6027 14.9776 11.0027 14.8976 10.3027C14.8276 9.62273 14.7176 8.93273 14.6176 8.25273C14.5076 7.53273 14.4476 6.80273 14.2276 6.11273C13.6876 4.38273 12.4876 3.24274 10.8676 2.51274C10.6976 2.43274 10.5976 2.35273 10.5476 2.15273C10.4576 1.71273 10.2376 1.32273 9.92759 0.992731C9.13759 0.162731 8.17759 -0.137276 7.07759 0.172724C5.97759 0.492724 5.32759 1.25274 5.08759 2.37274C5.06759 2.46274 4.98759 2.56273 4.90759 2.60273C4.19759 2.95273 3.55759 3.40273 3.23759 3.74273C2.09759 4.94273 1.57759 6.08273 1.46759 7.41273C1.37759 8.53273 1.25759 9.65273 1.17759 10.7727C1.12759 11.4327 0.897587 12.0027 0.487587 12.5227C-0.0924128 13.2727 -0.162413 14.2627 0.317587 15.0727C0.877587 16.0127 1.73759 16.4427 2.80759 16.4127C6.38759 16.3327 9.97759 16.2327 13.5576 16.1427C14.0176 16.1327 14.4476 16.0227 14.8476 15.8127C16.2776 15.0627 16.6876 13.2927 15.6576 12.1127L15.6776 12.1327ZM14.2576 14.5827C14.0376 14.7027 13.7676 14.7727 13.5076 14.7827C9.93759 14.8827 6.36759 14.9727 2.79759 15.0627C2.30759 15.0727 1.87759 14.9227 1.57759 14.5127C1.32759 14.1627 1.31759 13.7927 1.53759 13.4327C1.69759 13.1927 1.87759 12.9627 2.01759 12.7127C2.32759 12.1727 2.47759 11.5927 2.52759 10.9727C2.61759 9.83273 2.72759 8.70273 2.81759 7.57273C2.89759 6.55273 3.29759 5.68273 4.11759 4.82273C4.50759 4.40273 5.10759 4.00274 5.80759 3.73274C6.22759 3.57274 6.42759 3.31273 6.41759 2.85273C6.41759 2.09273 7.01759 1.48272 7.76759 1.45272C8.51759 1.42272 9.18759 1.98273 9.22759 2.74273C9.25759 3.25273 9.49759 3.49273 9.95759 3.66273C11.4876 4.22273 12.5976 5.23274 12.9876 6.87274C13.1676 7.63274 13.2276 8.42273 13.3376 9.19273C13.4276 9.78273 13.4976 10.3727 13.5876 10.9627C13.7076 11.7127 14.0376 12.3627 14.5276 12.9427C15.0276 13.5227 14.9176 14.2427 14.2376 14.5927L14.2576 14.5827Z"
						fill="#718096"
					/>
					<path
						d="M11.2575 16.6827C10.8975 16.5427 10.5275 16.7327 10.3475 17.1327C10.2375 17.3727 10.1075 17.6227 9.93749 17.8127C8.81749 19.0427 6.74749 18.7227 6.08749 17.2427C6.01749 17.0827 5.85749 16.9427 5.70749 16.8527C5.46749 16.7127 5.16749 16.7827 4.96749 16.9827C4.74749 17.2027 4.69749 17.4727 4.82749 17.7827C5.80749 20.1427 9.10749 20.7027 10.8975 18.8127C10.9175 18.8227 10.9375 18.8327 10.9575 18.8427C11.1875 18.4427 11.4375 18.0527 11.6275 17.6327C11.8175 17.2327 11.6275 16.8227 11.2575 16.6827Z"
						fill="#718096"
					/>
				</svg>
				<span
					className={`${
						sideBarOpen ? "block" : "hidden"
					} overflow-hidden line-clamp-1`}
				>
					{t("Notifications")}
				</span>
			</Button> */}
			<Button
				textTransform="capitalize"
				justifyContent={"start"}
				borderRadius={"8px"}
				fontSize={"14px"}
				className={`flex items-center ${sideBarOpen ? "gap-3" : "gap-0"}`}
				background={selectedTap === "help" ? "#E2E8F0" : "white"}
				onClick={() => setselectedTap("help")}
				_hover={{ bg: "gray-20" }}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9 12L11 14L15 10M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z"
						stroke="#718096"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<span
					className={`${
						sideBarOpen ? "block" : "hidden"
					} overflow-hidden line-clamp-1`}
				>
					{t("Help Center")}
				</span>
			</Button>

			<Button
				textTransform="capitalize"
				justifyContent={"start"}
				borderRadius={"8px"}
				fontSize={"14px"}
				className={`flex items-center ${sideBarOpen ? "gap-3" : "gap-0"}`}
				background={"white"}
				// eslint-disable-next-line react-hooks/rules-of-hooks
				onClick={() => useLogout()}
				_hover={{ bg: "gray-20" }}
			>
				<IoExitOutline color="#718096" fontSize="24px" />
				<span
					className={`${
						sideBarOpen ? "block" : "hidden"
					} overflow-hidden line-clamp-1`}
				>
					{t("Logout")}
				</span>
			</Button>

			<p
				className={`flex-grow-0 mt-auto flex-shrink-0 text-xs text-left p-4 text-[#323e53] ${
					sideBarOpen ? "block" : "hidden"
				}`}
			>
				<Link
					to="https://terapiainfiltrativapro.it/xgate-policy"
					target={"_blank"}
				>
					{t("Privacy Policy Xgate")}
				</Link>{" "}
				|{" "}
				<Link
					to="https://terapiainfiltrativapro.it/privacy-policy"
					target={"_blank"}
				>
					{t("Privacy Policy")}
				</Link>{" "}
				|{" "}
				<Link
					to="https://terapiainfiltrativapro.it/termini-uso"
					target={"_blank"}
				>
					{t("Terms of use")}
				</Link>
			</p>
		</div>
	);
};
