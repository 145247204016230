import SidebarIcon from "../../components/icons/SidebarIcon";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../store/atoms";

interface LeftMenuProps {
	children: React.ReactNode;
	title: string;
	withOverflow?: boolean;
}

const LeftMenu = ({ children, title, withOverflow = true }: LeftMenuProps) => {
	const [openSideBar, setOpenSideBar] = useRecoilState(sidebarState);

	return (
		<div className="flex-col sticky top-0 flex-1 h-[calc(100dvh-75px-65px)] md:!h-dvh flex w-full border-r-2 border-r-[#F7FAFC] bg-white">
			<div
				className={`p-[25px] ${
					openSideBar ? "px-5" : "px-[34px]"
				} flex justify-between gap-1 items-center border-b-2 border-b-[#F7FAFC]`}
			>
				<p
					className={`text-blue-primary font-semibold text-base leading-[19.2px] transition-opacity duration-300 line-clamp-1 ${
						openSideBar ? "opacity-100" : "opacity-0 hidden"
					}`}
				>
					{title}
				</p>
				<button
					onClick={() => setOpenSideBar((prev) => !prev)}
					className="!w-[30px] !h-[30px] rounded border-[1px] border-[#CBD5E0] lg:flex items-center justify-center hidden "
				>
					<SidebarIcon />
				</button>
			</div>
			<div className={`p-5 flex-1 ${withOverflow && "overflow-y-auto"}`}>
				{children}
			</div>
		</div>
	);
};

export default LeftMenu;
