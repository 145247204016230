import { atom } from "recoil";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  firstPage: number;
  currentNext: number;
  currentPrev: number;
}

const pagination = atom<Pagination>({
  default: {
    currentPage: 1,
    totalPages: 0,
    firstPage: 1,
    currentNext: 0,
    currentPrev: 0,
  },
  key: "pagination-app",
});

export default pagination;
