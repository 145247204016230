/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import AllDocuments from "./AllDocuments";
import AllLinks from "./AllLinks";
import AllMedia from "./AllMedia";
import { useTranslation } from "react-i18next";

type Media = "media" | "documents" | "links";

const MediaGroup = () => {
	const { getConversationMedia } = useApiRequests();
	const { id } = useParams();
	const { t } = useTranslation();

	const { ref, inView } = useInView();

	const [selectedList, setSelectedList] = useState<Media>("media");
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [mediaList, setMediaList] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);

	const { data, isFetching, isSuccess } = useQuery({
		queryKey: ["media", selectedList, page],
		queryFn: () =>
			getConversationMedia(
				id as string,
				selectedList ? selectedList : "media",
				page ? page : 1
			),
	});

	useEffect(() => {
		if (!isFetching && isSuccess) {
			setLoading(false);
			setMediaList((prev) => [...prev, ...(data?.data.data as [])]);
		}
	}, [isFetching, isSuccess]);

	useEffect(() => {
		if (page < data?.data?.last_page) {
			inView && setPage((prev) => prev + 1);
		}
	}, [inView]);

	const handleSelectList = (select: Media) => {
		setLoading(true);
		setMediaList([]);
		setPage(1);
		setSelectedList(select);
	};

	const observer = ((data?.data?.last_page && page < data?.data?.last_page) ||
		!data ||
		!isFetching) && <div ref={ref}> </div>;

	return (
		<Tabs variant={"gikiThemed"} className="h-full !flex flex-col">
			<TabList px="16px">
				<Tab onClick={() => handleSelectList("media")}>{t("Media")}</Tab>
				<Tab onClick={() => handleSelectList("documents")}>{t("Docs")}</Tab>
				<Tab onClick={() => handleSelectList("links")}>{t("Link")}</Tab>
			</TabList>
			<TabPanels className="h-full flex-1 overflow-y-auto">
				<TabPanel className="h-full overflow-y-auto">
					<AllMedia isLoading={isFetching || loading} data={mediaList} />
					{observer}
				</TabPanel>
				<TabPanel className="h-full overflow-y-auto">
					<AllDocuments isLoading={isFetching || loading} data={mediaList} />
					{observer}
				</TabPanel>
				<TabPanel className="h-full overflow-y-auto">
					<AllLinks isLoading={isFetching || loading} data={mediaList} />
					{observer}
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default MediaGroup;
