import { Button } from "@chakra-ui/react";
import FooterLinks from "../../../components/boxes/FooterLinks";
import PolicyForm from "../../../components/forms/PolicyForm";
import RegsiterForm from "../../../components/forms/RegsiterForm";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { registerStep } from "../../../store/atoms";

import AuthenticationLayout from "../AuthenticationLayout";
import { useTranslation } from "react-i18next";

const Register = () => {
	const [registerData] = useRecoilState(registerStep);
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<AuthenticationLayout>
			<div className="col-span-2 flex flex-col gap-[10px] text-left lg:max-w-3xl mx-auto">
				{(() => {
					switch (registerData.step) {
						case 1:
							return (
								<React.Fragment>
									<h1 className="text-3xl font-bold text-orange-primary">
										{t("register.title")}
									</h1>
									<p className="text-blue-primary font-semibold">
										{t("register.description")}
									</p>
									<RegsiterForm />
								</React.Fragment>
							);
						case 2:
							return (
								<React.Fragment>
									<h1 className="text-3xl font-bold text-orange-primary">
										{t("register.privacy_policy")}
									</h1>
									<PolicyForm />
								</React.Fragment>
							);
						case 3:
							return (
								<React.Fragment>
									<h1 className="text-3xl font-bold text-orange-primary">
										{t("register.complete_registration")}
									</h1>
									<div className="flex flex-col gap-7 pt-12">
										{/* <p className="text-blue-primary">
											Gentile{" "}
											<span className="font-semibold">Alice Lutgate,</span>
										</p> */}
										<p className="text-blue-primary">
											{t("register.account_activation_note")}
										</p>
										<p>{t("register.account_activation_note")}</p>
									</div>
									<Button
										onClick={() => {
											navigate("../login");
										}}
										w="50%"
										variant="primary"
										mx="auto"
										mt="36px"
									>
										{t("register.go_login")}
									</Button>
								</React.Fragment>
							);
					}
				})()}
			</div>

			<FooterLinks />
		</AuthenticationLayout>
	);
};

export default Register;
