import React from "react";

const Image = ({ className }: { className: string }) => {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 13.6667L5.58579 9.08096C6.36683 8.29991 7.63316 8.29991 8.41421 9.08096L13 13.6667M11 11.6667L12.5858 10.081C13.3668 9.29991 14.6332 9.29991 15.4142 10.081L17 11.6667M11 5.66675H11.01M3 17.6667H15C16.1046 17.6667 17 16.7713 17 15.6667V3.66675C17 2.56218 16.1046 1.66675 15 1.66675H3C1.89543 1.66675 1 2.56218 1 3.66675V15.6667C1 16.7713 1.89543 17.6667 3 17.6667Z'
        className={className}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Image;
