const PinnedSkeleton = () => {
  return (
    <div className='flex items-center gap-[10px] shadow-sm transition-all duration-300'>
      <p className='w-12 h-12 flex items-center justify-center rounded bg-gray-50 animate-pulse bg-slate-300 duration-300' />
      <div className='flex flex-col justify-between gap-[5px] text-blue-primary flex-1'>
        <p className='animate-pulse bg-slate-300 h-[24px] w-[100px] duration-300' />
        <div className='flex items-center gap-[10px] text-xs'>
          <p className='h-[14.4px] w-5 animate-pulse bg-slate-300 duration-300' />
          <p className='h-[14.4px] w-5 animate-pulse bg-slate-300 duration-300' />
        </div>
      </div>
    </div>
  );
};

export default PinnedSkeleton;
