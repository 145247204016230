import React from "react";

const Link = ({ className }: { className: string }) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.8284 8.83832C10.2663 7.27622 7.73367 7.27622 6.17157 8.83832L2.17157 12.8383C0.609476 14.4004 0.609476 16.9331 2.17157 18.4952C3.73367 20.0573 6.26633 20.0573 7.82843 18.4952L8.92999 17.3936M8.17157 12.4952C9.73367 14.0573 12.2663 14.0573 13.8284 12.4952L17.8284 8.49517C19.3905 6.93308 19.3905 4.40042 17.8284 2.83832C16.2663 1.27622 13.7337 1.27622 12.1716 2.83832L11.072 3.93793'
        className={className}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Link;
