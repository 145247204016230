import { useRecoilState } from "recoil";
import {
	currentOnlineUsersAtom,
	isSelectedChat,
	selectedChat,
} from "../../../../store/atoms";
import React, { useEffect, useRef } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import ChatInfo from "../modal/ChatInfo";
import MediaInfo from "../modal/MediaInfo";
import chatInfo from "../modal/store";
import { Avatar, Tooltip } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ConferencesControl from "../modal/ConferenceControl";
interface ChatHeaderProps {
	// onOpen: () => void;
	goIntoMassage: (page: string, communityId?: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	commuintyInfo: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		string,
		unknown
	>;
	// isMeetingComing: boolean;
}

const ChatHeader: React.FunctionComponent<ChatHeaderProps> = ({
	commuintyInfo,
}) => {
	const { id } = useParams();

	const [chat, setSelectedChat] = useRecoilState(selectedChat);
	const [, isSelected] = useRecoilState(isSelectedChat);
	const [, setSelected] = useRecoilState(chatInfo);
	const [communityOnline] = useRecoilState(currentOnlineUsersAtom);
	const { t } = useTranslation();

	const isMountedRef = useRef(false);

	useEffect(() => {
		if (!isMountedRef.current) {
			isMountedRef.current = true;
			commuintyInfo.mutate(id as string);
		} else {
			// Effect code to run on subsequent renders
		}

		return () => {
			setSelected("none");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!chat) return <></>;

	return (
		<div className="flex items-center justify-between p-4 border-b-2 border-b-[#F7FAFC]">
			<div className="flex items-center gap-[10px]">
			<Tooltip label={t("Back")}>
				<Link
					to={".."}
					onClick={() => {
						isSelected(false);
						setSelectedChat({ ...chat, id: "" });
					}}
				>
					
					<FaChevronLeft className="text-gray-600" />
					
				</Link>
				</Tooltip>
				<Avatar
					className="rounded !w-10 md:!w-12 !h-10 md:!h-12"
					rounded={4}
					src={`${process.env.REACT_APP_MEDIA_URL}${chat.image}`}
					name={chat.name}
				/>
				<div className="flex flex-col gap-[2px]">
					<h3 className="text-xs font-bold leading-[14.4px] text-blue-primary line-clamp-1">
						{chat.name ? (
							`${chat.name}`
						) : (
							<div className="h-[15px] w-28 animate-pulse bg-slate-300 rounded-sm"></div>
						)}
					</h3>
					<div className="flex items-center gap-[10px] text-[10px] leading-[15px]">
						<p className="bg-blue-primary text-white rounded py-[1px] px-2 ">
							{communityOnline} {t("online")}
						</p>
						<p className="text-[#718096] ">
							{chat.users_count} {t("Members")}
						</p>
					</div>
				</div>
			</div>
			<div className="flex items-center gap-5">
				<ChatInfo />
				<MediaInfo />
        <ConferencesControl />
			</div>
		</div>
	);
};

export const MemoizedChatHeader = React.memo(ChatHeader);
