import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../../store/atoms";
import { nearestConferenceAtom } from "../../../../store/NearesetConference";
import { MemoizedMeetBox } from "./MeetBox";

interface MeetingBoxSwitcherProps {
	commuintyInfo: any;
	showMeetBox: boolean;
	newMeeting: any;
}

const MeetingBoxSwitcher: React.FunctionComponent<MeetingBoxSwitcherProps> = ({
	commuintyInfo,
	newMeeting,
}) => {
	const userData = useRecoilValue(userDataAtom);
	const nearestConference = useRecoilValue(nearestConferenceAtom);

	// console.log(`%c${JSON.stringify(nearestConference)}`, "color: violet;");
	// console.log(`%c${JSON.stringify(newMeeting)}`, "color: blue;");

	const isAdmin =
		commuintyInfo.data?.data.data.members.filter(
			(member: any) =>
				member.is_admin === 1 &&
				member.id === userData?.accessToken.tokenable_id
		).length > 0;

	return (
		<div
			className={
				(newMeeting || nearestConference) &&
				"p-5 border-b-[1px] border-b-[#edf2f7]"
			}
		>
			{newMeeting && nearestConference ? (
				<MemoizedMeetBox
					isEnrolled={nearestConference.is_enrolled}
					data={nearestConference}
					is_live={nearestConference.on_air}
					isAdmin={isAdmin}
				/>
			) : (
				nearestConference && (
					<MemoizedMeetBox
						isEnrolled={nearestConference.is_enrolled}
						data={nearestConference}
						is_live={nearestConference.on_air}
						isAdmin={isAdmin}
					/>
				)
			)}
		</div>
	);
};

export default MeetingBoxSwitcher;
