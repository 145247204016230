/* eslint-disable react-hooks/rules-of-hooks */
import { Spinner, useMediaQuery } from "@chakra-ui/react";
// import useCreateICS from "../../hooks/useCreateICS";
import useJoinCommunity from "../../hooks/useJoinCommunity";
import { ConferenceData } from "../../pages/gikis/panels/conference.types";
import { useState } from "react";
import BannerCard from "./BannerCard";
import CountDownTimer from "./CountDownTimer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConferenceBanner = ({
	description,
	label_start_date,
	on_air,
	start_date,
	title,
	is_joined,
	community,
}: ConferenceData & {
	community: {
		id: string;
		name: string;
		image: string;
		short_description: string;
		users_count: number;
	};
}) => {
	const [isJoined, setIsJoined] = useState(is_joined);
	const [isMdScreen] = useMediaQuery(["(min-width: 768px)"]);
	// const toast = useToast();
	const { t } = useTranslation();

	const { handleJoin, joinMutation } = useJoinCommunity({
		id: community.id,
		navigateOnSuccess: false,
	});

	// const [loading, setLoading] = useState(false);

	// const handleIcs = async () => {
	// 	setLoading(true);

	// 	await useCreateICS({
	// 		description,
	// 		title,
	// 		startDate: start_date,
	// 		url: `${window.location.protocol}//${window.location.host}`,
	// 	})
	// 		.then(() => {
	// 			toast({
	// 				title: "file Downloaded Successfully",
	// 				description: "Please add ics file to your calender",
	// 				isClosable: true,
	// 			});
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 			toast({
	// 				title: "something went wrong",
	// 				status: "error",
	// 				isClosable: true,
	// 			});
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };

	const validBtn = () => {
		if (isJoined) {
			if (on_air) {
				return (
					<Link
						to={`/chat/${community.id}`}
						className={`${
							on_air ? "bg-orange-primary" : "bg-blue-primary"
						} rounded px-[30px] py-[10px] text-xs leading-[14.4px] flex items-center justify-center`}
					>
						{t("Join Now")}
					</Link>
				);
			} else {
				return (
					<Link
						className={`bg-blue-primary disabled:bg-blue-primary-50 rounded py-[10px] text-xs leading-[14.4px] flex items-center justify-center !w-[131px] gap-2`}
						// disabled={true}
						to={`/chat/${community.id}`}
					>
						{t("Go to chat")}
					</Link>
				);
			}
		} else {
			return (
				<button
					className={`${
						on_air ? "bg-orange-primary" : "bg-blue-primary"
					} rounded px-[30px] py-[10px] text-xs leading-[14.4px] flex items-center justify-center`}
					onClick={() =>
						handleJoin().then(() => {
							setIsJoined(true);
						})
					}
				>
					{joinMutation.isPending ? <Spinner size={"xs"} /> : "Join Giki"}
				</button>
			);
		}
	};

	return (
		<BannerCard
			className={`${
				on_air ? "bg-blue-primary" : "bg-orange-primary"
			} bg-blue-primary text-white self-stretch h-full`}
		>
			<div
				className={`flex gap-5 flex-col md:flex-row md:items-center ${
					!on_air && "justify-between"
				} h-full`}
			>
				{isMdScreen &&
					(on_air ? (
						<button className="bg-[#F80000] text-white text-xs leading-[14.4px] rounded px-[6.26px] py-[5px] self-start md:self-center">
							{t("ON AIR")}
						</button>
					) : (
						<CountDownTimer time={start_date} />
					))}
				<div
					className={`flex flex-col flex-1 ${
						on_air && "flex-1"
					}  self-start md:self-center w-full`}
				>
					<div className="flex justify-between gap-5">
						<span className="text-[10px] leading-[15px] line-clamp-1">
							{title}
						</span>
						{!on_air && (
							<span className="text-[10px] leading-[15px] line-clamp-1">
								{label_start_date}
							</span>
						)}
					</div>
					<span className="text-[10px] leading-[15px] line-clamp-1">
						{t("at")} <span className="font-bold">{community.name}</span>
					</span>
					<p className="text-sm leading-[16.8px] font-bold line-clamp-1">
						{description}
					</p>
				</div>

				<div className="flex justify-between">
					{!isMdScreen &&
						(on_air ? (
							<button className="bg-[#F80000] text-white text-xs leading-[14.4px] rounded px-[6.26px] py-[5px] self-start md:self-center">
								{t("ON AIR")}
							</button>
						) : (
							<CountDownTimer time={start_date} />
						))}
					{validBtn()}
				</div>
			</div>
		</BannerCard>
	);
};

export default ConferenceBanner;
