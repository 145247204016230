import { Button, Select, Textarea, useToast } from "@chakra-ui/react";
import { FadeBox } from "../../../components/boxes/FadeBox";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { selectedTab } from "../selectedTab";

export const HelpCenter = () => {
	const { t } = useTranslation();
	const toast = useToast();
	const { iNeedHelp } = useApiRequests();
	const [message, setMessage] = useState("");
	const [, setselectedTap] = useRecoilState(selectedTab);

	const { mutate, isPending } = useMutation({
		mutationFn: () => iNeedHelp(message),
		onSuccess: () => {
			toast({
				title: "Your message sent successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			setMessage("");
		},
		onError: () => {
			toast({
				title: "Please try again",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		},
	});

	return (
		<FadeBox className="lg:col-span-7 col-span-9 flex-col gap-[30px] bg-white px-3 py-[30px] h-[99%]">
			<p className="mb-4 text-base font-semibold text-left text-[#323e53]">
				<button className="m-2 text-xs" onClick={() => setselectedTap("")}>
					<FaChevronLeft />
				</button>
				{t("Help Center")}
			</p>
			<div className="flex flex-col justify-start left-0 w-full gap-[50px] px-4 py-[30px] rounded-lg bg-[#f7fafc] h-full">
				<div className="flex flex-col justify-start items-start  gap-[30px] px-4">
					<div className="flex flex-col justify-start items-center  gap-5 w-full">
						<div className="flex flex-col w-full justify-start items-start  gap-3">
							<p className="self-stretch  text-sm font-semibold text-left text-[#718096]">
								{t("Reason to contact")}
							</p>
							<Select border="solid 2px #bbbbbb59">
								<option>{t("General")}</option>
							</Select>
						</div>
						<Textarea
							border="solid 2px #bbbbbb59"
							placeholder="Message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						<Button
							isDisabled={message.length < 3}
							isLoading={isPending}
							onClick={() => mutate()}
							bg={"#5B6575"}
							color="white"
							w={"255px"}
							m="0 auto"
						>
							<span className="xl:text-xs  text-[10px]">{t("Send")}</span>{" "}
						</Button>
					</div>
				</div>
				<svg
					height={1}
					viewBox="0 0 825 1"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="self-stretch "
					preserveAspectRatio="none"
				>
					<path d="M825 0.5H0" stroke="#E2E8F0" />
				</svg>
				<div className="flex justify-start md:items-center md:flex-row flex-col items-center gap-5">
					<p className=" text-sm font-semibold text-left text-[#718096]">
						{t("Contact information")}
					</p>
					<div className="flex gap-5 justify-start items-center">
						<div className="flex justify-start items-center gap-3">
							<svg
								width={24}
								height={25}
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className=" w-6 h-6"
								preserveAspectRatio="none"
							>
								<path
									d="M3 8.5L10.8906 13.7604C11.5624 14.2083 12.4376 14.2083 13.1094 13.7604L21 8.5M5 19.5H19C20.1046 19.5 21 18.6046 21 17.5V7.5C21 6.39543 20.1046 5.5 19 5.5H5C3.89543 5.5 3 6.39543 3 7.5V17.5C3 18.6046 3.89543 19.5 5 19.5Z"
									stroke="#718096"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<a
								className=" text-sm text-left text-[#323e53]"
								href="mailto:info@terapiainfiltrativapro.it"
							>
								info@terapiainfiltrativapro.it
							</a>
						</div>
						<div className="flex justify-start items-center gap-3">
							<svg
								width={24}
								height={25}
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className=" w-6 h-6"
								preserveAspectRatio="none"
							>
								<path
									d="M9.22792 4.18377L8.51641 4.42094V4.42094L9.22792 4.18377ZM10.7257 8.67721L10.0142 8.91438V8.91438L10.7257 8.67721ZM10.2243 9.88787L10.5597 10.5587H10.5597L10.2243 9.88787ZM7.96701 11.0165L7.6316 10.3457C7.27157 10.5257 7.11784 10.9578 7.28329 11.3248L7.96701 11.0165ZM13.4835 16.533L13.1752 17.2167C13.5422 17.3822 13.9743 17.2284 14.1543 16.8684L13.4835 16.533ZM14.6121 14.2757L13.9413 13.9403L14.6121 14.2757ZM15.8228 13.7743L15.5856 14.4858L15.5856 14.4858L15.8228 13.7743ZM20.3162 15.2721L20.5534 14.5606L20.3162 15.2721ZM5 2.75C3.48122 2.75 2.25 3.98122 2.25 5.5H3.75C3.75 4.80964 4.30964 4.25 5 4.25V2.75ZM8.27924 2.75H5V4.25H8.27924V2.75ZM9.93944 3.9466C9.70124 3.232 9.03249 2.75 8.27924 2.75V4.25C8.38685 4.25 8.48238 4.31886 8.51641 4.42094L9.93944 3.9466ZM11.4372 8.44004L9.93944 3.9466L8.51641 4.42094L10.0142 8.91438L11.4372 8.44004ZM10.5597 10.5587C11.3386 10.1692 11.7126 9.26624 11.4372 8.44004L10.0142 8.91438C10.0536 9.03241 10.0001 9.16141 9.88886 9.21705L10.5597 10.5587ZM8.30242 11.6873L10.5597 10.5587L9.88886 9.21705L7.6316 10.3457L8.30242 11.6873ZM13.7918 15.8493C11.5138 14.8222 9.67779 12.9862 8.65073 10.7082L7.28329 11.3248C8.46071 13.9362 10.5638 16.0393 13.1752 17.2167L13.7918 15.8493ZM13.9413 13.9403L12.8127 16.1976L14.1543 16.8684L15.283 14.6111L13.9413 13.9403ZM16.06 13.0628C15.2338 12.7874 14.3308 13.1614 13.9413 13.9403L15.283 14.6111C15.3386 14.4999 15.4676 14.4464 15.5856 14.4858L16.06 13.0628ZM20.5534 14.5606L16.06 13.0628L15.5856 14.4858L20.0791 15.9836L20.5534 14.5606ZM21.75 16.2208C21.75 15.4675 21.268 14.7988 20.5534 14.5606L20.0791 15.9836C20.1811 16.0176 20.25 16.1132 20.25 16.2208H21.75ZM21.75 19.5V16.2208H20.25V19.5H21.75ZM19 22.25C20.5188 22.25 21.75 21.0188 21.75 19.5H20.25C20.25 20.1904 19.6904 20.75 19 20.75V22.25ZM18 22.25H19V20.75H18V22.25ZM2.25 6.5C2.25 15.1985 9.30152 22.25 18 22.25V20.75C10.1299 20.75 3.75 14.3701 3.75 6.5H2.25ZM2.25 5.5V6.5H3.75V5.5H2.25Z"
									fill="#718096"
								/>
							</svg>
							<a
								href="tel:02 36692 890"
								className=" text-sm text-left text-[#323e53] h-fit"
							>
								02 36692 890
							</a>
						</div>
					</div>
				</div>
			</div>
		</FadeBox>
	);
};
