import { Avatar } from "@chakra-ui/react";
import border from "../../assets/svgs/mobile-sidebar/border.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../store/atoms";
import MsgGroup from "../../components/icons/MsgGroup";
import Home from "../../components/icons/Home";
interface MobileBottomBarProps {}

const MobileBottomBar: React.FunctionComponent<MobileBottomBarProps> = () => {
	const [userData] = useRecoilState(userDataAtom);
	const navigate = useNavigate();

	return (
		<div className="flex z-50  bg-blue-primary md:hidden justify-between items-center h-[65px] sticky bottom-0">
			<NavLink className="w-full flex justify-center relative" to="/chat">
				{({ isActive }) =>
					isActive ? (
						<>
							<img className="absolute top-[-17px]" src={border} alt="border" />
							<MsgGroup className="!stroke-white" />
						</>
					) : (
						<>
							<div className="absolute top-[-17px]" />
							<MsgGroup className="!stroke-white" />
						</>
					)
				}
			</NavLink>
			<NavLink className="w-full flex justify-center relative" to="/">
				{({ isActive }) =>
					isActive ? (
						<>
							<img className="absolute top-[-17px]" src={border} alt="border" />
							<Home className="!stroke-white" />
						</>
					) : (
						<>
							<div className="absolute top-[-17px]" />
							<Home className="!stroke-white" />
						</>
					)
				}
			</NavLink>
			{/* 
      <NavLink className='w-full flex justify-center relative' to='/events'>
        {({ isActive }) =>
          isActive ? (
            <>
              <img className='absolute top-[-17px]' src={border} alt='border' />
              <img className='w-8' src={events} alt='events' />
            </>
          ) : (
            <>
              <div className='absolute top-[-17px]' />
              <img className='w-8' src={events} alt='events' />
            </>
          )
        }
      </NavLink> */}
			<NavLink className="w-full flex justify-center relative" to="/profile">
				<Avatar
					cursor={"pointer"}
					onClick={() => navigate("/profile")}
					name={userData?.user_data.first_name}
					background="gray.500"
					src={`${process.env.REACT_APP_MEDIA_URL}${userData?.user_data.image}`}
					w={"40px"}
					h="40px"
					rounded={"lg"}
				/>
			</NavLink>
		</div>
	);
};

export default MobileBottomBar;
