import { useRecoilState } from "recoil";
import { replyMassge, userDataAtom } from "../../../../store/atoms";
import { useToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { UseMutationResult } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { checkIfEndOfPage } from "../../../../utility/utility";
import { useTranslation } from "react-i18next";

interface MoreMenuProps {
	onOpen: () => void;
	isOpen: boolean;
	isOwner?: boolean;
	OnClose: () => void;
	massageData?: {
		id: string;
		massageImg?: string;
		name: string;
		massage: string;
		isPinned: boolean;
		userId: string | undefined;
	};
	hundlePin: UseMutationResult<AxiosResponse<any, any>, unknown, void, unknown>;
	isPinned: boolean;
	hundleUnpin: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		void,
		unknown
	>;
	hundleRemoveMassage?: (
		msgId: string
	) => UseMutationResult<AxiosResponse<any, any>, unknown, string, unknown>;
}

const MoreMenu: React.FunctionComponent<MoreMenuProps> = ({
	isOpen,
	onOpen,
	isOwner,
	OnClose,
	massageData,
	hundlePin,
	hundleUnpin,
	isPinned,
	hundleRemoveMassage,
}) => {
	const { t } = useTranslation();
	const [, setReplyMassage] = useRecoilState(replyMassge);
	const [userData] = useRecoilState(userDataAtom);
	const toast = useToast();
	const elRef = useRef<HTMLDivElement>(null);
	const [isBelowScreen, setisBelowScreen] = useState(false);
	const hundleReply = () => {
		massageData &&
			setReplyMassage({
				id: massageData.id,
				massageImg: massageData.massageImg,
				name: massageData.name,
				massage: massageData.massage,
			});
		OnClose();
	};
	const hundleCopyText = () => {
		if (massageData) {
			navigator.clipboard
				.writeText(massageData.massage)
				.then(() => {
					toast({
						title: t("Copied"),
						description: t("Massage copied to clipboard"),
						status: "success",
						duration: 2000,
						isClosable: true,
						position: "top",
					});
				})
				.catch((error) => {
					console.error("Failed to copy text: ", error);
				});
		}
		OnClose();
	};

	const hundleRemove =
		hundleRemoveMassage &&
		hundleRemoveMassage(massageData?.id ? massageData?.id : "");

	useEffect(() => {
		onOpen();
		if (elRef.current !== undefined) {
			const isBelow = checkIfEndOfPage(elRef.current);
			setisBelowScreen(isBelow);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elRef]);

	return (
		<>
			{isOpen && (
				<div
					ref={elRef}
					className={`flex flex-col p-[5px] justify-center items-center gap-[5px] min-w-[130px] bg-white absolute ${
						isBelowScreen ? "-bottom-full" : "top-1/2"
					} ${isOwner ? "-left-32" : "-right-32"}`}
				>
					<div
						onClick={() => {
							hundleReply();
						}}
						className="flex gap-[5px] hover:bg-[#edf2f7] items-center w-full p-[6px] cursor-pointer select-none"
					>
						<svg
							width="16"
							height="17"
							viewBox="0 0 16 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2 7.16666H8.66667C11.6122 7.16666 14 9.55447 14 12.5V13.8333M2 7.16666L6 11.1667M2 7.16666L6 3.16666"
								stroke="#718096"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span className="text-blue-primary text-xs">{t("Reply")}</span>
					</div>
					<div
						onClick={() => {
							hundleCopyText();
						}}
						className="flex gap-[5px] hover:bg-[#edf2f7] items-center w-full p-[6px] cursor-pointer select-none"
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5.33341 4.66667V10C5.33341 10.7364 5.93037 11.3333 6.66675 11.3333H10.6667M5.33341 4.66667V3.33333C5.33341 2.59695 5.93037 2 6.66675 2H9.72394C9.90075 2 10.0703 2.07024 10.1953 2.19526L13.1382 5.13807C13.2632 5.2631 13.3334 5.43266 13.3334 5.60948V10C13.3334 10.7364 12.7365 11.3333 12.0001 11.3333H10.6667M5.33341 4.66667H4.66675C3.56218 4.66667 2.66675 5.5621 2.66675 6.66667V12.6667C2.66675 13.403 3.2637 14 4.00008 14H8.66675C9.77132 14 10.6667 13.1046 10.6667 12V11.3333"
								stroke="#718096"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>

						<span className="text-blue-primary text-xs">{t("Copy")}</span>
					</div>
					<div
						onClick={
							isPinned
								? () => {
										hundleUnpin.mutate();
										OnClose();
								  }
								: () => {
										hundlePin.mutate();
										OnClose();
								  }
						}
						className="flex gap-[5px] hover:bg-[#edf2f7] items-center w-full p-[6px] cursor-pointer select-none"
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_1550_11975)">
								<path
									d="M4.23149 12.2046L6.38312 10.0639L6.58683 10.0724L8.48383 11.9597C8.52627 12.002 8.57295 12.0442 8.62388 12.0737C8.78515 12.1708 8.94217 12.1455 9.07373 12.0104C9.43446 11.6346 9.56601 11.1659 9.60845 10.6635C9.64665 10.199 9.59997 9.74305 9.51085 9.29126C9.49811 9.22371 9.51509 9.18149 9.56177 9.13504C9.8546 8.84793 10.1474 8.55659 10.436 8.26526C10.4827 8.21881 10.5209 8.16392 10.5761 8.09637C10.6694 8.19348 10.7373 8.26948 10.8095 8.34126C11.0004 8.53126 11.1914 8.53126 11.3824 8.34126L12.4179 7.31103C12.6089 7.12103 12.6173 6.8128 12.4264 6.6228C11.3739 5.57146 10.3172 4.52012 9.26046 3.473C9.08646 3.29566 8.89124 3.29566 8.713 3.473C8.33954 3.84033 7.96608 4.21189 7.59687 4.58345C7.41438 4.76923 7.41438 4.95501 7.59687 5.13657C7.67325 5.21257 7.76238 5.28012 7.84725 5.3519C7.46955 5.72346 7.1258 6.06546 6.7778 6.40746C6.7481 6.43702 6.68868 6.44546 6.64624 6.43702C6.13274 6.34413 5.61923 6.29769 5.09724 6.36102C4.67285 6.41591 4.28242 6.54258 3.95988 6.82969C3.72647 7.03658 3.72223 7.22658 3.94291 7.44614C4.58373 8.0837 5.22455 8.71704 5.86537 9.3546V9.56149L3.74345 11.6726C3.70525 11.7106 3.66706 11.7486 3.63735 11.7908C3.55672 11.9048 3.53974 12.0273 3.60764 12.154C3.6713 12.2722 3.76891 12.3355 3.90896 12.3397C4.04052 12.344 4.14237 12.2975 4.23149 12.2046Z"
									stroke="#718096"
									strokeWidth="1.2"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1550_11975">
									<rect
										width="12"
										height="12"
										fill="white"
										transform="translate(2.56543 2.34)"
									/>
								</clipPath>
							</defs>
						</svg>

						<span className="text-blue-primary text-xs">
							{/* {isPinned ? t("Unpin") : t("Pin")} */}
							{t("Pin")}
						</span>
					</div>
					{userData?.accessToken.tokenable_id === massageData?.userId && (
						<div
							onClick={() => {
								hundleRemove &&
									hundleRemove.mutate(massageData?.id ? massageData?.id : "");
								OnClose();
							}}
							className="flex gap-[5px] hover:bg-[#edf2f7] items-center w-full p-[6px] cursor-pointer select-none"
						>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.6667 4.66667L12.0885 12.7617C12.0387 13.4594 11.4581 14 10.7586 14H5.24157C4.54205 14 3.96147 13.4594 3.91163 12.7617L3.33341 4.66667M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M10.0001 4.66667V2.66667C10.0001 2.29848 9.7016 2 9.33341 2H6.66675C6.29856 2 6.00008 2.29848 6.00008 2.66667V4.66667M2.66675 4.66667H13.3334"
									stroke="#718096"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>

							<span className="text-blue-primary text-xs">{t("Delete")}</span>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default MoreMenu;
