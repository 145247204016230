import moment from "moment";
import "moment/locale/it"; // without this line it didn't work

// how likes dealing with time ?
const MessageBoxLastMessage = ({ date }: { date: string }) => {
	const elapsedTime = moment(date).locale("it").fromNow();

	return (
		<div className="text-xs italic leading-[12px] line-clamp-1 text-blue-primary-50">
			{elapsedTime}
		</div>
	);
};

export default MessageBoxLastMessage;
