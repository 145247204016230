/* eslint-disable jsx-a11y/alt-text */
import { FadeBox } from "../FadeBox";
import noFound from "../../../assets/svgs/chat/404.svg";

interface IntroBoxProps {
	text: string;
}

const IntroBox: React.FunctionComponent<IntroBoxProps> = ({ text }) => {
	return (
		<FadeBox className="flex-col justify-center items-center h-full hidden md:flex w-full">
			<div className="flex flex-col max-w-[300px]">
				<img className="w-[170px] mx-auto" src={noFound} />
				<p className="text-center text-blue-primary font-semibold">{text}</p>
			</div>
		</FadeBox>
	);
};

export default IntroBox;
