import FooterLinks from "../../../components/boxes/FooterLinks";
import AuthenticationLayout from "../AuthenticationLayout";
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface PrivacyProps {}

const Privacy: React.FunctionComponent<PrivacyProps> = () => {
	const navigate = useNavigate();
	const { getConfigs } = useApiRequests();
	const data = useQuery({
		queryKey: ["app-configs"],
		queryFn: getConfigs,
	});
	const { t } = useTranslation();

	return (
		<AuthenticationLayout>
			<div className="col-span-2 flex flex-col gap-[10px] text-left md:w-full justify-self-center mx-auto relative">
				<div className="flex gap-2 justify-center items-center mb-8 self-start">
					<MdKeyboardBackspace
						onClick={() => navigate("/")}
						className="text-3xl text-orange-primary cursor-pointer"
					/>
					<h1 className="text-3xl font-bold text-orange-primary ">
						{t("Privacy")}
					</h1>
				</div>
				{data?.data?.data && (
					<p
						className="text-blue-primary  overflow-y-auto max-h-[60vh]"
						dangerouslySetInnerHTML={{ __html: data?.data?.data.privacy }}
					/>
				)}
			</div>
			<FooterLinks />
		</AuthenticationLayout>
	);
};

export default Privacy;
