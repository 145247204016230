import { useRecoilValue } from "recoil";
import { sidebarState } from "../../store/atoms";

const SidebarIcon = () => {
	const sideBarState = useRecoilValue(sidebarState);

	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`transition-all duration-300 ${
				sideBarState ? "rotate-0" : "rotate-180"
			}`}
		>
			<path
				d="M8.33329 10.6666L8.91663 10.6666C9.88312 10.6666 10.6666 9.88312 10.6666 8.91663L10.6666 3.08329C10.6666 2.11679 9.88312 1.33329 8.91663 1.33329L8.33329 1.33329M3.66662 3.66663L1.33329 5.99996M1.33329 5.99996L3.66663 8.33329M1.33329 5.99996L8.33329 5.99996"
				stroke="#CBD5E0"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default SidebarIcon;
