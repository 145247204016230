import { Spinner, useMediaQuery } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FadeBox } from "../../components/boxes/FadeBox";
import LeftMenu from "../../components/menus/LeftMenu";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../store/atoms";
import { ChangePassword } from "./panels/ChangePassword";
import { HelpCenter } from "./panels/HelpCenter";
import { Info } from "./panels/Info";
import { Notifications } from "./panels/Notifications";
import { SectionsMenu } from "./panels/SectionsMenu";
import { selectedTab } from "./selectedTab";
import { useTranslation } from "react-i18next";

export const Profile = () => {
	const [sideBarOpen] = useRecoilState(sidebarState);

	const [selectedTap, setselectedTap] = useRecoilState(selectedTab);
	const { t } = useTranslation();

	const TapsPanels = {
		info: Info,
		changePassword: ChangePassword,
		notifications: Notifications,
		help: HelpCenter,
	};

	const { getProfile } = useApiRequests();

	const SelectedTapPanel = TapsPanels[selectedTap as keyof typeof TapsPanels];

	const [isMobile] = useMediaQuery("(max-width: 700px)");
	const [isDesktop] = useMediaQuery("(min-width: 1000px)");

	const [isTabletScreen, isSmallScreen] = useMediaQuery([
		"(max-width: 768px)",
		"(max-width: 1024px)",
	]);

	useEffect(() => {
		isDesktop && setselectedTap("info");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop]);

	const profileQuery = useQuery({
		queryKey: ["profile"],
		queryFn: getProfile,
		select: (res) => res.data,
	});

	const calculateGridWidth = (colsCount: number, gridCols: number) => {
		const widthOfContent = document.documentElement.clientWidth - 90;
		const colWidth = widthOfContent / gridCols;
		return colWidth * colsCount;
	};

	return (
		<div className="flex w-full self-start relative flex-col lg:flex-row overflow-x-hidden transition-all duration-300 bg-[#F7FAFC]">
			<div
				className={`bg-white  ${
					isTabletScreen && "!w-full"
				} md:hidden transition-all duration-300 flex-shrink-0 md:h-dvh sticky top-0 pl-2 pr-3 bg-gray-50 hidden lg:flex ${
					!isTabletScreen && isSmallScreen
						? 0
						: sideBarOpen
						? "w-[calc(((100vw-90px)/7)*2)] max-w-full"
						: "w-[98px]"
				}`}
			>
				<LeftMenu title={t("My Profile")}>
					<SectionsMenu
						selectedTap={selectedTap}
						setselectedTap={setselectedTap}
					/>
				</LeftMenu>
			</div>

			{isMobile && !selectedTap && (
				<FadeBox className="h-[calc(100dvh-75px-65px)] lg:min-h-full p-4 bg-[#F7FAFC]">
					<p className="text-blue-primary font-semibold text-xl mb-8">
						{t("My Profile")}
					</p>
					<SectionsMenu
						selectedTap={selectedTap}
						setselectedTap={setselectedTap}
					/>
				</FadeBox>
			)}

			{selectedTap && isMobile && profileQuery?.data?.profile && (
				<div className="bg-white w-full col-span-9 flex flex-col h-[calc(100dvh-75px-65px)] lg:min-h-full">
					<SelectedTapPanel
						photo={profileQuery?.data?.photo}
						profileData={{
							...profileQuery?.data?.profile,
							email: profileQuery?.data.email,
						}}
					/>
				</div>
			)}

			{profileQuery.isLoading && (
				<div
					className="w-full flex-1 h-dvh bg-white flex items-center justify-center"
					style={{
						width: isSmallScreen
							? "100%"
							: sideBarOpen
							? calculateGridWidth(5, 7)
							: calculateGridWidth(1, 1) - 186,
					}}
				>
					<Spinner />
				</div>
			)}

			{!isMobile && selectedTap && profileQuery?.data?.profile && (
				<div
					className="w-full flex-1 h-dvh bg-white"
					style={{
						width: isSmallScreen
							? "100%"
							: sideBarOpen
							? calculateGridWidth(5, 7)
							: calculateGridWidth(1, 1) - 186,
					}}
				>
					<SelectedTapPanel
						photo={profileQuery?.data?.photo}
						profileData={{
							...profileQuery?.data?.profile,
							email: profileQuery?.data.email,
						}}
					/>
				</div>
			)}
		</div>
	);
};
