import { Avatar, useMediaQuery } from "@chakra-ui/react";
import {
	LocalVideo,
	RemoteVideo,
	useAttendeeStatus,
	useRemoteVideoTileState,
} from "amazon-chime-sdk-component-library-react";
import { BsFillMicMuteFill } from "react-icons/bs";

export const Attendee = ({
	chimeAttendeeId,
	externalUserId,
	selectedView,
	setselectedView,
	contentShareTileId,
	width,
}: any) => {
	const { attendeeIdToTileId } = useRemoteVideoTileState();
	// const isCreator = (externalUserId as string).endsWith('t')

	const matched = Object.keys(attendeeIdToTileId).find(
		(attendeeId) => attendeeId === chimeAttendeeId
	);

	const { muted, videoEnabled } = useAttendeeStatus(chimeAttendeeId);

	const tileId = attendeeIdToTileId[matched as string];
	// const getBoxStyle = () => {
	//   if ((isCreator && !selectedView && !contentShareTileId) || selectedView === chimeAttendeeId) {
	//     return 'col-span-1 lg:col-span-3 row-span-4 lg:row-span-6 order-1 rounded-lg'
	//   } else return 'col-span-1 lg:col-span-1 row-span-4 lg:row-span-2 order-2 rounded-lg'
	// }

	const [isMobile] = useMediaQuery("(max-width: 768px)");

	return (
		<div
			onClick={() => !contentShareTileId && setselectedView(chimeAttendeeId)}
			className={`cursor-pointer !aspect-video`}
			style={{
				...(() => {
					if (!contentShareTileId) {
						return { minWidth: width, maxWidth: width };
					} else {
						return { minWidth: "200px", maxWidth: "200px" };
					}
				})(),
			}}
		>
			{videoEnabled ? (
				<>
					{tileId ? (
						<div className="h-full rounded-lg relative">
							<RemoteVideo
								style={{ borderRadius: "0.5rem" }}
								css="rounded-lg"
								tileId={tileId}
							/>
							<div className="flex w-full justify-between absolute bottom-2 px-2">
								<span className="bg-[#2D3748] rounded-lg text-xs text-white flex justify-center items-center px-2">
									{externalUserId.replace("|", " ").slice(0, -2)}
								</span>
								<span>
									{muted ? <BsFillMicMuteFill className="text-red-600" /> : ""}
								</span>
							</div>
						</div>
					) : (
						<LocalVideo />
					)}
				</>
			) : (
				<div
					className={
						"bg-[#4A5568] h-full p-5 rounded-lg flex flex-col relative text-white justify-center items-center border-orange-primary"
					}
				>
					<Avatar
						size={"lg"}
						name={externalUserId.replace("|", " ").slice(0, -2)}
						background="#2D3748"
						color={"white"}
					/>
					<div className="flex w-full justify-between absolute bottom-2 px-2">
						<span className="bg-[#2D3748] rounded-lg text-xs flex justify-center items-center px-2">
							{isMobile
								? externalUserId.replace("|", " ").slice(0, -2).split(" ")?.[0]
								: externalUserId.replace("|", " ").slice(0, -2)}
						</span>
						<span>
							{muted ? <BsFillMicMuteFill className="text-red-600" /> : ""}
						</span>
					</div>
				</div>
			)}
		</div>
	);
};
