import { Member } from "../../../types";
import { Link } from "@chakra-ui/react";

const UserSocials = ({
	data,
	isLoading,
}: {
	isLoading: boolean;
	data: Member | undefined;
}) => {
	return (
		<div className="flex flex-wrap md:flex-nowrap">
			{isLoading ? (
				<div className="flex gap-4 self-end px-4 ml-[115px] md:ml-0">
					<div className="w-4 h-4 rounded bg-slate-300 animate-pulse" />
					<div className="w-4 h-4 rounded bg-slate-300 animate-pulse" />
					<div className="w-4 h-4 rounded bg-slate-300 animate-pulse" />
				</div>
			) : (
				<div className="flex gap-4 self-end px-4">
					{data?.profile.website && data?.profile.website !== "null" && (
						<Link href={data.profile.website}>
							<svg
								className="cursor-pointer"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.802 8.4325C14.802 11.7462 12.1157 14.4325 8.802 14.4325M14.802 8.4325C14.802 5.11879 12.1157 2.4325 8.802 2.4325M14.802 8.4325H2.802M8.802 14.4325C5.48829 14.4325 2.802 11.7462 2.802 8.4325M8.802 14.4325C9.90657 14.4325 10.802 11.7462 10.802 8.4325C10.802 5.11879 9.90657 2.4325 8.802 2.4325M8.802 14.4325C7.69743 14.4325 6.802 11.7462 6.802 8.4325C6.802 5.11879 7.69743 2.4325 8.802 2.4325M2.802 8.4325C2.802 5.11879 5.48829 2.4325 8.802 2.4325"
									stroke="#FF8C1E"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Link>
					)}

					{data?.email && (
						<Link href={`mailto:${data.email}`}>
							<svg
								className="cursor-pointer"
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.802 13.0992V7.1461C2.802 6.7003 3.0248 6.28399 3.39574 6.0367L8.0624 2.92559C8.51027 2.62702 9.09374 2.62702 9.5416 2.92559L14.2083 6.0367C14.5792 6.28399 14.802 6.7003 14.802 7.1461V13.0992M2.802 13.0992C2.802 13.8356 3.39896 14.4325 4.13534 14.4325H13.4687C14.205 14.4325 14.802 13.8356 14.802 13.0992M2.802 13.0992L7.302 10.0992M14.802 13.0992L10.302 10.0992M2.802 7.09919L7.302 10.0992M14.802 7.09919L10.302 10.0992M10.302 10.0992L9.5416 10.6061C9.09374 10.9047 8.51027 10.9047 8.0624 10.6061L7.302 10.0992"
									stroke="#FF8C1E"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Link>
					)}

					{data?.profile.linkedin && data?.profile.linkedin !== "null" && (
						<Link href={data.profile.linkedin}>
							<svg
								className="cursor-pointer"
								width="14"
								height="15"
								viewBox="0 0 14 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_1743_44049)">
									<path
										d="M3.71249 12.6825H1.01673V4.50398H3.71249V12.6825ZM2.36316 3.38835C1.50133 3.38835 0.802002 2.7157 0.802002 1.90359C0.802002 1.09148 1.50133 0.432495 2.36316 0.432495C3.22499 0.432495 3.92432 1.09148 3.92432 1.90359C3.92432 2.7157 3.22499 3.38835 2.36316 3.38835ZM13.802 12.6825H11.112V8.70125C11.112 7.75242 11.0917 6.53562 9.71048 6.53562C8.30892 6.53562 8.09419 7.56648 8.09419 8.63289V12.6825H5.40133V4.50398H7.98682V5.6196H8.02455C8.38437 4.97703 9.26361 4.2989 10.5752 4.2989C13.3029 4.2989 13.8049 5.99148 13.8049 8.18992V12.6825H13.802Z"
										fill="#FF8C1E"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1743_44049">
										<rect
											width="13"
											height="14"
											fill="white"
											transform="translate(0.802002 0.432495)"
										/>
									</clipPath>
								</defs>
							</svg>
						</Link>
					)}
				</div>
			)}
		</div>
	);
};

export default UserSocials;
