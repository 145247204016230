const ReplyIcon = () => {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 7.16666H8.66667C11.6122 7.16666 14 9.55447 14 12.5V13.8333M2 7.16666L6 11.1667M2 7.16666L6 3.16666"
				stroke="#718096"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ReplyIcon;
