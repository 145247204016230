import { Avatar } from "@chakra-ui/avatar";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { FadeBox } from "../../../components/boxes/FadeBox";
import Users from "../../../components/icons/Users";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import GikisBox from "../../../pages/gikis/panels/GikisBox";
import { userSelected } from "../../../store/atoms";
import { Member, MembersList } from "../../../types";
import BackButton from "./BackButton";
import UserSocials from "./UserSocials";
interface UserPanelProps {
	user: MembersList;
}

const UserPanel: React.FunctionComponent<UserPanelProps> = ({ user }) => {
	const { t } = useTranslation();
	const { getUserDetails } = useApiRequests();
	const [, setSelectedUser] = useRecoilState(userSelected);

	const { data, isLoading } = useQuery({
		queryKey: ["gikis", user.id],
		queryFn: () => getUserDetails(user.id),
		select: (res) => res.data.data as Member,
	});

	useEffect(() => {
		return () => {
			setSelectedUser({
				first_name: "",
				id: "",
				last_name: "",
				photo: "",
				profession: "",
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="w-full bg-white md:block">
			<div className="flex items-center gap-[10px] p-4 border-b-[1px] border-b-gray-50">
				<BackButton />

				<Users />
				<div className="flex flex-col gap-[2px] ">
					<h2 className="text-base font-bold text-blue-primary leading-[19.2px]">
						{t("gikis.members.Users")}
					</h2>
				</div>
			</div>
			<FadeBox key={user.id} className="flex flex-col justify-between h-full">
				<div>
					<div className="w-full h-[176px] bg-blue-primary relative p-4 flex flex-col justify-between">
						<div className="flex flex-row items-center gap-2">
							<div className="w-16 h-16">
								<Avatar
									height={"64px"}
									width={"64px"}
									name={`${user.first_name} ${user.last_name}`}
									src={
										data?.photo
											? `${process.env.REACT_APP_MEDIA_URL}${data.photo}`
											: ""
									}
									bgColor="blue-primary"
									className="text-gray-50 w-16 h-16"
									borderRadius="4px"
									borderColor="white"
									borderWidth="2px"
								/>
							</div>
							<div className="flex flex-col !self-stretch justify-between  flex-1 h-16">
								<h3 className="text-base font-bold leading-[19.2px] text-gray-50">
									{user.first_name} {user.last_name}
								</h3>
								<div className="text-xs leading-[15px] text-[#CBD5E0]">
									{isLoading ? (
										<div className="flex items-center gap-1">
											<div className="animate-pulse bg-slate-300 rounded h-4 w-[138px]" />
											<div className="animate-pulse bg-slate-300 rounded h-4 w-[138px]" />
										</div>
									) : (
										<>
											{data?.profile.profession} ,{" "}
											{data?.profile.specialization}
										</>
									)}
								</div>
								<div className="flex justify-between items-center">
									{isLoading ? (
										<div className="animate-pulse bg-slate-300 rounded h-4 w-[138px]" />
									) : (
										<div className="text-xs leading-[15px] text-[#CBD5E0]">
											{t("gikis.members.Member_since")} {data?.created_at}
										</div>
									)}

									<UserSocials data={data} isLoading={isLoading} />
								</div>
							</div>
						</div>
						<div className="text-sm leading-[16.8px] text-white line-clamp-4">
							{isLoading ? (
								<div className="flex flex-col gap-1">
									<div className="animate-pulse bg-slate-300 rounded h-4 w-full" />
									<div className="animate-pulse bg-slate-300 rounded h-4 w-full" />
									<div className="animate-pulse bg-slate-300 rounded h-4 w-[140px]" />
								</div>
							) : (
								(data?.profile.bio && data?.profile.bio !== "null") ||
								t("gikis.members.no_bio")
							)}
						</div>
					</div>

					<div className="flex px-5 py-5 flex-col gap-5">
						{data?.mutual_communities && (
							<GikisBox
								isLoading={isLoading}
								title={t("gikis.gikis_in_common_with_you")}
								data={data ? data.mutual_communities : []}
							/>
						)}
						{data?.unmutual_communities && (
							<GikisBox
								isLoading={isLoading}
								title={`${t("follows")} ${user.first_name} ${user.last_name} `}
								data={data ? data.unmutual_communities : []}
							/>
						)}
					</div>
				</div>
			</FadeBox>
		</div>
	);
};

export default UserPanel;
