/* eslint-disable jsx-a11y/alt-text */
import { IconButton, Textarea, useMediaQuery } from "@chakra-ui/react";
import { KeyboardEvent, useEffect } from "react";
import { useRecoilState } from "recoil";
import { replyMassge } from "../../../../../store/atoms";
import send from "../../../../../assets/svgs/chat/sender/send.svg";
import activeSend from "../../../../../assets/svgs/chat/sender/activeSend.svg";

interface MassageInputProps {
	setMassage: (massage: string) => void;
	massage: string;
	sendMassageHandler: () => void;
	isLoading: boolean;
	placeHolder: string;
	inputRef?: any;
}

const MassageInput: React.FunctionComponent<MassageInputProps> = ({
	massage,
	setMassage,
	sendMassageHandler,
	isLoading,
	placeHolder,
	inputRef,
}) => {
	const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key !== "Enter" || e.shiftKey) return;
		e.preventDefault();
		sendMassageHandler();
	};
	const [replyMassage] = useRecoilState(replyMassge);
	const [isXS] = useMediaQuery("(max-width: 400px)");

	useEffect(() => {
		if (replyMassage.id !== "" || massage !== "") {
			inputRef?.current?.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [massage, replyMassage]);
	return (
		<>
			<Textarea
				variant="user-outline"
				resize="none"
				ref={inputRef}
				className="w-full placeholder:text-sm bg-[#F7FAFC] rounded"
				onChange={(e) => {
					setMassage(e.target.value);
				}}
				onKeyDown={(e) => {
					handleKeyPress(e);
				}}
				value={massage}
				rows={1}
				placeholder={
					placeHolder.length > 15
						? isXS
							? placeHolder.slice(0, 12) + "..."
							: placeHolder.slice(0, 15) + "..."
						: placeHolder
				}
				disabled={isLoading}
			/>
			<IconButton
				_active={{ bg: "gray-20" }}
				variant="white"
				bg={massage ? "blue-primary" : "#F7FAFC"}
				aria-label="send"
				ml={2}
				onClick={() => {
					sendMassageHandler();
				}}
				isLoading={isLoading}
				disabled={!massage}
				icon={
					massage ? (
						<img className="w-5 h-5" src={activeSend} />
					) : (
						<img className="w-5 h-5" src={send} />
					)
				}
			/>
		</>
	);
};

export default MassageInput;
