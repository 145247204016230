/* eslint-disable jsx-a11y/alt-text */
import {
	Avatar,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";
import gikis from "../../assets/svgs/discover/gikis.svg";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedCategoryIdAtom } from "../../store/atoms";
interface CategoriesMenuProps {
	category: string;
	setCategory?: Dispatch<SetStateAction<string>>;
	setCategoryId?: Dispatch<SetStateAction<number>>;
	CategoriesMenuData: any;
}

const CategoriesMenu: React.FunctionComponent<CategoriesMenuProps> = ({
	setCategory,
	category,
	CategoriesMenuData,
}) => {
	const [icon, setIcon] = useState<string>(gikis);
	const [, setCategoryId] = useRecoilState(selectedCategoryIdAtom);

	return (
		<Menu>
			<MenuButton
				className="h-full"
				h="100%"
				fontSize="12px"
				rounded={"4px"}
				textTransform="capitalize"
				w="100%"
				as={Button}
				rightIcon={<MdKeyboardArrowDown fontSize="20px" />}
			>
				<div className="flex items-center gap-[10px]">
					<Avatar
						className={`aspect-square h-5 w-5 object-cover`}
						rounded={4}
						w={"20px"}
						h={"20px"}
						src={`${process.env.REACT_APP_MEDIA_URL}${icon}`}
						name={category}
					/>
					{category}
				</div>
			</MenuButton>
			<MenuList zIndex={2}>
				{CategoriesMenuData?.data.length > 0 &&
					CategoriesMenuData?.data?.map(
						(category: { id: ""; image: ""; name: " " }) => {
							return (
								<MenuItem
									onClick={() => {
										setCategory && setCategory(category.name);
										setIcon(category.image);
										setCategoryId(parseInt(category.id));
									}}
									py="15px"
									key={category.id}
								>
									<Avatar
										className={`aspect-square !h-[20px] !w-[20px] object-cover`}
										w="20px"
										h="20px"
										mr="12px"
										rounded={4}
										src={`${process.env.REACT_APP_MEDIA_URL}${category.image}`}
										name={category.name}
									/>
									<span className="font-semibold text-xs">{category.name}</span>
								</MenuItem>
							);
						}
					)}
			</MenuList>
		</Menu>
	);
};

export default CategoriesMenu;
