const Start = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_994_1061)'>
        <path
          d='M8.04895 2.92664C8.34833 2.00538 9.65167 2.00538 9.95105 2.92664L10.7963 5.52768C10.9302 5.93967 11.3141 6.21861 11.7473 6.21863L14.4822 6.21873C15.4509 6.21877 15.8537 7.45832 15.07 8.02773L12.8575 9.63536C12.507 9.89 12.3604 10.3413 12.4942 10.7533L13.3392 13.3544C13.6385 14.2757 12.5841 15.0418 11.8004 14.4725L9.58776 12.865C9.23728 12.6104 8.76272 12.6104 8.41225 12.865L6.19958 14.4725C5.41588 15.0418 4.36145 14.2757 4.66076 13.3544L5.5058 10.7533C5.63965 10.3413 5.493 9.89 5.14255 9.63536L2.93 8.02773C2.14634 7.45832 2.54909 6.21877 3.51778 6.21873L6.25271 6.21863C6.68591 6.21861 7.06984 5.93967 7.20372 5.52768L8.04895 2.92664Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_994_1061'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Start;
