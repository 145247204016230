import { useTranslation } from "react-i18next";
import { FadeBox } from "../../components/boxes/FadeBox";

export const NoGikis = ({ title }: { title: string }) => {
	const { t } = useTranslation();

	return (
		<FadeBox className="flex flex-col justify-center items-center w-full relative gap-5 px-5 py-10 rounded-lg bg-[#f7fafc]">
			<svg
				width={167}
				height={119}
				viewBox="0 0 167 119"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="flex-grow-0 flex-shrink-0 w-[167px] h-[116.91px]"
				preserveAspectRatio="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M160 26.8757C163.866 26.8757 167 30.0097 167 33.8757C167 37.7417 163.866 40.8757 160 40.8757H120C123.866 40.8757 127 44.0097 127 47.8757C127 51.7417 123.866 54.8757 120 54.8757H142C145.866 54.8757 149 58.0097 149 61.8757C149 65.7417 145.866 68.8757 142 68.8757H131.826C126.952 68.8757 123 72.0097 123 75.8757C123 78.4531 125 80.7864 129 82.8757C132.866 82.8757 136 86.0097 136 89.8757C136 93.7417 132.866 96.8757 129 96.8757H46C42.134 96.8757 39 93.7417 39 89.8757C39 86.0097 42.134 82.8757 46 82.8757H7C3.13401 82.8757 0 79.7417 0 75.8757C0 72.0097 3.13401 68.8757 7 68.8757H47C50.866 68.8757 54 65.7417 54 61.8757C54 58.0097 50.866 54.8757 47 54.8757H22C18.134 54.8757 15 51.7417 15 47.8757C15 44.0097 18.134 40.8757 22 40.8757H62C58.134 40.8757 55 37.7417 55 33.8757C55 30.0097 58.134 26.8757 62 26.8757H160ZM160 54.8757C163.866 54.8757 167 58.0097 167 61.8757C167 65.7417 163.866 68.8757 160 68.8757C156.134 68.8757 153 65.7417 153 61.8757C153 58.0097 156.134 54.8757 160 54.8757Z"
					fill="#EDF2F7"
				/>
				<circle
					cx="79.9761"
					cy="3.52393"
					r="2.27393"
					stroke="#989EA9"
					strokeWidth="2.5"
				/>
				<circle
					cx="65.4658"
					cy="17.2051"
					r="2.27393"
					stroke="#989EA9"
					strokeWidth="2.5"
				/>
				<path
					d="M39.1692 61.9417L75.2351 72.1445L111.301 61.9417L122.216 50.7897L87.0987 41.2986L75.2351 50.7897L64.0829 41.2986L28.729 50.7897L39.1692 61.9417Z"
					fill="#EDF2F7"
				/>
				<path
					d="M25.9399 76.3367L37.0796 63.7691L38.5829 61.6521L75.2005 72.4573L113.219 61.6521L124.824 76.3367L114.019 80.8613L113.219 104.072L76.4011 115.678L37.0796 104.072L38.5829 80.8613L25.9399 76.3367Z"
					fill="white"
				/>
				<path
					d="M114.267 104.079V80.5766L100.796 85.3057L77.1279 114.85L114.267 104.079Z"
					fill="#EDF2F7"
				/>
				<path
					d="M112.179 61.7636L75.7695 51.4387M112.179 61.7636L75.7695 72.6319M112.179 61.7636L122.343 51.4387L87.9651 41.246L75.7695 51.4387M112.179 61.7636L124.824 77.0416L113.557 80.1834M75.7695 51.4387L38.0023 61.7636M75.7695 51.4387L64.2195 41.246L27.0062 51.4387L38.0023 61.7636M75.7695 51.4387V61.7636M38.0023 61.7636L75.7695 72.6319M38.0023 61.7636L24.1709 77.0416L38.0023 80.7903M75.7695 72.6319L87.9651 87.3196L113.557 80.1834M75.7695 72.6319L62.0932 87.3196L38.0023 80.7903M38.0023 80.7903V105.303L75.7695 116.912M75.7695 116.912L113.557 105.303V80.1834M75.7695 116.912V88.7202M75.7695 80.1834V84.3671"
					stroke="#989EA9"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.4864 17.2051L85.0547 26.6368M85.0547 17.2051L94.4864 26.6368"
					stroke="#989EA9"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M57.8995 26.6368L50.0225 34.5138M50.0225 26.6368L57.8995 34.5138"
					stroke="#FFC58E"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M96.9941 102.939L106.965 99.8332"
					stroke="#FFC58E"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M87.269 106.09L92.495 104.318"
					stroke="#FFC58E"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
			</svg>
			<div className="flex-grow-0 flex-shrink-0 w-[488px] h-[19px] relative">
				<p className="left-[115.61px] top-0 text-sm md:text-base font-semibold text-center text-[#323e53]">
					{t("There are no")} {title}
				</p>
			</div>
		</FadeBox>
	);
};
