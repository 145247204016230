import FooterLinks from "../../../components/boxes/FooterLinks";
import LoginForm from "../../../components/forms/LoginForm";
import { useTranslation } from "react-i18next";
import AuthenticationLayout from "../AuthenticationLayout";
interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
	const { t } = useTranslation();
	const appName = localStorage.getItem("app_name");
	const appNameLong = localStorage.getItem("app_long_name");

	return (
		<AuthenticationLayout>
			<div className="col-span-2 flex flex-col gap-[10px] text-left md:w-[400px] justify-self-center mx-auto">
				<h1 className="text-3xl font-bold text-orange-primary">
					{appName ? `${t("login.var_header")} ${appName}` : t("login.header")}
				</h1>
				<p className="text-blue-primary text-xl font-semibold">
					{appNameLong
						? `${t("login.var_description")} ${appNameLong}`
						: t("login.description")}
				</p>
				<div className="pt-11">
					<LoginForm />
				</div>
			</div>
			<FooterLinks />
		</AuthenticationLayout>
	);
};

export default Login;
