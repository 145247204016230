import { Outlet, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { FadeBox } from "../../components/boxes/FadeBox";
import MassagesPanel from "./panels/MassagesPanel";
import { useMediaQuery } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { hasOpenChat, isSelectedChat, sidebarState } from "../../store/atoms";
import { useRecoilState } from "recoil";
import { useEffect } from "react";

const ChatLayout = () => {
	const [, isSelected] = useRecoilState(isSelectedChat);
	const [sideBarOpen] = useRecoilState(sidebarState);
	const [isOpen, setIsOpen] = useRecoilState(hasOpenChat);
	const { id } = useParams();

	const { getMyCommunities } = useApiRequests();

	const [isTabletScreen, isSmallScreen] = useMediaQuery([
		"(max-width: 768px)",
		"(max-width: 1024px)",
	]);

	const { data, isLoading } = useQuery({
		queryKey: ["chats"],
		queryFn: getMyCommunities,
		select: (res) => res.data,
	});

	useEffect(() => {
		if (id) {
			setIsOpen(false);
			isSelected(true);
		} else {
			setIsOpen(true);
			isSelected(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<motion.div className={`flex w-full`} layout={true} layoutRoot>
			<div
				className={`bg-white ${isOpen ? "block" : "hidden"} ${
					isTabletScreen && "!w-full"
				} lg:block transition-all duration-300 flex-shrink-0 ${
					sideBarOpen
						? "lg:w-[calc(((100vw-90px)/7)*2)] max-w-full"
						: "w-[98px]"
				}`}
			>
				<FadeBox key="MassagePanels">
					<MassagesPanel isLoading={isLoading} data={data} />
				</FadeBox>
			</div>

			<div
				className={`bg-white flex flex-col gap-[10px] transition-all duration-300 w-full flex-1 ${
					isSmallScreen
						? "100%"
						: sideBarOpen
						? "w-[calc(((100vw-90px)/7)*5)]"
						: "w-[calc(100vw-90px-186px)]"
				}`}
			>
				<Outlet />
			</div>
		</motion.div>
	);
};

export default ChatLayout;
