import { Box, useToast } from "@chakra-ui/react";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { FadeBox } from "../../../../components/boxes/FadeBox";
import { AiOutlineMore } from "react-icons/ai";
import { useRecoilState, useRecoilValue } from "recoil";
import { replyMassge, userDataAtom } from "../../../../store/atoms";
import CopyIcon from "./icons/CopyIcon";
import ReplyIcon from "./icons/ReplyIcon";
import PinIcon from "./icons/PinIcon";
import DeleteIcon from "./icons/DeleteIcon";
import { useTranslation } from "react-i18next";

interface MoreMobileMenuProps {
	onOpen: () => void;
	isOpen: boolean;
	onClose: () => void;
	massageData?: {
		id: string;
		massageImg?: string;
		name: string;
		massage: string;
		isPinned: boolean;
		userId: string | undefined;
	};
	hundlePin: UseMutationResult<AxiosResponse<any, any>, unknown, void, unknown>;
	isPinned: boolean;
	hundleUnpin: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		void,
		unknown
	>;
	hundleRemoveMassage?: (
		msgId: string
	) => UseMutationResult<AxiosResponse<any, any>, unknown, string, unknown>;
}

const MoreMobileMenu: React.FunctionComponent<MoreMobileMenuProps> = ({
	onOpen,
	isOpen,
	massageData,
	onClose,
	hundleRemoveMassage,
	hundlePin,
	hundleUnpin,
	isPinned,
}) => {
	const userData = useRecoilValue(userDataAtom);
	const { t } = useTranslation();
	const [, setReplyMassage] = useRecoilState(replyMassge);
	const toast = useToast();
	const hundleReply = () => {
		massageData &&
			setReplyMassage({
				id: massageData.id,
				massageImg: massageData.massageImg,
				name: massageData.name,
				massage: massageData.massage,
			});
		onClose();
	};
	const hundleCopyText = () => {
		if (massageData) {
			navigator.clipboard
				.writeText(massageData.massage)
				.then(() => {
					toast({
						title: t("Copied"),
						description: t("Massage copied to clipboard"),
						status: "success",
						duration: 2000,
						isClosable: true,
						position: "top",
					});
				})
				.catch((error) => {
					console.error("Failed to copy text: ", error);
				});
		}
		onClose();
	};

	const hundleRemove =
		hundleRemoveMassage &&
		hundleRemoveMassage(massageData?.id ? massageData?.id : "");

	const handleRemove = () => {
		hundleRemove && hundleRemove.mutate(massageData?.id ? massageData?.id : "");
		onClose();
	};

	const handlePin = () => {
		if (isPinned) {
			hundleUnpin.mutate();
			onClose();
		} else {
			hundlePin.mutate();
			onClose();
		}
	};

	return (
		<>
			<AiOutlineMore onClick={onOpen} className="text-text-gray" />
			{isOpen && (
				<FadeBox
					className="h-dvh bg-transparent fixed bottom-[75px] w-screen left-0 z-50"
					key={`${massageData?.id}-msg-menu`}
				>
					<Box bgColor="blackAlpha.600" className="h-[80vh] opacity-40" />

					<div className="flex flex-col gap-2 justify-between h-[215px] bg-white p-4">
						<div
							onClick={hundleReply}
							className="flex rounded-lg active:bg-[#EDF2F7] bg-gray-50 justify-center py-[10px] gap-4 cursor-pointer select-none"
						>
							<ReplyIcon />
							<span className="text-blue-primary text-sm">{t("Reply")}</span>
						</div>
						<div
							onClick={hundleCopyText}
							className="flex rounded-lg active:bg-[#EDF2F7] bg-gray-50 justify-center py-[10px] gap-4 cursor-pointer select-none"
						>
							<CopyIcon />

							<span className="text-blue-primary text-sm">{t("Copy")}</span>
						</div>
						<div
							onClick={handlePin}
							className="flex rounded-lg active:bg-[#EDF2F7] bg-gray-50 justify-center py-[10px] gap-4 cursor-pointer select-none"
						>
							<PinIcon />
							<span className="text-blue-primary text-sm">
								{/* {isPinned ? t("Unpin") : t("Pin")} */}
								{t("Pin")}
							</span>
						</div>
						{userData?.accessToken.tokenable_id === massageData?.userId && (
							<div
								onClick={handleRemove}
								className="flex rounded-lg active:bg-[#EDF2F7] bg-gray-50 justify-center py-[10px] gap-4 cursor-pointer select-none"
							>
								<DeleteIcon />

								<span className="text-blue-primary text-sm">{t("Delete")}</span>
							</div>
						)}
					</div>
				</FadeBox>
			)}
		</>
	);
};

export default MoreMobileMenu;
