import InviteCard from "../../../../components/boxes/InviteCard";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Invitation } from "../../../../types";
import { GikiSkeleton } from "../../../../components/boxes/GikiSkeleton";
import { FadeBox } from "../../../../components/boxes/FadeBox";
import { useRecoilState } from "recoil";
import { invitationsNumberAtom } from "../../../../store/atoms";
import { NoInvitations } from "../../../../components/SVGs/NoInvitations";
import { useEffect } from "react";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "../../../../components/carousel/Embla";

interface NewInvitesBoxProps {}

const NewInvitesBox: React.FunctionComponent<NewInvitesBoxProps> = () => {
	const { t } = useTranslation();
	const [, setCount] = useRecoilState(invitationsNumberAtom);

	const { getUserInvitations } = useApiRequests();
	const { data, isLoading, isSuccess } = useQuery({
		queryKey: ["invitations"],
		queryFn: getUserInvitations,
		select: (res) => res?.data,
	});

	useEffect(() => {
		if (!isLoading && isSuccess) {
			setCount(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isSuccess]);

	return !isLoading && !!data.length ? (
		<div>
			<p className="pb-5 font-semibold lg:text-base text-xl text-blue-primary">
				{t("gikis.newInvites")}
			</p>
			{isLoading ? (
				<FadeBox>
					<Carousel className="w-full">
						<CarouselContent className="-ml-1">
							{Array.from({ length: 5 }).map((_, index) => (
								<CarouselItem
									key={index}
									className="pl-4 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
								>
									<GikiSkeleton />
								</CarouselItem>
							))}
						</CarouselContent>
					</Carousel>
				</FadeBox>
			) : (
				<>
					{data.length ? (
						<FadeBox>
							<Carousel className="w-full">
								<div className="flex items-center absolute right-0 -top-11 gap-5">
									<CarouselPrevious className="relative flex items-center justify-center border-2 translate-x-0 translate-y-0 rotate-0" />
									<CarouselNext className="relative flex items-center justify-center border-2 translate-x-0 translate-y-0 rotate-0" />
								</div>
								<CarouselContent className="-ml-1">
									{data?.map((invitation: Invitation) => (
										<CarouselItem
											key={invitation.id}
											className="pl-4 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
										>
											<InviteCard data={invitation} />
										</CarouselItem>
									))}
								</CarouselContent>
							</Carousel>
						</FadeBox>
					) : (
						<NoInvitations />
					)}
				</>
			)}
		</div>
	) : (
		<></>
	);
};

export default NewInvitesBox;
