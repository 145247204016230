import CategoriesButton from "../../../components/buttons/CategoriesButton";
import LeftMenu from "../../../components/menus/LeftMenu";
import group from "../../../assets/svgs/discover/group.svg";
import colorGroup from "../../../assets/svgs/discover/groupColored.svg";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Category } from "../../../types";
import { CustomWidget } from "../Gikis";
import { useRecoilValue } from "recoil";
import { sidebarState } from "../../../store/atoms";

interface GikisPanelProps {
	activeCategory: string;
	setActiveCategory: Dispatch<SetStateAction<string>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	isLoading: boolean;
	cData: CustomWidget;
}

const GikisPanel: React.FunctionComponent<GikisPanelProps> = ({
	activeCategory,
	setActiveCategory,
	data,
	isLoading,
	cData,
}) => {
	const { t } = useTranslation();
	const openSideBar = useRecoilValue(sidebarState);
	const [showCBanner, setShowCBanner] = useState(false);

	useEffect(() => {
		if (openSideBar) {
			setTimeout(() => {
				setShowCBanner(true);
			}, 500);
		} else {
			setShowCBanner(false);
		}
	}, [openSideBar]);

	return (
		<LeftMenu title={t("gikis.menu.title")} withOverflow={false}>
			<div className="flex flex-col justify-between sticky top-0 !h-[calc(100dvh-81px-40px)] gap-5">
				<div className="flex flex-col gap-[15px] overflow-y-scroll flex-1 !h-full">
					<CategoriesButton
						setCategory={setActiveCategory}
						icon={activeCategory === t("gikis.allGikis") ? group : colorGroup}
						isActive={activeCategory === t("gikis.allGikis")}
						requests={2}
						title={t("gikis.allGikis")}
						isInvite
					/>
					<div className="flex flex-col gap-[15px] w-full">
						{data?.data.length > 0 &&
							data?.data?.map((category: Category) => (
								<CategoriesButton
									id={category.id}
									key={category.id}
									isActive={activeCategory === category.name}
									setCategory={setActiveCategory}
									icon={category.image}
									title={category.name}
									fromApi
								/>
							))}
					</div>
				</div>
				{showCBanner &&
					(isLoading ? (
						<div className="w-full h-[150px] overflow-hidden rounded bg-slate-200 animate-pulse shadow-sm"></div>
					) : (
						cData?.title &&
						cData.title.length > 0 && (
							<div
								className="w-full rounded p-5 overflow-hidden"
								style={{
									background: cData.background_color,
									color: cData.text_color,
								}}
							>
								<h4 className="text-base font-bold leading-[19.2px] mb-[10px]">
									{cData.title}
								</h4>
								<p className="text-xs leading-[18px] mb-5">
									{cData.description}
								</p>
								<a
									href={cData.url}
									style={{ background: cData.button_color }}
									className="w-full rounded text-center flex-1 block py-4"
									target={"_blank"}
									rel="noreferrer"
								>
									{cData.url_text ? cData.url_text : "Link"}
								</a>
							</div>
						)
					))}
			</div>
		</LeftMenu>
	);
};

export default GikisPanel;

// import SuggestGikiButton from "components/buttons/SuggestGikiButton";
// import gikis from "assets/svgs/discover/gikis.svg";
// import { useDisclosure } from "@chakra-ui/react";
// import plus from "assets/svgs/discover/plus.svg";
// import { useRecoilValue } from "recoil";
// import { sidebarState } from "store/atoms";
// import { FadeBox } from "components/boxes/FadeBox";
// const sideBarOpen = useRecoilValue(sidebarState);
// const { isOpen, onClose, onOpen } = useDisclosure();
// eslint-disable-next-line no-lone-blocks
{
	/* <SuggestGikiButton isOpen={isOpen} onClose={onClose}>
  <Button
  onClick={onOpen}
  textTransform='capitalize'
    variant='heaveyBlue'
    className='!rounded'
  >
    {sideBarOpen && (
      <FadeBox className="w-full ">
        <div className='flex text-xs gap-3 line-clamp-1'>{t("gikis.menu.suggest")}</div>
      </FadeBox>
    )}
    <img src={plus} />
  </Button>
</SuggestGikiButton> */
}
