import i18n from "i18next";
import { italianStrings } from "./locale/it";
// import { italianStrings } from "./locale/en";
import { initReactI18next } from "react-i18next";

const resources = {
	en: {
		translation: italianStrings,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en",

		interpolation: {
			escapeValue: true, // react already safes from xss
		},
	});

export default i18n;
