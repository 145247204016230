import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Pagination } from "../../../../store/pagination.store";
import { FaSpinner } from "react-icons/fa";

export const BottomOffset = ({
	fetchPreviousPageMessages,
	pagination,
	isLoading,
}: {
	fetchPreviousPageMessages: () => void;
	pagination: Pagination;
	isLoading: boolean;
}) => {
	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView) {
			if (pagination.currentPage > 1 && pagination.currentPrev > 1) {
				fetchPreviousPageMessages();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	if (pagination.currentPrev === 1) {
		return <></>;
	}

	if (isLoading) {
		return (
			<div className="flex-1 w-full items-center justify-center flex">
				<FaSpinner className="animate-spin duration-400" />
			</div>
		);
	}

	return <div ref={ref} className="bottomOffset bg-[#EDF2F7] h-1 mt-5" />;
};
