import React from "react";

const Video = ({ className }: { className: string }) => {
  return (
    <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.3333 5.44444L19.392 2.91512C20.1308 2.54573 21 3.08295 21 3.90893V11.4244C21 12.2504 20.1308 12.7876 19.392 12.4182L14.3333 9.88889M3.22222 14.3333H12.1111C13.3384 14.3333 14.3333 13.3384 14.3333 12.1111V3.22222C14.3333 1.99492 13.3384 1 12.1111 1H3.22222C1.99492 1 1 1.99492 1 3.22222V12.1111C1 13.3384 1.99492 14.3333 3.22222 14.3333Z'
        className={className}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Video;
