import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useMediaQuery,
} from "@chakra-ui/react";
import FriendsSmall from "../../../../components/icons/FriendsSmall";
import { useRecoilState } from "recoil";
import TabsGroup from "../chatPannels/info/Tabs";
import chatInfo from "./store";
import { useTranslation } from "react-i18next";

const ChatInfo = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selected, setSelected] = useRecoilState(chatInfo);
	const { t } = useTranslation();

	const [isLgScreen] = useMediaQuery(["(min-width: 1024px)"]);

	const handleOpen = () => {
		if (isLgScreen) {
			setSelected("info");
			onClose();
		} else {
			setSelected("none");
			onOpen();
		}
	};

	return (
		<>
			<button
				onClick={handleOpen}
				className={`w-6 md:w-12 h-6 md:h-12 flex items-center justify-center rounded ${
					selected === "info" && "bg-blue-primary"
				}`}
			>
				<FriendsSmall
					className={
						selected === "info" ? "stroke-white" : "stroke-blue-primary"
					}
				/>
			</button>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent className="flex flex-col h-[80vh]">
					<ModalHeader className="!text-base text-blue-primary">
						{t("Info")}
					</ModalHeader>
					<ModalCloseButton
						color={"#CBD5E0"}
						className="border-[2px] rounded border-[#CBD5E0]"
					/>
					<ModalBody px={0} className="h-full overflow-hidden">
						<TabsGroup />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ChatInfo;
