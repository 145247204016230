const InputVariants = {
  'user-outline': {
    field: {
      border: '1px solid',
      borderColor: 'gray-20',
      _invalid: { border: '1px solid #C53030' },
      _focus: { border: '2px', borderColor: '#88bee9' },
      fontWeight: 400,
    },
  },
}
export default InputVariants
