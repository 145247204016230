import { Navigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from '../store/atoms'

function AlreadyLoggedInRedirect({ children }: any) {
   const [userData] = useRecoilState(userDataAtom)


   if (userData && userData.accessToken.abilities[0] === '*') {
      return <Navigate to='/' replace state={{ alreadyLoggedin: true }} />
   }

   return children
}

export default AlreadyLoggedInRedirect
