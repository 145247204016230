import { Avatar, Button } from '@chakra-ui/react'

interface NotificationBoxProps {
  type: string
  time: string
  text: string
  description?: string
  img: string
}

const NotificationBox: React.FunctionComponent<NotificationBoxProps> = ({
  img,
  text,
  time,
  type,
  description,
}) => {
  return (
    <div className='rounded-lg bg-gray-20'>
      <div className='p-4 flex flex-col gap-5'>
        <div className='flex items-center justify-between'>
          <span className='bg-white p-1 text-xs'> {type}</span>
          <span className='text-xs text-orange-primary'>{time}</span>
        </div>
        <div className='flex gap-2'>
          <Avatar src={img} size='sm' />
          <span className='text-blue-primary text-sm'>{text}</span>
        </div>
        <span className='text-xs italic text-blue-primary'>{description}</span>
        <div className='flex gap-[10px]'>
          <Button
            fontSize={{ base: '16px', lg: '14px' }}
            // onClick={onClose}
            bg='blue-primary-80'
            variant='primary'
            // disabled={suggestCommunity.isLoading}
          >
            Decline
          </Button>
          <Button
            // isLoading={suggestCommunity.isLoading}
            // disabled={suggestCommunity.isLoading}
            fontSize={{ base: '16px', lg: '14px' }}
            type='submit'
            bg='orange-primary'
            _hover={{ bg: 'blue-primary-80' }}
            variant='primary'
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotificationBox
