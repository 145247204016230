import { Spinner, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import AuthenticationLayout from "../AuthenticationLayout";

interface ActiveAccountProps {}

const ActiveAccount: React.FunctionComponent<ActiveAccountProps> = () => {
	const navigate = useNavigate();

	const toast = useToast();

	const { activateUser } = useApiRequests();

	const [searchParams] = useSearchParams();

	const { t } = useTranslation();

	const { mutate } = useMutation({
		mutationFn: activateUser,
		onSuccess: () => {
			navigate("/login");
			toast({
				title: t("Account activated successfully"),
				position: "top",
				status: "success",
				isClosable: true,
			});
		},
		onError: () => {
			toast({
				title: t("Account has not activated"),
				position: "top",
				status: "error",
			});
		},
	});

	useEffect(() => {
		mutate({ code: searchParams.get("code") || "" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthenticationLayout>
			<div className="flex h-[50vh] w-full items-center justify-center">
				<Spinner />
			</div>
		</AuthenticationLayout>
	);
};

export default ActiveAccount;
