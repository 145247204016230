import { debounce } from "lodash";
import { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import { Pagination } from "../../../../store/pagination.store";

export const TopOffset = ({
	fetchNextPageMessages,
	pagination,
	isLoading,
}: {
	fetchNextPageMessages: () => void;
	pagination: Pagination;
	isLoading: boolean;
}) => {
	const { ref, inView } = useInView();

	useEffect(() => {
		const fetchNextPageMessagesThrottled = debounce(fetchNextPageMessages, 300);

		if (inView) {
			if (pagination.currentPage < pagination.totalPages && !isLoading) {
				fetchNextPageMessagesThrottled();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	if (pagination.currentNext === pagination.totalPages) {
		return <></>;
	}

	if (isLoading) {
		return (
			<div className="flex-1 w-full items-center justify-center flex mt-4">
				<FaSpinner className="animate-spin duration-400" />
			</div>
		);
	}

	return <div ref={ref} className="topOffset bg-[#EDF2F7] h-1 mt-2" />;
};
