import { Spinner, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MembersBox from "../../../../../../components/boxes/MembersBox";
import { useApiRequests } from "../../../../../../configs/API/ApiRequests";
import { useRecoilState } from "recoil";
import {
	isSelectedChat,
	selectedChat,
	userSelected,
} from "../../../../../../store/atoms";
import generalChatInfo from "../../info.atom";
import InviteNewUser from "../../userInvite/InviteNewUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InviteNewUserOutsideGiki from "../../userInvite/InviteMemebersOutsideGiki";

const Members = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { cancelOrExitCommunity } = useApiRequests();
	const [generalInfo, setGeneralInfo] = useRecoilState(generalChatInfo);
	const toast = useToast();
	const [, isSelected] = useRecoilState(isSelectedChat);
	const [chat, setSelectedChat] = useRecoilState(selectedChat);
	const [, setSelectedUser] = useRecoilState(userSelected);

	const leaveGiki = useMutation({
		mutationFn: () => cancelOrExitCommunity(chat.id),
		mutationKey: ["exitCommunity", chat.id],
	});

	const queryClient = useQueryClient();

	const handleLeaveGikiMutation = () => {
		leaveGiki
			.mutateAsync()
			.then(async () => {
				isSelected(false);
				setSelectedChat({ ...chat, id: "" });
				setGeneralInfo({
					isAdmin: false,
					members: [],
					refetchChats: !generalInfo.refetchChats,
					allowInvites: false,
					createdOn: "",
				});
				await queryClient.invalidateQueries({
					queryKey: ["chats", "gikiDetails", "communityInfo-more"],
				});
				navigate("/chat");
			})
			.catch(() => {
				toast({
					title: t("something went wrong"),
					status: "error",
				});
			});
	};

	return (
		<div className="px-3 h-full flex flex-col gap-4">
			<div>
				{generalInfo.members.length > 0 &&
					generalInfo.members.map(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(users: any, index: number) =>
							users.is_admin === 1 && (
								<button
									onClick={() => {
										setSelectedUser(users);
										navigate("/members");
									}}
									key={`members_panel_${index}`}
									className="w-full"
								>
									<MembersBox users={users} isAdmin={true} />
								</button>
							)
					)}
			</div>
			<div className="flex-1 h-full overflow-y-auto">
				{generalInfo.members.length > 0 &&
					generalInfo.members.map(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(users: any, index: number) =>
							users.is_admin === 0 && (
								<button
									onClick={() => {
										setSelectedUser(users);
										navigate("/members");
									}}
									key={`members_panel_${index}`}
									className="w-full"
								>
									<MembersBox users={users} key={`members_panel_${index}`} />
								</button>
							)
					)}
			</div>
			<div className="flex flex-col gap-[10px] px-1">
				{generalInfo.allowInvites &&
					(chat.type === 0 || generalInfo.isAdmin) && (
						<InviteNewUser id={chat.id} />
					)}
				{generalInfo.allowInvites &&
					(chat.type === 0 || generalInfo.isAdmin) && (
						<InviteNewUserOutsideGiki id={chat.id} />
					)}
				{!generalInfo.isAdmin && (
					<button
						disabled={leaveGiki.isPending}
						className="w-full text-center bg-text-gray hover:bg-gray-300 transition-all duration-300 text-white text-xs leading-[14.4px] p-4 rounded flex items-center gap-4 justify-center"
						onClick={handleLeaveGikiMutation}
					>
						{t("Leave this Giki")}{" "}
						{leaveGiki.isPending && <Spinner size={"xs"} />}
					</button>
				)}
			</div>
		</div>
	);
};

export default Members;
