import { RouterProvider } from "react-router-dom";

import useRoutes from "./Routes";

interface AppRoutesProps {}

const AppRoutes: React.FunctionComponent<AppRoutesProps> = () => {
  const routes = useRoutes();

  return <RouterProvider router={routes} />;
};

export default AppRoutes;
