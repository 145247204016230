/* eslint-disable jsx-a11y/alt-text */
import {
	Avatar,
	Box,
	Button,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Textarea,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import camera from "../../assets/svgs/discover/popup/camera.svg";
import { AiFillDelete } from "react-icons/ai";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Category } from "../../types";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { selectedChat } from "../../store/atoms";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

interface EditCommunityAdminProps {}

const EditCommunityAdmin: React.FunctionComponent<
	EditCommunityAdminProps
> = () => {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedFile, setSelectedFile] = useState<Blob | null>();
	const [selectedCover, setSelectedCover] = useState<Blob | null>();
	const fileRef = useRef<HTMLInputElement>(null);
	const coverRef = useRef<HTMLInputElement>(null);
	const { getCategories, updateCommunity, getCommunityDetails } =
		useApiRequests();
	const { register, handleSubmit, reset, watch } = useForm();
	const { data, isLoading } = useQuery({
		queryKey: ["categories"],
		queryFn: getCategories,
	});
	const [chat, setChat] = useRecoilState(selectedChat);
	const updateCommunityMutation = useMutation({
		mutationFn: updateCommunity,
	});
	const toast = useToast();
	const queryClient = useQueryClient();

	const communityData = useQuery({
		queryKey: ["community-details", chat.id],
		queryFn: () => getCommunityDetails(chat.id),
		select: (data) => data.data.data,
	});

	const handleSelectFile = (e: any) => {
		const file = e.target.files[0];
		setSelectedFile(file);
	};

	const handleSelectCover = (e: any) => {
		const file = e.target.files[0];
		setSelectedCover(file);
	};

	const onSubmit = (data: any) => {
		const formData = new FormData();
		formData.append("name", data.name);
		formData.append("short_description", data.short_description);
		formData.append("category_id", data.category);
		formData.append("type", data.type);
		formData.append("image", selectedFile as Blob);
		formData.append("cover_image", selectedCover as Blob);

		updateCommunityMutation.mutate(
			{ id: chat.id, data: formData },
			{
				onSuccess: async (res) => {
					communityData.refetch();
					queryClient.invalidateQueries();
					setChat((prev) => ({
						...prev,
						id: chat.id,
						name: data.name,
						type: data.type,
						short_description: data.short_description,
						cover_image: res.data.cover_image,
						image: res.data.image,
					}));
					reset();
					setSelectedFile(null);
					setSelectedCover(null);
					onClose();
					toast({
						title: t("Success"),
						description: t("Your changes has been applied"),
						status: "success",
						duration: 4000,
						isClosable: true,
					});
				},
				onError: (error: any) => {
					const errorsArr = [];
					if (typeof error.response.data.error !== "string") {
						const errors = error.response.data.error;
						for (const key in errors) {
							// Access the array using the key
							const array = errors[key];
							const firstValue = array[0];
							errorsArr.push(firstValue);
						}
					} else {
						errorsArr.push(error.response.data.error);
					}
					toast({
						title: t("Error"),
						description: errorsArr.join(", "),
						status: "error",
						duration: 4000,
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<>
			<button
				onClick={onOpen}
				className="w-full text-center bg-text-gray hover:bg-gray-300 transition-all duration-300 text-white text-xs leading-[14.4px] p-4 rounded"
			>
				{t("Edit Giki Profile Details")}
			</button>
			<Modal
				isCentered
				scrollBehavior="inside"
				size="xl"
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody px="30px">
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="flex flex-col gap-4 px-4"
						>
							<div className="w-full relative h-36 mb-10">
								<input
									{...register("cover_image")}
									onChange={handleSelectCover}
									ref={coverRef}
									type="file"
									hidden
								/>

								{selectedCover ? (
									<>
										<Image
											opacity={0.6}
											w="full"
											h="full"
											src={URL.createObjectURL(selectedCover)}
											rounded="8px"
											borderColor="gray-20"
										/>
										{selectedCover && (
											<div className="absolute -right-2 bottom-0">
												<Box
													rounded="full"
													className="cursor-pointer flex justify-center p-1 items-center w-6 h-6"
													bg="gray.300"
													onClick={() => coverRef?.current?.click()}
												>
													<AiFillDelete
														onClick={() => setSelectedCover(null)}
														className="text-xl text-red-600"
													/>
												</Box>
											</div>
										)}
									</>
								) : (
									<>
										<div
											style={{
												backgroundImage: `url(${process.env.REACT_APP_MEDIA_URL}${chat.cover_image})`,
											}}
											className="w-full h-full rounded-lg border border-gray-20 flex justify-center items-center relative bg-cover bg-no-repeat"
										>
											<div className="bg-light-gray absolute top-2 right-2 rounded-lg ">
												<label
													className="w-full"
													htmlFor="contained-button-file"
												>
													{!selectedCover && (
														<Box
															rounded="8px"
															className="cursor-pointer bg-white rounded-full p-1 flex flex-col justify-center items-center"
															onClick={() => coverRef?.current?.click()}
														>
															<img className="h-5 w-5" src={camera} />
														</Box>
													)}
												</label>
											</div>
										</div>
									</>
								)}

								<div className="absolute bottom-[-20%] left-[5%]">
									<input
										{...register("image")}
										onChange={handleSelectFile}
										ref={fileRef}
										type="file"
										hidden
									/>

									{selectedFile ? (
										<>
											<Avatar
												opacity={0.6}
												w="100px"
												h="100px"
												src={URL.createObjectURL(selectedFile)}
												rounded="8px"
												borderColor="gray-20"
											/>
											{selectedFile && (
												<div className="absolute -right-2 bottom-0">
													<Box
														rounded="full"
														className="cursor-pointer flex justify-center p-1 items-center w-6 h-6"
														bg="gray.300"
														onClick={() => fileRef?.current?.click()}
													>
														<AiFillDelete
															onClick={() => setSelectedFile(null)}
															className="text-xl text-red-600"
														/>
													</Box>
												</div>
											)}
										</>
									) : (
										<>
											<div
												style={{
													backgroundImage: `url(${process.env.REACT_APP_MEDIA_URL}${communityData.data?.image})`,
												}}
												className="w-[100px] h-[100px] rounded-lg border  border-gray-20 flex justify-center items-center bg-cover bg-no-repeat"
											>
												<div className="bg-light-gray w-[52px] h-[52px] rounded-lg "></div>
											</div>
											<label
												className="w-full absolute right-1/2 bottom-1/2 translate-x-1/2 translate-y-1/2 "
												htmlFor="contained-button-file"
											>
												{!selectedFile && (
													<Box
														rounded="8px"
														className="cursor-pointer flex flex-col justify-center items-center"
														onClick={() => fileRef?.current?.click()}
													>
														<img src={camera} />
														{chat.image === "" && (
															<>
																<p className="text-[10px] text-white">
																	{t("upload image")}
																</p>
															</>
														)}
													</Box>
												)}
											</label>
										</>
									)}
								</div>
							</div>

							<Input
								{...register("name", { required: true })}
								variant="user-outline"
								className=""
								defaultValue={chat.name ? chat.name : ""}
								required
							/>

							<Textarea
								{...register("short_description", { required: true })}
								variant="user-outline"
								resize="none"
								className="border border-gray-20"
								defaultValue={communityData.data?.short_description}
								required
							/>

							<Select
								disabled={isLoading}
								{...register("category")}
								border="solid 2px #bbbbbb59"
								borderColor={watch("category") === "" ? "red.400" : "#bbbbbb59"}
								variant="register-outline"
								className=" placeholder:text-grap-20"
								required
								defaultValue={communityData.data?.category.id}
							>
								<option value="">{t("Select a category")}</option>
								{data?.data?.map((option: Category) => (
									<option key={option.id} value={option.id}>
										{option.name}
									</option>
								))}
							</Select>

							<Select
								{...register("type")}
								border="solid 2px #bbbbbb59"
								borderColor={watch("type") === "" ? "red.400" : "#bbbbbb59"}
								variant="register-outline"
								className=" placeholder:text-grap-20"
								required
								defaultValue={communityData.data?.type}
							>
								<option value="">{t("Type of Giki")}</option>
								<option value="0">{t("Public")}</option>
								<option value="1">{t("Private")}</option>
							</Select>

							<div className="flex gap-[10px] pb-3">
								<Button
									fontSize={{ base: "16px", lg: "14px" }}
									onClick={onClose}
									bg="blue-primary-50"
									_hover={{ bg: "blue-primary-80" }}
									variant="primary"
									disabled={updateCommunityMutation.isPending}
								>
									{t("Cancel")}
								</Button>
								<Button
									isLoading={updateCommunityMutation.isPending}
									disabled={updateCommunityMutation.isPending}
									fontSize={{ base: "16px", lg: "14px" }}
									type="submit"
									bg="orange-primary"
									_hover={{ bg: "blue-primary-80" }}
									variant="primary"
								>
									{t("Save Changes")}
								</Button>
							</div>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</> //   disabled={suggestCommunity.isLoading}
	);
};

export default EditCommunityAdmin;
