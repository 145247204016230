/* eslint-disable jsx-a11y/alt-text */
import { NavLink } from "react-router-dom";
import search from "../../assets/svgs/sidebar/mobile-search.svg";
import { Avatar, Button, IconButton, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileMenuButton from "../../components/buttons/MobileMenuButton";
import settings from "../../assets/svgs/mobile-sidebar/settings.svg";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import {
	superSearchAtom,
	topBarMobileMenuState,
	userSelected,
} from "../../store/atoms";
import SuggestGikiButton from "../../components/buttons/SuggestGikiButton";
import Friends from "../../components/icons/Friends";
import { Divide as Hamburger } from "hamburger-react";

interface MobileTopBarProps {}

const MobileTopBar: React.FunctionComponent<MobileTopBarProps> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [, setTopBarState] = useRecoilState(topBarMobileMenuState);
	const { t } = useTranslation("");
	const [, setselectedUser] = useRecoilState(userSelected);
	const [isSuggestGikiOpen, setIsSuggestGikiOpen] = useState(false);
	const [, setSuperSearch] = useRecoilState(superSearchAtom);

	useEffect(() => {
		return () => setTopBarState(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="sticky top-0 h-[75px] z-50 md:hidden flex justify-between items-center bg-blue-primary py-3 px-6">
			<NavLink to="/">
				<Avatar
					src={
						localStorage.getItem("logo")
							? `${process.env.REACT_APP_MEDIA_URL}${localStorage.getItem(
									"logo"
							  )}`
							: require("../../assets/logo.png")
					}
					name="logo"
					rounded={4}
					className="!w-10 !h-10"
				/>
			</NavLink>

			<div className="flex gap-4 items-center">
				<IconButton
					_active={{ bg: "blue-primary-80" }}
					variant={"heaveyBlue"}
					aria-label="search"
					className=""
					onClick={() => {
						setSuperSearch((prev: any) => !prev);
					}}
					icon={
						<>
							<img src={search} />
						</>
					}
				/>
				{/* <IconButton
					_active={{ bg: "blue-primary-80" }}
					variant={"heaveyBlue"}
					aria-label="notification"
					onClick={() => {
						setNotification((prev: any) => !prev);
					}}
					className="flex items-center justify-center self-stretch"
					icon={
						<>
							<img src={notification} />
						</>
					}
				/> */}
				<div
					className={`w-12 h-12 flex items-center justify-center rounded ${
						isOpen && ""
					}`}
				>
					<Hamburger
						toggled={isOpen}
						size={20}
						color={"white"}
						toggle={() => {
							if (isOpen) {
								onClose();
								setTopBarState(false);
							} else {
								onOpen();
								setTopBarState(true);
							}
						}}
					/>
				</div>

				<div
					className={`absolute transition duration-500 ease-in-out bg-black mix-blend-multiply h-dvh left-0 top-[75px] ${
						isOpen ? "opacity-70 visible" : "opacity-0 invisible"
					}`}
				/>

				<div
					className={`absolute flex flex-col gap-4 justify-center transition duration-500 ease-in-out top-[100px] right-[6vw] ${
						isOpen ? "opacity-100 visible" : "opacity-0 invisible"
					}`}
				>
					<SuggestGikiButton
						onClose={() => {
							setIsSuggestGikiOpen(false);
						}}
						isOpen={isSuggestGikiOpen}
					>
						<Button
							textTransform="capitalize"
							justifyContent="flex-end"
							className="flex gap-[10px] translate-x-1"
							variant="mobileMenu"
							color="black"
							fontSize="16px"
							bg="white"
							pr="5px"
							pl="15px"
							onClick={() => {
								setIsSuggestGikiOpen(true);
							}}
						>
							{t("gikis.menu.suggest")}
							<IconButton
								_active={{ bg: "blue-primary-80" }}
								variant={"heaveyBlue"}
								aria-label="hamburger"
								bg="white"
								width="50px"
								height="50px"
								fontSize="18px"
								icon={
									<svg
										width="50"
										height="50"
										viewBox="0 0 46 46"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											x="0.844727"
											y="0.5"
											width="45"
											height="45"
											rx="8"
											fill="#FF8C1E"
										/>
										<path
											d="M23.3451 17.6667V28.3333M28.6784 23L18.0117 23"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								}
							/>
						</Button>
					</SuggestGikiButton>

					<MobileMenuButton
						func={() => {
							setselectedUser({
								first_name: "",
								last_name: "",
								id: "",
								photo: "",
								profession: "",
							});
							setTopBarState(false);
							onClose();
						}}
						link="/members"
						title="Members"
						IconElement={
							<span className="!stroke-[#323E53]">
								<Friends />
							</span>
						}
					/>
					<MobileMenuButton
						func={() => {
							onClose();
							setTopBarState(false);
						}}
						link="/profile"
						title="Settings"
						icon={settings}
					/>
				</div>
			</div>
		</div>
	);
};

export default MobileTopBar;
