import React, { useState } from 'react'
import {
  FormControl,
  Tooltip,
  FormLabel,
  Input,
  InputRightElement,
  Button,
  InputGroup,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

export const RegisterInput = ({
  disabled,
  register,
  name,
  isDate,
  isPassword,
  defaultValue = '',
  infoText = '',
  errors,
  isRequired = true,
}: {
  disabled?: boolean
  isRequired?: boolean
  infoText?: string
  name: any
  isDate?: boolean
  isPassword?: boolean
  defaultValue?: string
  register: any
  errors: any
}) => {
  const { t } = useTranslation()
  const errorMessage = errors[`${name}`]?.message
  const [showPassword, setShowPassword] = useState(false)
  return (
    <FormControl isRequired={isRequired}>
      <div className='flex justify-between'>
        <FormLabel variant='register-outline' mb={'12px'}>
          {t(`register.registerForm.${name}`)}
        </FormLabel>
        {infoText && (
          <Tooltip label={infoText} bg='white' textColor={'black'}>
            <span className='cursor-pointer'>
              <AiOutlineInfoCircle className='text-2xl' />
            </span>
          </Tooltip>
        )}
      </div>
      <InputGroup>
        <Input
          defaultValue={defaultValue}
          type={
            (isDate && 'date') ||
            (isPassword && !showPassword && 'password') ||
            (!isDate && !isPassword && '')
          }
          {...register(`${name}`)}
          border='solid 2px #bbbbbb59'
          disabled={disabled}
          fontSize='12px'
          placeholder={t(`register.registerForm.${name}`)}
          variant='user-outline'
          className='placeholder:text-grap-20'
          isInvalid={errorMessage ? true : false}
        />

        {isPassword && (
          <InputRightElement h='full'>
            <Button
              bg={'transparent'}
              size={'sm'}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              {showPassword ? (
                <BsEye className='text-3xl text-gray' />
              ) : (
                <BsEyeSlash className='text-3xl text-gray' />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {errorMessage && <div className='text-[#C53030] text-xs mt-2'>{errorMessage}</div>}
    </FormControl>
  )
}
