import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { FadeBox } from "../../../../components/boxes/FadeBox";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { FunctionComponent, memo, useState } from "react";
import { useRecoilState } from "recoil";
import { isMeeting, meetingAtom } from "../../../../store/atoms";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConferenceType } from "../../conference.atom";

interface MeetBoxProps {
	isAdmin?: boolean;
	isEnrolled?: boolean;
	data: ConferenceType;
	is_live: boolean;
}

const MeetBox: FunctionComponent<MeetBoxProps> = ({
	isAdmin,
	isEnrolled,
	data,
	is_live,
}) => {
	const { id } = useParams();
	const { t } = useTranslation();

	const { enrollConference, startMeeting, getMeetingInfo } = useApiRequests();
	const toast = useToast();
	const [Enrolled, setIsEnrolled] = useState(isEnrolled);
	const [, setMeeting] = useRecoilState(meetingAtom);
	const [, setShowBox] = useRecoilState(isMeeting);

	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: () => enrollConference(data?.id || ""),
		onSuccess: () => {
			setIsEnrolled(true);
			toast({
				title: t("Enrolled Successfully"),
				status: "success",
				duration: 3000,
				isClosable: true,
			});
		},
	});

	const startMeetingHandler = useMutation({
		mutationFn: () =>
			startMeeting({ conferenceId: data?.id || "", id: id as string }),
		onSuccess: (data) => {
			setMeeting(data.data.data.meeting);
			setShowBox(false);
		},
	});

	const meetingInfo = useMutation({
		mutationFn: () => getMeetingInfo(data?.id || ""),
		onSuccess: (data) => {
			setMeeting(data.data.data.MediaPlacement);
			setShowBox(false);
		},
	});

	const handleClick = () => {
		if (isAdmin && !is_live) {
			startMeetingHandler.mutate();
		} else {
			if (is_live) {
				meetingInfo
					.mutateAsync()
					.then((data) => {
						if (data.data.data.MediaPlacement) {
							const meetingData = {
								MediaPlacement: JSON.parse(data.data.data.MediaPlacement),
							};
							setMeeting(meetingData);
							setShowBox(false);
						}
					})
					.catch(() => {})
					.finally(() => {});
			} else {
				!Enrolled && mutate();
			}
		}
	};

	return (
		<FadeBox className="flex p-3 justify-between items-center bg-blue-primary rounded-lg">
			<p className="font-semibold text-sm text-white">
				{t("The conference created by")} {data?.created_by} - {t("Live at")}{" "}
				{data?.label_start_date || ""}
			</p>
			<Button
				fontSize={{ base: "16px", lg: "14px" }}
				type="submit"
				bg="orange-primary"
				_hover={{ bg: "blue-primary-80" }}
				variant="primary"
				width="180px"
				_disabled={{ bg: "orange-primary-80", cursor: "not-allowed" }}
				isLoading={isLoading || startMeetingHandler.isPending}
				onClick={() => {
					handleClick();
				}}
				isDisabled={Enrolled && !isAdmin && !is_live}
			>
				{is_live
					? t("Join Meeting")
					: isAdmin
					? t("Start Meeting")
					: Enrolled
					? t("Enrolled")
					: t("Enroll")}
			</Button>
		</FadeBox>
	);
};

export const MemoizedMeetBox = memo(MeetBox);
