const LabelsVariants = {
  'user-outline': {
    fontSize: 'sm',
    margin: '0',
    color: 'text-gray',
    fontWeight: '600',
  },
  'register-outline': {
    fontSize: 'sm',
    color: 'text-gray',
    margin: '0',
    fontWeight: 600,
  },
}

export default LabelsVariants
