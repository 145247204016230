import { Avatar, useDisclosure } from "@chakra-ui/react";
import JoinButton from "../../components/buttons/JoinButton";
import React from "react";
import GikiModal from "../../components/modals/GikiModal";
import { Giki } from "../../types";
import { useGetRandomCover } from "../../hooks/useGetRandomCover";
import Start from "../../components/icons/Start";
import { useNavigate } from "react-router-dom";
import { isSelectedChat } from "../../store/atoms";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

interface GikiCardProps {
	coverImage: string;
	img: string;
	data: Giki;
	className?: string;
}

const GikiCard: React.FunctionComponent<GikiCardProps> = ({
	data,
	className,
}) => {
	const navigate = useNavigate();
	const [, setIsSelected] = useRecoilState(isSelectedChat);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();

	const { randomImage } = useGetRandomCover();
	if (!data) return <></>;
	return (
		<div
			className={`bg-gray-50 rounded hover:bg-[#edf2f7] p-4 flex flex-col gap-[10px] border-[1px] border-[#EDF2F7] !h-full !self-stretch ${
				data.is_joined === 1 && "cursor-pointer"
			} ${className}`}
			onClick={() => {
				if (data.is_joined === 1) {
					setIsSelected(true);
					navigate(`/chat/${data.id}`, { state: { ...data } });
				}
			}}
		>
			<div className="flex justify-between">
				<Avatar
					className="aspect-square h-[58px] rounded bg-cover bg-center cursor-pointer"
					rounded={4}
					src={`${process.env.REACT_APP_MEDIA_URL}${data.image}`}
					name={data.name}
				/>

				<div className="self-stretch flex flex-col justify-between">
					<button className="rounded bg-[#FF8C1E] p-[5px] self-end">
						<Start />
					</button>
					<span className="text-[10px] leading-[15px]">
						{data.users_count} {t("Members")}{" "}
					</span>
				</div>
			</div>
			<div className="flex flex-col gap-[10px] flex-1 self-stretch justify-between">
				<div className="flex flex-col gap-[10px]">
					<p className="text-blue-primary font-bold leading-[16.8px] text-sm line-clamp-2">
						{data.name}
					</p>
					{
						<p className="lg:text-xs text-xs min-h-[50px] !line-clamp-3">
							{data?.short_description}{" "}
						</p>
					}
				</div>
				<div>
					{data.is_joined !== 1 && <JoinButton data={data} type={0} />}
					{/* <JoinButton data={data} type={data.is_joined} /> */}
				</div>
			</div>
			{isOpen && (
				<GikiModal
					id={data.id}
					isOpen={isOpen}
					onClose={onClose}
					onOpen={onOpen}
					coverImage={randomImage}
				/>
			)}
		</div>
	);
};

export default GikiCard;
