import ReactDOM from "react-dom/client";
import "./index.css";
import "keen-slider/keen-slider.min.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { setColorsInTheme } from "./configs/ChakraTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import "./i18n"

(async () => {
	const updatedTheme = await setColorsInTheme();
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
		<>
			<ChakraProvider theme={updatedTheme}>
				<QueryClientProvider client={queryClient}>
					<RecoilRoot>
						<App />
					</RecoilRoot>
				</QueryClientProvider>
			</ChakraProvider>
		</>
	);
})();
