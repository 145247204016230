import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const gikiThemed = definePartsStyle(() => {
  return {
    tablist: {
      display: "flex",
      alignItems: "center",
    },
    tab: {
      borderColor: 'transparent',
      bg: "transparent",
      borderRadius: "4px",
      color: "blue-primary-50",
      width: "100%",
      lineHeight: "14.4px",
      fontWeight: 400,
      fontSize: 12,
      height: 26,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      _selected: {
        bg: "gray-20",
        color: "blue-primary",
        border: "0px",
        py: "0",
      },
    },
    tabpanel: {
      pt: "20px",
      px: 0,
      overflowY: "auto"
    },
  }
})

const variants = {
  gikiThemed,
}


// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants })