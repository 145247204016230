import { FormControl, Tooltip, FormLabel, Select } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { TRegisterForm } from "../../types";

export const RegisterSelect = ({
	register,
	name,
	errors,
	options = [],
	required = true,
	selectId = false,
	autoSelectFirstOption = false,
	defaultValue = "",
	infoText = "",
}: {
	infoText?: string;
	defaultValue?: string;
	errorMessage?: string;
	name: keyof TRegisterForm;
	register: any;
	required?: boolean;
	selectId?: boolean;
	autoSelectFirstOption?: boolean;
	options?: { name: string; id: number | string }[];
	errors: any;
}) => {
	const { t } = useTranslation();

	const errorMessage = errors[`${name}`]?.message;

	return (
		<FormControl isRequired={required}>
			<div className="flex justify-between">
				<FormLabel variant="register-outline" mb="12px" fontSize={"14px"}>
					{t(`register.registerForm.${name}`)}
				</FormLabel>
				{infoText && (
					<Tooltip label={infoText} bg="white" textColor={"black"}>
						<span className="cursor-pointer">
							<AiOutlineInfoCircle className="text-2xl" />
						</span>
					</Tooltip>
				)}
			</div>
			<Select
				{...register(`${name}`)}
				defaultValue={autoSelectFirstOption ? options[0].name : defaultValue}
				disabled={options.length === 0}
				border="solid 2px #bbbbbb59"
				borderColor={errorMessage ? "red.400" : "#bbbbbb59"}
				variant="register-outline"
				fontSize="12px"
				className=" placeholder:text-grap-20"
			>
				<option value=""> {t(`register.registerForm.${name}`)}</option>
				{options?.map((option) => (
					<option key={option.id} value={selectId ? option.id : option.name}>
						{option.name}
					</option>
				))}
			</Select>
			{errorMessage && (
				<div className="p-1 pl-2 mt-2 rounded text-sm text-white bg-primary">
					{errorMessage}
				</div>
			)}
		</FormControl>
	);
};
