/* eslint-disable react/no-children-prop */
import { Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai'
import { IoArrowBack } from 'react-icons/io5'
interface ActionInputProps {
  input: string
  setInput: Dispatch<SetStateAction<string>>
  isLoading: boolean
  placeholder?: string
}

const ActionInput: React.FunctionComponent<ActionInputProps> = ({
  input,
  setInput,
  isLoading,
  placeholder,
}) => {
  return (
    <InputGroup>
      <InputLeftElement
        children={
          isLoading ? (
            <Spinner color='black' size='sm' />
          ) : input ? (
            <IoArrowBack
              cursor='pointer'
              onClick={() => {
                setInput('')
              }}
              className='text-text-gray'
            />
          ) : (
            <AiOutlineSearch className='text-text-gray' />
          )
        }
      />
      <Input
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
        }}
        color='blue-primary'
        placeholder={placeholder ? placeholder : ''}
        variant='user-outline'
      />
      {input && (
        <InputRightElement
          children={
            <AiOutlineClose
              onClick={() => {
                setInput('')
              }}
              cursor='pointer'
              className='text-text-gray'
            />
          }
        />
      )}
    </InputGroup>
  )
}

export default ActionInput
