import { useTranslation } from "react-i18next";
import { FadeBox } from "../../components/boxes/FadeBox";

export const NoInvitations = () => {
	const { t } = useTranslation();

	return (
		<FadeBox className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[222px] w-full relative gap-5 px-5 py-10 rounded-lg bg-[#f7fafc]">
			<svg
				width={216}
				height={87}
				viewBox="0 0 216 87"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="flex-grow-0 flex-shrink-0 w-[216px] h-[83px]"
				preserveAspectRatio="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M46 79H137C137.515 79 138.017 78.9444 138.5 78.8389C138.983 78.9444 139.485 79 140 79H192C195.866 79 199 75.866 199 72C199 68.134 195.866 65 192 65H186C182.134 65 179 61.866 179 58C179 54.134 182.134 51 186 51H205C208.866 51 212 47.866 212 44C212 40.134 208.866 37 205 37H183C186.866 37 190 33.866 190 30C190 26.134 186.866 23 183 23H119C122.866 23 126 19.866 126 16C126 12.134 122.866 9 119 9H62C58.134 9 55 12.134 55 16C55 19.866 58.134 23 62 23H22C18.134 23 15 26.134 15 30C15 33.866 18.134 37 22 37H47C50.866 37 54 40.134 54 44C54 47.866 50.866 51 47 51H7C3.13401 51 0 54.134 0 58C0 61.866 3.13401 65 7 65H46C42.134 65 39 68.134 39 72C39 75.866 42.134 79 46 79ZM209 79C212.866 79 216 75.866 216 72C216 68.134 212.866 65 209 65C205.134 65 202 68.134 202 72C202 75.866 205.134 79 209 79Z"
					fill="#EDF2F7"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M75 85C62.8497 85 53 75.3741 53 63.5C53 51.6259 62.8497 42 75 42C75.5167 42 76.0292 42.0174 76.537 42.0517C76.1842 40.0878 76 38.0654 76 36C76 17.2223 91.2223 2 110 2C124.991 2 137.716 11.702 142.239 25.1695C143.31 25.0575 144.398 25 145.5 25C162.345 25 176 38.4315 176 55C176 70.7406 163.675 83.7273 148 84.9782V85H91.5078M86.996 85H80.0314H86.996Z"
					fill="white"
				/>
				<path
					d="M75 86.25C75.6904 86.25 76.25 85.6904 76.25 85C76.25 84.3096 75.6904 83.75 75 83.75V86.25ZM76.537 42.0517L76.4529 43.2988L78.0504 43.4066L77.7673 41.8306L76.537 42.0517ZM142.239 25.1695L141.054 25.5674L141.372 26.5169L142.369 26.4127L142.239 25.1695ZM148 84.9782L147.901 83.7321L146.75 83.824V84.9782H148ZM148 85V86.25H149.25V85H148ZM91.5078 83.75C90.8175 83.75 90.2578 84.3096 90.2578 85C90.2578 85.6904 90.8175 86.25 91.5078 86.25V83.75ZM86.996 86.25C87.6863 86.25 88.246 85.6904 88.246 85C88.246 84.3096 87.6863 83.75 86.996 83.75V86.25ZM80.0314 83.75C79.341 83.75 78.7814 84.3096 78.7814 85C78.7814 85.6904 79.341 86.25 80.0314 86.25V83.75ZM75 83.75C63.5128 83.75 54.25 74.6568 54.25 63.5H51.75C51.75 76.0914 62.1866 86.25 75 86.25V83.75ZM54.25 63.5C54.25 52.3432 63.5128 43.25 75 43.25V40.75C62.1866 40.75 51.75 50.9086 51.75 63.5H54.25ZM75 43.25C75.4886 43.25 75.9731 43.2665 76.4529 43.2988L76.6211 40.8045C76.0853 40.7684 75.5448 40.75 75 40.75V43.25ZM77.7673 41.8306C77.4275 39.9394 77.25 37.991 77.25 36H74.75C74.75 38.1399 74.9408 40.2362 75.3067 42.2727L77.7673 41.8306ZM77.25 36C77.25 17.9127 91.9127 3.25 110 3.25V0.75C90.532 0.75 74.75 16.532 74.75 36H77.25ZM110 3.25C124.438 3.25 136.697 12.5936 141.054 25.5674L143.424 24.7716C138.735 10.8104 125.544 0.75 110 0.75V3.25ZM142.369 26.4127C143.397 26.3052 144.442 26.25 145.5 26.25V23.75C144.355 23.75 143.223 23.8097 142.109 23.9263L142.369 26.4127ZM145.5 26.25C161.674 26.25 174.75 39.1412 174.75 55H177.25C177.25 37.7217 163.015 23.75 145.5 23.75V26.25ZM174.75 55C174.75 70.0705 162.945 82.5317 147.901 83.7321L148.099 86.2242C164.406 84.923 177.25 71.4108 177.25 55H174.75ZM146.75 84.9782V85H149.25V84.9782H146.75ZM148 83.75H91.5078V86.25H148V83.75ZM86.996 83.75H80.0314V86.25H86.996V83.75Z"
					fill="#989EA9"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M99.6123 9.34263C99.6123 41.5657 128.633 68.118 166 71.7029C161.317 77.4611 154.086 81.3675 145.847 81.9813V82H78.7431C70.6013 82 57 78.5697 57 63.5483C57 48.527 67.5742 45.0967 78.7431 45.0967C79.218 45.0967 79.6891 45.1116 80.1559 45.141C79.8316 43.4556 79.7746 41.7184 79.6623 39.9474C78.9038 27.9842 84.1229 12.907 99.6304 8C99.618 8.44733 99.6123 8.89443 99.6123 9.34263Z"
					fill="#EDF2F7"
				/>
				<path
					d="M120 14C126.509 15.7226 131.648 20.8129 133.44 27.2932"
					stroke="#FFC58E"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M91 46.5C91 50.0899 94.134 53 98 53C101.866 53 105 50.0899 105 46.5"
					stroke="#989EA9"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M120 46.5C120 50.0899 123.134 53 127 53C130.866 53 134 50.0899 134 46.5"
					stroke="#989EA9"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					d="M105 65H119.5"
					stroke="#989EA9"
					strokeWidth="2.5"
					strokeLinecap="round"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M52.156 5.29199H40.21V7.66799H48.658L39.506 18.756V21H52.376V18.624H43.004L52.156 7.38199V5.29199ZM65.568 20.576H56.88V22.304H63.024L56.368 30.368V32H65.728V30.272H58.912L65.568 22.096V20.576Z"
					fill="#989EA9"
				/>
			</svg>
			<div className="flex-grow-0 flex-shrink-0 w-[488px] h-[19px] relative">
				<p className="absolute left-[98.61px] top-0 text-base font-semibold text-center text-[#323e53]">
					{t("You do not have any pending requests!")}
				</p>
			</div>
		</FadeBox>
	);
};
