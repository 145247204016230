import {
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import UseGetAdmin from "../../../../../hooks/useGetAdmin";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../../../store/atoms";
import { Members } from "../../../../../types";
import CreateNewConference from "./CreateNewConference";
import NextConferences from "./NextConferences";
import PastConferences from "./PastConferences";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConferenceGroup = () => {
	const { id } = useParams();
	const { t } = useTranslation();

	const { getCommunityMembers, getCommunityConferences } = useApiRequests();
	const { ifUserAdmin } = UseGetAdmin();
	const userData = useRecoilValue(userDataAtom);

	const { data, isLoading } = useQuery({
		queryFn: () => getCommunityMembers(id as string),
		queryKey: ["CommunityMemebers", id],
	});

	const { data: conferences, isLoading: isLoadingConferences } = useQuery({
		queryFn: () => getCommunityConferences(id as string),
		queryKey: ["community-conferences", id],
		select: (data) => data.data,
	});

	return (
		<Tabs variant={"gikiThemed"} className="h-full !flex flex-col">
			<TabList px="16px">
				<Tab>{t("next")}</Tab>
				<Tab>{t("prev")}</Tab>
				{isLoading ? (
					<Tab isDisabled={true}>
						<Spinner />
					</Tab>
				) : (
					ifUserAdmin(
						data?.data.data as Members[],
						userData?.accessToken.tokenable_id
							? userData?.accessToken.tokenable_id
							: ""
					) && <Tab>{t("new")}</Tab>
				)}
			</TabList>
			<TabPanels className="h-full flex-1 flex flex-col">
				<TabPanel className="overflow-y-auto h-full flex-1">
					<NextConferences
						conferences={conferences?.upcoming}
						isLoading={isLoadingConferences}
					/>
				</TabPanel>
				<TabPanel>
					<PastConferences
						conferences={conferences?.past}
						isLoading={isLoadingConferences}
					/>
				</TabPanel>
				<TabPanel>
					<CreateNewConference />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default ConferenceGroup;
