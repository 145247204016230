import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import FriendsSmall from "../../../../../components/icons/FriendsSmall";
import { useApiRequests } from "../../../../../configs/API/ApiRequests";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

export type Users = {
	user_id: string;
	full_name: string;
	selected: boolean;
}[];

const InviteNewUserOutsideGiki = ({ id }: { id: string }) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { singleInvite } = useApiRequests();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const mutate = useMutation({
		mutationFn: ({ name, email }: { name: string; email: string }) =>
			singleInvite(id, name, email),
		onSuccess: () => {
			toast({
				title: t("Users Invited Successfully"),
				status: "success",
				isClosable: true,
				duration: 5000,
			});
		},
		onError: () => {
			toast({
				title: t("something went wrong"),
				status: "error",
				isClosable: true,
				duration: 5000,
			});
		},
	});

	return (
		<>
			<button
				onClick={onOpen}
				className="w-full text-center bg-text-gray hover:bg-gray-300 transition-all duration-300 text-white text-xs leading-[14.4px] p-4 rounded"
			>
				{t("Invite member from outside the platform")}
			</button>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent className="">
					<div className="bg-white flex flex-col gap-[10px] rounded overflow-hidden pb-5 h-full">
						<div className="px-4 py-[10px] bg-[#4A5568] flex items-center gap-[10px] ">
							<p className="w-[54px] h-[54px] flex items-center justify-center">
								<FriendsSmall className="stroke-white" />
							</p>
							<h3 className="text-gray-50 font-bold text-sm leading-[16.8px]">
								{t("Invite member from outside the platform")}
							</h3>
						</div>
						<div className="px-5">
							<Formik
								initialValues={{ name: "", email: "" }}
								onSubmit={(values, actions) => {
									mutate.mutateAsync(values).finally(() => {
										actions.setSubmitting(false);
										actions.resetForm();
									});
								}}
							>
								{(props) => (
									<Form className="gap-5 flex flex-col">
										<Field
											name="name"
											validate={(e: string) => {
												if (e.length === 0) {
													return "Please enter valid name";
												}
											}}
										>
											{({ field, form }: { field: any; form: any }) => (
												<FormControl
													isInvalid={form.errors.name && form.touched.name}
												>
													<FormLabel>{t("Name")}: </FormLabel>
													<Input {...field} placeholder="name" />
													<FormErrorMessage>
														{form.errors.name}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Field name="email" validate={(e: string) => {}}>
											{({ field, form }: { field: any; form: any }) => (
												<FormControl
													isInvalid={form.errors.email && form.touched.email}
												>
													<FormLabel>{t("Email")}</FormLabel>
													<Input
														{...field}
														placeholder="email"
														type={"email"}
													/>
													<FormErrorMessage>
														{form.errors.email}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<div className="flex justify-end">
											<Button
												mt={4}
												className="disabled:!bg-blue-primary-50 !bg-blue-primary hover:!bg-blue-primary-80 rounded !text-white px-4 py-[6px] transition-colors duration-300 !h-fit"
												isLoading={props.isSubmitting}
												type="submit"
											>
												{t("Submit")}
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</ModalContent>
			</Modal>
		</>
	);
};

export default InviteNewUserOutsideGiki;
