import React from "react";
import PinnedButton from "./PinnedButton";
import PinnedSkeleton from "./PinnedSkeleton";

type Props = {
  isLoading: boolean;
  data: {
    id: number;
    message: string;
    user_id: string;
    conversation_id: number;
    reply_to: number | string;
    content_type: string;
    attachment_id: number | string;
    sent_at: string;
    created_at: string;
    updated_at: string;
    message_date: string;
    message_time: string;
    page_number: number;
    user: {
      id: string;
      sso_id: number;
      photo: null;
      first_name: string;
      last_name: string;
    };
  }[];
};

const AllLinks = ({ isLoading, data }: Props) => {
  return (
    <div>
      <div className='flex flex-col gap-5 px-5'>
        {data.length > 0 &&
          data.map((item) => (
            <PinnedButton
              content={item.message}
              size={0}
              type={"link"}
              link={((item.message || "").match(/https?:\/\/[^\s]+/) || "")[0]}
              key={item.id}
            />
          ))}
        {isLoading && (
          <>
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
            <PinnedSkeleton />
          </>
        )}
      </div>
    </div>
  );
};

export default AllLinks;
