import { cloneDeep } from 'lodash';

export const fetchColorsFromAPI = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}vendor/init`);
    if (!response.ok) {
      throw new Error('Failed to fetch color data from the API');
    }
    const data = await response.json();
    if (data?.logo) {
      localStorage.setItem('logo', data.logo)
    }
    if (data?.main_logo) {
      localStorage.setItem('main_logo', data.main_logo)
    }
    if (data?.app_long_name) {
      localStorage.setItem('app_long_name', data.app_long_name)
    }
    if (data?.app_name) {
      localStorage.setItem('app_name', data.app_name)
    }

    const colorsOnly = cloneDeep(data)

    delete colorsOnly.logo;
    delete colorsOnly.main_logo;
    delete colorsOnly.app_long_name;
    delete colorsOnly.app_name;
    
    return colorsOnly;
  } catch (error) {
    console.error('Error fetching color data:', error);
    return {}; // Return an empty object in case of an error
  }
}; 