import ConferenceCard, { ConferenceCardSkeleton } from "./ConferenceCard";

export type Conf = {
	id: string;
	title: string;
	type: number;
	created_by: string;
	description: string;
	label_start_date: string;
	start_date: Date;
	on_air: boolean;
	attendees: number;
	is_joined: boolean;
	is_enrolled: boolean;
	community: {
		id: string;
		name: string;
		image: string;
		short_description: string;
		users_count: number;
	};
};

const NextConferences = ({
	conferences,
	isLoading,
}: {
	conferences: Conf[];
	isLoading: boolean;
}) => {
	return (
		<div className="flex flex-col gap-4 p-4">
			{isLoading ? (
				<>
					<ConferenceCardSkeleton />
					<ConferenceCardSkeleton />
					<ConferenceCardSkeleton />
					<ConferenceCardSkeleton />
				</>
			) : (
				conferences.map((item: Conf, index: number) => (
					<ConferenceCard
						key={index}
						date={item.label_start_date}
						description={item.description}
						title={item.title}
						attendees={item.attendees}
						isLive={item.on_air}
						isNext={true}
						confId={item.id}
						is_enrolled={item.is_enrolled}
					/>
				))
			)}
		</div>
	);
};

export default NextConferences;
