const buttonsVariants = {
  primary: {
    bg: 'blue-primary-80',
    color: 'white',
    fontWeight: '600',
    height: '10',
    textTransform: 'none',
    rounded: '6px',
    w: 'full',
    _disabled: {
      bgColor: 'primary',
      opacity: 0.5,
    },
    _hover: {
      bg: 'orange-primary-80',
    },
    _active: { bg: 'orange-primary-80' },
  },
  heaveyBlue: {
    bg: 'blue-primary',
    color: 'white',
    fontWeight: '600',
    height: '40px',
    rounded: '8px',
    fontSize: '14px',
  },
  white: {
    bg: {base: 'gray-50', lg: 'white'},
    color: 'primary',
    fontWeight: '600',
    height: '40px',
    rounded: '8px',
    fontSize: '14px',
  },
  gray: {
    bg: 'gray-20',
    color: 'primary',
    fontWeight: '600',
    height: '40px',
    rounded: '8px',
    fontSize: '14px',
  },
  mobileMenu : {
    bg: '#00000000',
    color: 'white',
    fontWeight: '600',
    height: '55px',
    rounded: '8px',
    fontSize: '16px',
  },
}

export default buttonsVariants
