/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import _ from "lodash";
import { useRecoilState } from "recoil";
import {
	massagesArr,
	selectedMassage,
	showWelcomeFirstAccess,
} from "../store/atoms";
import { useToast } from "@chakra-ui/react";
import { pusherMassage } from "./usePusherChannels";
import paginationStore from "../store/pagination.store";
import { useParams } from "react-router-dom";

export interface Message {
	id: string;
	message: string;
	user_id: string;
	conversation_id: number;
	reply_to: string;
	content_type: string;
	attachment_id: string;
	is_owner: number;
	is_pinned: number;
	message_date: string;
	message_time: string;
	page_number: number;
	user: {
		id: string;
		sso_id: number;
		first_name: string;
		last_name: string;
		photo: string;
	};
	attachment: {
		id: number;
		attachmentable_type: string;
		attachmentable_id: number;
		file: string;
		type: string;
		created_at: string;
		updated_at: string;
		name: string;
		size: number;
		url: string;
	};

	replied_to: replyedMasage;
	replied_page: number;
}

export interface replyedMasage {
	attachment_id: string;
	content_type: string;
	conversation_id: number;
	created_at: string;
	id: number;
	message: string;
	message_date: string;
	message_time: string;
	reply_to: null;
	sent_at: string;
	updated_at: string;
	user_id: string;
	user: {
		first_name: string;
		id: string;
		last_name: string;
		photo: string;
	};
}

const useMessageHandler = () => {
	const { id } = useParams<{ id: string }>();
	const [pagination, setPagination] = useRecoilState(paginationStore);
	const [messages, setMessages] = useRecoilState(massagesArr);
	const [isLoading, setIsLoading] = useState(false);
	const [grouppedMessages, setGrouppedMessages] =
		useState<{ date: string; massages: Message[] }[]>();
	const [selectedIdMassage] = useRecoilState(selectedMassage);
	const [, setShowWelcome] = useRecoilState(showWelcomeFirstAccess);

	const { getConversation, deleteConversation } = useApiRequests();
	const toast = useToast();

	useEffect(() => {
		const groupMassges = _(messages)
			.groupBy((o: { message_date: any }) => o.message_date)
			.map((massages, date) => ({ date, massages }))
			.value();

		setGrouppedMessages(groupMassges);
	}, [messages]);

	useEffect(() => {
		return () => {
			setMessages([]);
			setGrouppedMessages([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const { mutate, isPending: isLoadingFirstLoad } = useMutation({
		mutationFn: (data: { communityId: string; page: string }) =>
			getConversation(data),
		onSuccess: (data: any) => {
			const messagesData = data.data;
			setShowWelcome(!messagesData.is_accessed);
			setMessages((prevMessages) => [
				...prevMessages,
				...messagesData.messages.data,
			]);
			setPagination({
				currentPage: messagesData.messages.current_page,
				totalPages: Math.ceil(messagesData.messages.total / 10),
				firstPage: 1,
				currentNext: messagesData.messages.current_page,
				currentPrev: 1,
			});

			setIsLoading(false);
		},
	});

	const loadPage = useMutation({
		mutationFn: (data: { communityId: string; page: string }) =>
			getConversation(data),
		onSuccess: (data: any) => {
			const messagesData = data.data;
			setMessages((prevMessages) => [
				...prevMessages,
				...messagesData.messages.data,
			]);
			setPagination((prevPagination) => ({
				...prevPagination,
				currentPage: messagesData.messages.current_page,
				currentNext: messagesData.messages.current_page,
			}));
		},
	});

	const loadPrevPage = useMutation({
		mutationFn: (data: { communityId: string; page: string }) =>
			getConversation(data),
		onSuccess: (data: any) => {
			const messagesData = data.data;
			setMessages((prevMessages) => [
				...messagesData.messages.data,
				...prevMessages,
			]);
			setPagination((prevPagination) => ({
				...prevPagination,
				currentPage: messagesData.messages.current_page,
				currentPrev: messagesData.messages.current_page,
			}));
		},
	});

	const jumpToMessage = useMutation({
		mutationFn: (data: { communityId: string; page: string }) =>
			getConversation(data),
		onSuccess: (data: any) => {
			const messagesData = data.data;

			setMessages(messagesData.messages.data);
			setPagination((prevPagination) => ({
				...prevPagination,
				currentPage: messagesData.messages.current_page,
				totalPages: Math.ceil(messagesData.messages.total / 10),
				currentNext: messagesData.messages.current_page,
				currentPrev: messagesData.messages.current_page,
			}));
		},
	});

	const loadMoreMessages = () => {
		const nextPage = pagination.currentNext + 1;
		if (id === "" || loadPage.isPending) return;
		loadPage.mutate({ communityId: id as string, page: nextPage.toString() });
	};

	const loadPreviousMessages = () => {
		const previousPage = pagination.currentPrev - 1;
		if (id === "" || loadPage.isPending) return;
		loadPrevPage.mutate({
			communityId: id as string,
			page: previousPage.toString(),
		});
	};

	const refreshMessages = () => {
		setMessages([]);
		setPagination({
			currentPage: 1,
			totalPages: 0,
			firstPage: 1,
			currentNext: 0,
			currentPrev: 0,
		});
		if (id === "") return;
		mutate({ communityId: id as string, page: "1" });
	};

	const firstLoad = () => {
		mutate({ communityId: id as string, page: "1" });
	};

	const goIntoMassage = (page: string) => {
		if (id === "" || selectedIdMassage.id === "") return;
		const massageId = parseInt(selectedIdMassage.id) as any;
		if (messages.map((msg) => msg.id).includes(massageId)) return;
		setMessages([]);
		jumpToMessage.mutate({ communityId: id as string, page: page });
	};

	const hundleRemoveMassage = (msgId: string) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMutation({
			mutationFn: (id: string) => deleteConversation(id),
			onSuccess: () => {
				const newMessages = messages.filter((massage) => massage.id !== msgId);
				setMessages(newMessages);
				toast({
					title: "Massage deleted",
					status: "success",
					duration: 1500,
					isClosable: true,
				});
			},
		});
	};

	const pusherMassageHundler = (data: pusherMassage) => {
		if (!data.message) return;
		const newMassage = {
			id: data.id.toString(),
			message: data.message,
			user_id: data.Sender.id,
			conversation_id: data.conversation_id,
			reply_to: data.reply_to,
			content_type: data.content_type.toString(),
			attachment_id: "",
			is_owner: data.is_owner,
			is_pinned: 0,
			message_date: data.message_date,
			message_time: data.message_time,
			page_number: data.page_number,
			user: {
				id: data.Sender.id,
				sso_id: 0,
				first_name: data.Sender.first_name,
				last_name: data.Sender.last_name,
				photo: data.Sender.photo,
			},
			attachment: {
				id: data.attachment?.attachmentable_id,
				attachmentable_type: data.attachment?.attachmentable_type,
				attachmentable_id: data.attachment?.attachmentable_id,
				file: data.attachment?.file,
				type: data.attachment?.type,
				created_at: data.attachment?.created_at,
				updated_at: data.attachment?.updated_at,
				name: data.attachment?.name,
				size: data.attachment?.size,
				url: data.attachment?.url,
			},
			replied_to: {
				attachment_id: data.replied_to?.attachment_id,
				content_type: data.replied_to?.content_type,
				conversation_id: data.replied_to?.conversation_id,
				created_at: data.replied_to?.created_at,
				id: data.replied_to?.id,
				message: data.replied_to?.message,
				message_date: data.replied_to?.message_date,
				message_time: data.replied_to?.message_time,
				page_number: data.replied_to?.page_number,
				sent_at: data.replied_to?.sent_at,
				updated_at: data.replied_to?.updated_at,
				user: {
					first_name: data.replied_to?.user.first_name,
					id: data.replied_to?.user.id,
					last_name: data.replied_to?.user.last_name,
					photo: data.replied_to?.user.photo,
					sso_id: data.replied_to?.user.sso_id,
				},
			} as any,
			replied_page: data.reply_to as any,
		};

		setMessages((prevMessages) => [newMassage, ...prevMessages]);
	};

	useEffect(() => {
		setIsLoading(isLoadingFirstLoad);
	}, [isLoadingFirstLoad]);

	return {
		messages,
		pagination,
		isLoading,
		loadMoreMessages,
		loadPreviousMessages,
		refreshMessages,
		firstLoad,
		grouppedMessages,
		goIntoMassage,
		jumpToMessage,
		loadPage,
		isLoadPage:
			loadPage.isPending || jumpToMessage.isPending || loadPrevPage.isPending,
		hundleRemoveMassage,
		pusherMassageHundler,
	};
};

export default useMessageHandler;
