/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Avatar,
	IconButton,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	useDisclosure,
	useMediaQuery,
} from "@chakra-ui/react";
import { getUrl, urlify } from "../../../../utility/regex";
import link from "../../../../assets/svgs/chat/link.svg";
import pinned from "../../../../assets/svgs/chat/pinned.svg";
import download from "../../../../assets/svgs/chat/download.svg";
import { useEffect, useState } from "react";
import MoreMenu from "./MoreMenu";
import { replyedMasage } from "../../../../hooks/useMessageHandler";
import { selectedChat, selectedMassage } from "../../../../store/atoms";
import { useRecoilState } from "recoil";
import usePinHundler from "../../../../hooks/usePinHundler";
import { AxiosResponse } from "axios";
import { UseMutationResult } from "@tanstack/react-query";
import MoreMobileMenu from "./MoreMobileMenu";
import { AiOutlineMore } from "react-icons/ai";
import { FadeBox } from "../../../../components/boxes/FadeBox";
import OutsideClickHandler from "../../../../hooks/useClickOutSideEl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface MassageBubbleProps {
	id: string;
	img: string;
	first_name: string;
	last_name: string;
	massage: string;
	isOwner?: boolean;
	time: string;
	massageImg?: string;
	replyData?: replyedMasage;
	replied_page?: number;
	urlTitle?: string;
	isPinnedDrawer?: boolean;
	isPinned?: boolean;
	file?: {
		url: string;
		type: string;
		name: string;
		size: string;
		img: string;
	};
	hasGoToButton?: boolean;
	nextMassageUserId?: string;
	prevMassageUserId?: string;
	userId?: string;
	goIntoMassage?: any;
	page_number?: number;
	hundleRemoveMassage?: (
		msgId: string
	) => UseMutationResult<AxiosResponse<any, any>, unknown, string, unknown>;
}

const UrlPreview = ({ msg }: { msg: string }) => {
	const [info, setInfo] = useState({
		title: "",
		description: "",
	});
	const [link, setLink] = useState<string | null>(null);
	const regex = /(https?:\/\/[^\s]+)/;
	const match = msg.match(regex);

	useEffect(() => {
		if (match && match[0]) {
			fetch(`https://api.microlink.io/?url=${match[0]}`)
				.then(async (res) => {
					const data = await res.json();
					// console.log(data.data.image.url);
					setLink(data.data.image.url);
					setInfo({
						title: data.data.title,
						description: data.data.description,
					});
				})
				.catch((err) => {
					setLink(null);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (link) {
		return (
			<div className="bg-white p-1 rounded border mb-2">
				<Link href={match ? match[0] : ""} target="_blank">
					<img src={link} alt="" className="rounded pb-1 border-b" />
					<p className="font-bold text-sm pb-0.5">{info.title}</p>
					<p className="text-xs line-clamp-1 pb-0.5">{info.description}</p>
				</Link>
			</div>
		);
	}

	return <></>;
};

const MassageBubble: React.FunctionComponent<MassageBubbleProps> = ({
	img,
	massage,
	first_name,
	last_name,
	time,
	isOwner,
	massageImg,
	replyData,
	urlTitle,
	isPinnedDrawer,
	isPinned,
	file,
	nextMassageUserId,
	userId,
	prevMassageUserId,
	id,
	replied_page,
	hundleRemoveMassage,
	goIntoMassage,
	page_number,
	hasGoToButton = false,
}) => {
	const {
		isOpen: isOpenImage,
		onOpen: onOpenImage,
		onClose: onCloseImage,
	} = useDisclosure();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [chat] = useRecoilState(selectedChat);
	const [, setSelectedMassage] = useRecoilState(selectedMassage);
	const isSameUser =
		userId === nextMassageUserId || userId === prevMassageUserId;
	const isLastMassage = userId === nextMassageUserId && !isPinnedDrawer;
	const pinHundler = usePinHundler(id, isPinned || false);
	const [isMobile] = useMediaQuery("(max-width: 1024px)");
	const navigate = useNavigate();
	const name = `${first_name} ${last_name}`;
	const { t } = useTranslation();

	return (
		<div className="lg:w-full">
			<FadeBox
				id={id}
				key={`massage-box-${id}`}
				className={`flex ${
					isOwner ? "flex-row-reverse float-right" : "float-left"
				} gap-1 ${isSameUser ? "py-1" : "py-4"} ${
					isLastMassage ? (isOwner ? "mr-[43px]" : "ml-[43px]") : ""
				} ${
					isPinnedDrawer
						? "w-full"
						: "lg:max-w-[65%] md:max-w-[94%] max-w-[88%] "
				}`}
			>
				{!isLastMassage && (
					<Avatar
						cursor="pointer"
						onClick={() =>
							navigate("/members", {
								state: {
									first_name: first_name,
									id: userId,
									last_name: last_name,
									photo: img,
								},
							})
						}
						border="2.5px solid white"
						w="39px"
						h="39px"
						name={name}
						src={`${process.env.REACT_APP_MEDIA_URL}${img}`}
						size="sm"
					/>
				)}

				<div
					className={`bg-[#F7FAFC] hover:bg-[#cbd5e0]  lg:w-auto relative rounded p-[10px] ${
						isPinnedDrawer ? "w-full" : "max-w-[275px]"
					}`}
					onClick={() => {
						if (hasGoToButton) {
							setSelectedMassage({ id: id });
							setTimeout(() => {
								goIntoMassage(page_number);
							}, 200);
						}
					}}
				>
					{replyData && replied_page && (
						<div
							onClick={(e) => {
								setSelectedMassage({ id: replyData.id.toString() });
								goIntoMassage(replied_page.toString(), chat.id);
								e.stopPropagation();
							}}
							className="bg-gray-50 rounded p-[10px] my-1 cursor-pointer"
						>
							<div className="flex justify-between items-center gap-2">
								<p className="text-blue-primary font-semibold text-xs max-w-[120px] line-clamp-1">
									{replyData.user.first_name} {replyData.user.last_name}
								</p>
								<span className="text-blue-primary-50 text-xs">
									{replyData.message_time}
								</span>
							</div>
							<div className="flex items-center gap-1">
								{replyData.attachment_id && (
									<svg
										width="21"
										height="21"
										viewBox="0 0 21 21"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.5 3.0752C3.39543 3.0752 2.5 3.97063 2.5 5.0752V15.0752C2.5 16.1798 3.39543 17.0752 4.5 17.0752H16.5C17.6046 17.0752 18.5 16.1798 18.5 15.0752V5.0752C18.5 3.97063 17.6046 3.0752 16.5 3.0752H4.5ZM16.5 15.0752H4.5L8.5 7.0752L11.5 13.0752L13.5 9.0752L16.5 15.0752Z"
											fill="#718096"
										/>
									</svg>
								)}
								<p
									className="text-xs text-blue-primary"
									dangerouslySetInnerHTML={{
										__html: urlify(replyData.message),
									}}
								/>
							</div>
						</div>
					)}

					<UrlPreview msg={massage} />
					<div className="flex justify-between w-full pb-[1px] gap-2">
						<p
							className={`${
								isOwner ? "text-orange-primary" : "text-blue-primary"
							} font-semibold text-xs line-clamp-1 max-w-[170px]`}
						>
							{isOwner ? t("You") : name}
						</p>
						<div className="flex gap-[5px] items-center">
							<span className="text-blue-primary-50 text-xs">{time}</span>
							{pinHundler.isPinned && <img src={pinned} alt="" />}
						</div>
					</div>
					{!replyData && urlTitle && (
						<Link
							style={{ textDecoration: "none" }}
							href={getUrl(massage) as string}
							target="_blank"
						>
							<div className="bg-gray-50 rounded-lg p-[10px] my-1 flex gap-[5px] items-center break-words">
								<img src={link} alt={`${name} link`} />
								<div
									style={{ wordBreak: "break-word" }}
									className="flex flex-col gap-[5px] break-words"
								>
									<p className="font-semibold text-xs text-blue-primary-50">
										{urlTitle}
									</p>
									<p className="text-xs text-blue-primary-50">
										{getUrl(massage)}
									</p>
								</div>
							</div>
						</Link>
					)}
					{massageImg && (
						<>
							<Modal isCentered isOpen={isOpenImage} onClose={onCloseImage}>
								<ModalContent className="sm:min-w-[600px] md:min-w-[700px]">
									<ModalCloseButton />
									<ModalBody>
										<img
											src={massageImg}
											alt={`${name} massage image`}
											className="py-[5px]"
										/>
									</ModalBody>
								</ModalContent>
							</Modal>
							<img
								onClick={onOpenImage}
								src={massageImg}
								alt={`${name} massage image`}
								className="object-cover py-[5px] h-[150px] w-[275px] z-1000"
							/>
						</>
					)}
					{file?.name && (
						<Link
							style={{ textDecoration: "none" }}
							href={file.url}
							target="_blank"
						>
							<div className="bg-gray-50 rounded-lg p-[10px] my-1 flex gap-[5px] items-center relative">
								<IconButton
									aria-label="File-icon"
									bg="blue-primary-80"
									icon={
										<img
											className="w-6 h-6 rounded-lg"
											src={file.img}
											alt={`${file.name} link`}
										/>
									}
									_hover={{ bg: "blue-primary-80" }}
								/>
								<div className="flex flex-col gap-[5px] break-words pr-10">
									<p className="font-semibold text-xs text-blue-primary-50 no-underline	">
										{file.name.length > 20
											? file.name.substring(0, 20) + "..."
											: file.name}
									</p>
									<p className="text-xs text-blue-primary-50 no-underline">
										{file.type} • {file.size}
									</p>
								</div>
								<img className="absolute right-3" src={download} alt="" />
							</div>
						</Link>
					)}
					<p
						style={{ wordBreak: "break-word" }}
						className=" w-full text-blue-primary text-xs leading-[18px] font-light"
						dangerouslySetInnerHTML={{ __html: urlify(massage) }}
					/>
					<div
						className={`absolute hidden lg:block ${
							isOwner ? "-left-5 top-1/3" : "-right-5 top-1/3"
						}`}
					>
						{!isPinnedDrawer && !isMobile && (
							<OutsideClickHandler onOutsideClick={onClose}>
								<AiOutlineMore
									onClick={isOpen ? onClose : onOpen}
									className="text-text-gray cursor-pointer"
								/>
								{!isPinnedDrawer && isOpen && (
									<MoreMenu
										hundleRemoveMassage={hundleRemoveMassage}
										massageData={{
											massage: massage,
											name: name,
											massageImg: massageImg ? massageImg : "",
											id: id,
											isPinned: pinHundler.isPinned
												? pinHundler.isPinned
												: false,
											userId: userId,
										}}
										OnClose={onClose}
										isOwner={isOwner}
										isOpen={isOpen}
										onOpen={onOpen}
										{...pinHundler}
									/>
								)}
							</OutsideClickHandler>
						)}
					</div>
					{!isPinnedDrawer && (
						<div
							className={`absolute block lg:hidden ${
								isOwner ? "-left-5 top-1/3" : "-right-5 top-1/3"
							}`}
						>
							<MoreMobileMenu
								onClose={onClose}
								isOpen={isOpen}
								onOpen={onOpen}
								massageData={{
									massage: massage,
									name: name,
									massageImg: massageImg ? massageImg : "",
									id: id,
									isPinned: pinHundler.isPinned ? pinHundler.isPinned : false,
									userId: userId,
								}}
								hundleRemoveMassage={hundleRemoveMassage}
								{...pinHundler}
							/>
						</div>
					)}
				</div>
			</FadeBox>
		</div>
	);
};

export default MassageBubble;
