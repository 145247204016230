import React from "react";

const File = ({ className }: { className: string }) => {
  return (
    <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 19.6667H13C14.1046 19.6667 15 18.7713 15 17.6667V8.08096C15 7.81575 14.8946 7.56139 14.7071 7.37386L9.29289 1.95964C9.10536 1.7721 8.851 1.66675 8.58579 1.66675H3C1.89543 1.66675 1 2.56218 1 3.66675V17.6667C1 18.7713 1.89543 19.6667 3 19.6667Z'
        className={className}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default File;
