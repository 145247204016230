import {
	Button,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Link,
} from "@chakra-ui/react";
import { useLogin } from "../../hooks/useLogin";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface LoginFormProps {}

const LoginForm: React.FunctionComponent<LoginFormProps> = () => {
	const { onSubmit, register, handleSubmit, isLoading, errors } = useLogin();
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation();

	return (
		<form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-3">
				<FormLabel variant="user-outline" className="col-span-2">
					{t("login.loginForm.email")}
				</FormLabel>
				<Input
					{...register("email")}
					variant="user-outline"
					placeholder="you@domain.com"
					className="col-span-2 placeholder:text-grap-20"
					type="email"
				/>
				{errors.email && (
					<div className="text-[#C53030] text-xs ">{errors.email.message}</div>
				)}
			</div>
			<div className="grid grid-cols-2 gap-3">
				<FormLabel variant="user-outline" className="col-span-2">
					{t("login.loginForm.password")}
				</FormLabel>
				<InputGroup className="col-span-2 placeholder:text-grap-20">
					<Input
						type={showPassword ? "text" : "password"}
						{...register("password")}
						variant="user-outline"
						placeholder="******"
					/>
					<InputRightElement h="full">
						<Button
							bg={"transparent"}
							size={"sm"}
							onClick={() => setShowPassword((showPassword) => !showPassword)}
						>
							{showPassword ? (
								<BsEye className="text-3xl text-gray" />
							) : (
								<BsEyeSlash className="text-3xl text-gray" />
							)}
						</Button>
					</InputRightElement>
				</InputGroup>
				{errors.password && (
					<div className="text-[#C53030] text-xs ">
						{errors.password.message}
					</div>
				)}
			</div>
			<div className="grid grid-cols-2 gap-3">
				<Button
					isLoading={isLoading}
					disabled={isLoading}
					className="col-span-2"
					variant="primary"
					type="submit"
				>
					{t("login.loginForm.button")}
				</Button>
			</div>
			<div className="grid grid-cols-2">
				<span className="col-span-1 font-medium text-sm text-blue-primary">
					{t("login.loginForm.already_member")}{" "}
					<Link href="register" textDecoration="underline" color="primary">
						{t("login.loginForm.register")}
					</Link>
				</span>
				<span className="col-span-1 text-right text-sm text-blue-primary">
					<Link
						href="forgot-password"
						textDecoration="underline"
						color="primary"
					>
						{t("login.loginForm.forgotPassword")}
					</Link>
				</span>
			</div>
		</form>
	);
};

export default LoginForm;
