import { AiOutlineCamera, AiFillDelete } from "react-icons/ai";

import { Avatar, WrapItem } from "@chakra-ui/react";
import { useRef } from "react";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../store/atoms";

export function UpdateProfileImage({
	selectedFile,
	setSelectedFile,
	photo,
}: any) {
	const fileRef = useRef<HTMLInputElement>(null);
	const [user] = useRecoilState(userDataAtom);

	const handleSelectFile = (e: any) => {
		const file = e.target.files[0];
		setSelectedFile(file);
	};

	return (
		<>
			<WrapItem id="profilePic" position="relative" w="150px" h="150px">
				{selectedFile ? (
					<>
						<Avatar
							rounded="lg"
							opacity={0.6}
							w="150px"
							className="rounded-lg"
							borderRadius={"lg"}
							h="150px"
							fontSize="20px"
							name={
								user?.user_data.first_name + " " + user?.user_data.last_name
							}
							src={URL.createObjectURL(selectedFile)}
						/>
						<div className="w-8 absolute z-20 -right-2 -bottom-2">
							<span
								className="cursor-pointer shadow-lg rounded-full bg-white flex justify-center items-center p-2 w-9 h-9"
								onClick={() => fileRef?.current?.click()}
							>
								<AiFillDelete
									onClick={() => setSelectedFile(null)}
									className=" text-lg text-red-500 bg-white"
								/>
							</span>
						</div>
					</>
				) : (
					<>
						<Avatar
							rounded="lg"
							fontSize="30px"
							size={"150px"}
							borderRadius={"lg"}
							w="150px"
							h="150px"
							name={
								user?.user_data.first_name + " " + user?.user_data.last_name
							}
							src={`${process.env.REACT_APP_MEDIA_URL}${photo}`}
						/>
						<label
							className="w-8 absolute -right-2 -bottom-2"
							htmlFor="contained-button-file"
						>
							<input
								onChange={handleSelectFile}
								ref={fileRef}
								type="file"
								hidden
							/>
							{!selectedFile && (
								<span
									className="cursor-pointer shadow-lg rounded-full bg-white flex justify-center items-center p-2 w-8 h-8"
									onClick={() => fileRef?.current?.click()}
								>
									<AiOutlineCamera className="text-base text-blue-primary bg-white" />
								</span>
							)}
						</label>
					</>
				)}
			</WrapItem>
		</>
	);
}
