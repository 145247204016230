/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useEffect, useState } from "react";
import _ from "lodash";
import { MembersList } from "../../../types";
import MembersBox from "../../../components/boxes/MembersBox";
import { useRecoilValue } from "recoil";
import { sidebarState } from "../../../store/atoms";
import { useTranslation } from "react-i18next";
import { InputGroup } from "@chakra-ui/react";
import { useDebounce } from "use-debounce";
import ActionInput from "../../../components/inputs/ActionInput";

interface MembersPanelProps {
	setUser: Dispatch<any>;
	selectedUser: any;
	data: MembersList[];
}

const MembersPanel: React.FunctionComponent<MembersPanelProps> = ({
	setUser,
	selectedUser,
	data,
}) => {
	const openSideBar = useRecoilValue(sidebarState);
	const { t } = useTranslation();
	const [input, setInput] = useState("");
	const [debouncedInputValue] = useDebounce(input, 1000);
	const [groupedUsers, setGroupedUsers] = useState<
		{ letter: string; contacts: MembersList[] }[]
	>([]);
	const handleGroupUsers = (searchedItems: any[]) => {
		setGroupedUsers(
			_(searchedItems)
				.groupBy((o: any) => o.first_name[0].toUpperCase())
				.map((contacts, letter) => ({ letter, contacts }))
				.value()
				.sort((a, b) => {
					if (a.letter > b.letter) {
						return 1;
					}
					return -1;
				})
		);
	};

	useEffect(() => {
		handleGroupUsers(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const temp = data.filter((contact) =>
			(contact.first_name + contact.last_name)
				.toLocaleLowerCase()
				.includes(debouncedInputValue.toLocaleLowerCase())
		);

		handleGroupUsers(temp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedInputValue]);

	return (
		<>
			<InputGroup className="mb-2">
				<ActionInput
					placeholder={`Search ${data.length} members`}
					input={input}
					setInput={setInput}
					isLoading={false}
				/>
			</InputGroup>

			<div className="flex flex-col mb-4 gap-3 overflow-y-auto">
				{groupedUsers.map((obj) => (
					<div className="flex flex-col gap-[10px]" key={obj.letter}>
						<div className="flex items-center gap-2 pr-1">
							<span
								className={`h-[1px] ${
									openSideBar ? "w-[10px]" : "w-full"
								} bg-orange-primary`}
							/>
							<span className="font-bold text-orange-primary text-xs">
								{obj.letter}
							</span>
							<span className="h-[1px] w-full bg-orange-primary " />
							{openSideBar && (
								<span className="font-bold text-orange-primary flex items-center flex-shrink-0 text-xs">
									{obj.contacts.length} {t("Contacts")}
								</span>
							)}
						</div>
						{obj.contacts.map((users, index) => (
							<MembersBox
								selectedUser={selectedUser}
								setUser={setUser}
								users={users}
								key={`members_panel_${index}`}
							/>
						))}
					</div>
				))}
			</div>
		</>
	);
};

export default MembersPanel;
