/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton } from "@chakra-ui/react";
import emoji from "../../../../assets/svgs/chat/sender/emojis.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useRecoilState } from "recoil";
import { replyMassge, selectedChat } from "../../../../store/atoms";
import { useRef, useState } from "react";
import { useApiRequests } from "../../../../configs/API/ApiRequests";
import { useMutation } from "@tanstack/react-query";
import AttachButton from "./SendersButtons/AttachButton";
import MassageInput from "./SendersButtons/MassageInput";
import { FadeBox } from "../../../../components/boxes/FadeBox";
import { useTranslation } from "react-i18next";
interface MassageSenderProps {}

const MassageSender: React.FunctionComponent<MassageSenderProps> = () => {
	const [chat] = useRecoilState(selectedChat);
	const [massage, setMassage] = useState("");
	const [emojisMenu, setEmojisMenu] = useState(false);
	const inputRef = useRef<any>(null);
	const { t } = useTranslation();

	const [replyMassage, setReplyMassage] = useRecoilState(replyMassge);

	const { sendMassage } = useApiRequests();

	const handleEmojiSelect = (emoji: any) => {
		const { selectionStart, selectionEnd } = inputRef.current;

		const updatedMessage =
			massage.slice(0, selectionStart) +
			emoji.native +
			massage.slice(selectionEnd);

		setMassage(updatedMessage);
		setEmojisMenu(false);

		// Move the cursor position to the end of the inserted emoji
		const newCursorPos = selectionStart + emoji.native.length;
		inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
		inputRef.current.focus();
	};

	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: (data: any) => sendMassage(data),
		onSuccess: () => {
			// console.log(res)
		},
	});

	const sendMassageHandler = () => {
		if (!massage) return;
		else {
			mutate(
				{
					communityId: chat.id,
					data: {
						message: massage,
						reply_to: replyMassage.id !== "" ? replyMassage.id : null,
					},
				},
				{
					onSuccess: () => {
						setMassage("");
						setReplyMassage({ id: "", name: "", massageImg: "" });
					},
				}
			);
		}
	};

	return (
		<div className="bg-white border-t-[1px] border-t-[#EDF2F7] w-full justify-between items-center sticky bottom-0 flex flex-col p-5">
			{replyMassage.id !== "" && (
				<div className="flex items-center justify-between w-full">
					<FadeBox
						key={replyMassage.id}
						className="bg-gray-20 rounded-lg p-[10px] m-2 flex flex-col gap-[5px] w-full"
					>
						<p className="text-blue-primary text-xs font-semibold">
							{replyMassage.name}
						</p>
						<div className="flex items-center gap-[5px]">
							{replyMassage.massageImg !== "" && (
								<svg
									width="21"
									height="20"
									viewBox="0 0 21 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.8125 3C3.70793 3 2.8125 3.89543 2.8125 5V15C2.8125 16.1046 3.70793 17 4.8125 17H16.8125C17.9171 17 18.8125 16.1046 18.8125 15V5C18.8125 3.89543 17.9171 3 16.8125 3H4.8125ZM16.8125 15H4.8125L8.8125 7L11.8125 13L13.8125 9L16.8125 15Z"
										fill="#718096"
									/>
								</svg>
							)}
							<span
								style={{ wordBreak: "break-word" }}
								className="text-blue-primary text-xs "
							>
								{replyMassage.massage}
							</span>
						</div>
					</FadeBox>

					<IconButton
						_active={{ bg: "gray-20" }}
						variant="white"
						bg="#EDF2F7"
						aria-label="emojis"
						onClick={() => {
							setReplyMassage({
								id: "",
								massage: "",
								massageImg: "",
								name: "",
							});
						}}
						icon={
							<>
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.8125 12L12.8125 4M4.8125 4L12.8125 12"
										stroke="#718096"
										strokeWidth="1.2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</>
						}
					/>
				</div>
			)}

			<div className="rounded w-full justify-between items-center flex relative gap-5">
				{emojisMenu && (
					<div className="absolute bottom-full">
						<Picker
							theme="light"
							data={data}
							onEmojiSelect={handleEmojiSelect}
						/>
					</div>
				)}
				<IconButton
					onClick={() => setEmojisMenu(!emojisMenu)}
					_active={{ bg: "gray-20" }}
					variant="white"
					bg="#F7FAFC"
					aria-label="emojis"
					icon={
						<>
							<img className="w-5 h-5" src={emoji} alt="" />
						</>
					}
				/>
				<AttachButton />
				<MassageInput
					massage={massage}
					sendMassageHandler={sendMassageHandler}
					setMassage={setMassage}
					isLoading={isLoading}
					placeHolder={`${t("Message")} ${chat?.name}`}
					inputRef={inputRef}
				/>
			</div>
		</div>
	);
};

export default MassageSender;
