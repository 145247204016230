import { useMutation } from "@tanstack/react-query";
import { useApiRequests } from "../configs/API/ApiRequests";
import { useState } from "react";

export const useSuperSearch = () => {
	const {
		searchGikis,
		searchUsers,
		searchAllCommunityMessages,
		searchMyCommunity,
	} = useApiRequests();
	const [searchResults, setSearchResults] = useState<any>();

	const allGikisSearch = useMutation({
		mutationFn: searchGikis,
		onSuccess: (data) => {
			setSearchResults(data.data?.data);
		},
	});

	const messagesSearch = useMutation({
		mutationFn: searchAllCommunityMessages,
		onSuccess: (data) => {
			setSearchResults(data.data?.data);
		},
	});

	const usersSearch = useMutation({
		mutationFn: searchUsers,
		onSuccess: (data) => {
			setSearchResults(data.data?.data);
		},
	});

	const myCommunitySearch = useMutation({
		mutationFn: searchMyCommunity,
		onSuccess: (data) => {
			setSearchResults(data.data?.data);
		},
	});

	const handleSearch = ({
		keyword,
		type,
	}: {
		keyword: string;
		type: string;
	}) => {
		switch (type) {
			case "All Gikis":
				allGikisSearch.mutate({ keyword });
				break;
			case "Members":
				usersSearch.mutate(keyword);
				break;
			case "Messages":
				messagesSearch.mutate({ keyword: keyword, type: 1 });
				break;
			case "Media":
				messagesSearch.mutate({ keyword: keyword, type: 2 });
				break;
			case "My Gikis":
				myCommunitySearch.mutate({ keyword: keyword, type: 1 });
				break;
			default:
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				null;
		}
	};

	return {
		searchResults,
		handleSearch,
		isLoading:
			allGikisSearch.isPending ||
			usersSearch.isPending ||
			messagesSearch.isPending,
		setSearchResults,
	};
};
