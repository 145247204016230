import NotifcationDrawer from "../../components/drawers/NotifcationDrawer";
import SuperSearchDrawer from "../../components/drawers/SuperSearchDrawer";
import { Outlet } from "react-router-dom";
import MobileLayout from "./mobile-layout";
import Sidebar from "./sidebar";
import AlreadyLoggedInRedirect from "../../routes/AlreadyLoggedInRedirect";

const AppLayout: React.FunctionComponent = () => {
	return (
		<AlreadyLoggedInRedirect>
			<div className="flex flex-col md:flex-row mx-auto">
				<Sidebar />
				<NotifcationDrawer />
				<SuperSearchDrawer />
				<MobileLayout>
					<Outlet />
				</MobileLayout>
			</div>
		</AlreadyLoggedInRedirect>
	);
};

export default AppLayout;
