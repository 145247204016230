import { useToast } from "@chakra-ui/react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../store/atoms";

function ProtectedRoute() {
	const toast = useToast();
	const [userData] = useRecoilState(userDataAtom);
	const location = useLocation();

	if (userData && userData?.accessToken.abilities[0] === "make_consent") {
		return <Navigate to="/consent" replace />;
	}

	if (!userData && location.pathname === "/dashboard") {
		toast({
			title: "Non hai ancora effettuato il login!",
			position: "top",
			status: "error",
			isClosable: true,
		});
		return <Navigate to="/login" replace />;
	}

	if (!userData && location.pathname.includes("offerta")) {
		toast({
			title: "Non hai ancora effettuato il login!",
			position: "top",
			status: "error",
			isClosable: true,
		});

		localStorage.setItem("wasInOfferta", location.pathname);

		return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
	}

	if (!userData)
		return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;

	return <Outlet />;
}

export default ProtectedRoute;
