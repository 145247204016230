import File from "../../../../../../components/icons/File";
import Folder from "../../../../../../components/icons/Folder";
import Image from "../../../../../../components/icons/Image";
import Link from "../../../../../../components/icons/Link";
// import Trash from "../../../../../../components/icons/Trash";
import useJumpToMessage from "../../../../../../hooks/useJumpToMessage";
// import { useRecoilValue } from "recoil";
// import generalChatInfo from "../../info.atom";

export type FileType = "PPT" | "ZIP" | "Image" | "PDF" | "Link";

type PinnedData = {
	type: FileType;
	name: string;
	messageId: number;
	size?: number;
	page: number;
};

const PinnedButton = ({ type, messageId, name, size, page }: PinnedData) => {
	// const generalInfo = useRecoilValue(generalChatInfo);

	const {
		jumpToMessage: { mutate, isPending: isLoading },
	} = useJumpToMessage({ page });

	const icon = () => {
		switch (type) {
			case "PPT":
				return <File className="stroke-blue-primary" />;
			case "PDF":
				return <File className="stroke-blue-primary" />;
			case "Image":
				return <Image className="stroke-blue-primary" />;
			case "Link":
				return <Link className="stroke-blue-primary" />;
			case "ZIP":
				return <Folder className="fill-blue-primary" />;
			default:
				break;
		}
	};

	return (
		<div
			className={`flex items-center gap-[10px] cursor-pointer overflow-hidden ${
				isLoading &&
				"animate-pulse duration-300 bg-slate-300 pointer-events-none cursor-progress"
			}`}
			key={messageId}
			onClick={() => {
				mutate();
			}}
		>
			<p className="w-12 h-12 flex items-center justify-center rounded bg-gray-50">
				{icon()}
			</p>
			<div className="flex flex-col justify-between gap-[5px] text-blue-primary flex-1">
				<p className="line-clamp-1">{name}</p>
				<div className="flex items-center gap-[10px] text-xs">
					<p className="leading-[14.4px]">{type}</p>
					{type !== "Link" && (
						<p className="text-gray-20">{size && Math.floor(size / 1024)}KB</p>
					)}
				</div>
			</div>
			{/* {generalInfo.isAdmin && (
				<button className="w-12 h-12 rounded border-[1px] border-gray-50 flex items-center justify-center hover:bg-gray-50">
					<Trash />
				</button>
			)} */}
		</div>
	);
};

export default PinnedButton;
