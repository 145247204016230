import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface FooterLinksProps {}

const FooterLinks: React.FunctionComponent<FooterLinksProps> = () => {
	const { t } = useTranslation();

	return (
		<ul className="col-span-2 text-text-gray gap-x-10 font-light text-xs select-none flex lg:hidden items-center justify-center py-4">
			{/* <NavLink to="/about" className="cursor-pointer">
				{({ isActive }) => (
					<span className={isActive ? "font-semibold" : ""}>{t("About")}</span>
				)}
			</NavLink> */}
			<NavLink
				to="https://terapiainfiltrativapro.it/xgate-policy"
				target={"_blank"}
				className="cursor-pointer"
			>
				{({ isActive }) => (
					<span className={isActive ? "font-semibold" : ""}>
						{t("Privacy Policy Xgate")}
					</span>
				)}
			</NavLink>
			<NavLink
				to="https://terapiainfiltrativapro.it/privacy-policy"
				className="cursor-pointer"
			>
				{({ isActive }) => (
					<span className={isActive ? "font-semibold" : ""}>
						{t("Privacy")}
					</span>
				)}
			</NavLink>
			<NavLink
				to="https://terapiainfiltrativapro.it/termini-uso"
				className="cursor-pointer"
			>
				{({ isActive }) => (
					<span className={isActive ? "font-semibold" : ""}>
						{t("Terms of use")}
					</span>
				)}
			</NavLink>
			{/* <NavLink to="/faq" className="cursor-pointer">
				{({ isActive }) => (
					<span className={isActive ? "font-semibold" : ""}>{t("FAQ")}</span>
				)}
			</NavLink> */}
		</ul>
	);
};

export default FooterLinks;
