const Users = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.7273 15C9.91995 15 8.45459 13.5347 8.45459 11.7273C8.45459 9.91995 9.91995 8.45459 11.7273 8.45459C13.5347 8.45459 15 9.91995 15 11.7273C15 13.5347 13.5347 15 11.7273 15Z'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M14.1818 39.5454H11.0992C10.0648 39.5454 9.20102 38.7566 9.10737 37.7264L8.56598 31.7712C8.49829 31.0265 7.9962 30.3926 7.28686 30.1561V30.1561C6.51836 29.9 6 29.1808 6 28.3707V22.3635C6 20.5562 7.46536 19.0908 9.27273 19.0908H14.1818C15.3715 19.0908 16.413 19.7265 16.9857 20.6756'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
      />
      <path
        d='M36.2727 15C38.0801 15 39.5455 13.5347 39.5455 11.7273C39.5455 9.91995 38.0801 8.45459 36.2727 8.45459C34.4654 8.45459 33 9.91995 33 11.7273C33 13.5347 34.4654 15 36.2727 15Z'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M33.8181 39.5454H36.9007C37.9351 39.5454 38.7989 38.7566 38.8925 37.7264L39.4339 31.7712C39.5016 31.0265 40.0037 30.3926 40.713 30.1561V30.1561C41.4815 29.9 41.9999 29.1808 41.9999 28.3707V22.3635C41.9999 20.5562 40.5345 19.0908 38.7272 19.0908H33.8181C32.6284 19.0908 31.5869 19.7265 31.0142 20.6756'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
      />
      <path
        d='M23.9999 15.8182C21.2885 15.8182 19.0908 13.6205 19.0908 10.9091C19.0908 8.19764 21.2885 6 23.9999 6C26.7114 6 28.909 8.19764 28.909 10.9091C28.909 13.6205 26.7114 15.8182 23.9999 15.8182Z'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M28.2437 40.1662C28.1573 41.2028 27.2908 42.0001 26.2506 42.0001H21.7493C20.7091 42.0001 19.8426 41.2028 19.7562 40.1662L19.2098 33.6088C19.1387 32.7556 18.5323 32.0423 17.7017 31.8346L17.333 31.7425C16.4427 31.5199 15.8181 30.7199 15.8181 29.8022V23.1819C15.8181 21.3745 17.2835 19.9092 19.0908 19.9092H28.909C30.7164 19.9092 32.1818 21.3745 32.1818 23.1819V29.8022C32.1818 30.7199 31.5572 31.5199 30.6668 31.7425L30.2981 31.8346C29.4675 32.0423 28.8612 32.7556 28.7901 33.6088L28.2437 40.1662Z'
        stroke='#323E53'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};

export default Users;
