/* eslint-disable jsx-a11y/alt-text */
import { Button, IconButton } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface MobileMenuButtonProps {
  title: string
  icon?: string
  IconElement?: React.ReactElement;
  func?: () => void
  link?: string
  className?: string
}

const MobileMenuButton: React.FunctionComponent<MobileMenuButtonProps> = ({
  icon,
  title,
  func,
  link,
  className,
  IconElement
}) => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={link ? () => navigate(link) : () => null}
      px={0}
      textTransform='capitalize'
      justifyContent='flex-end'
      className={`flex gap-[10px] ${className}`}
      variant='mobileMenu'
    >
      {title}
      <IconButton
        onClick={func}
        _active={{ bg: 'blue-primary-80' }}
        variant={'heaveyBlue'}
        aria-label='hamburger'
        bg='white'
        width='50px'
        height='50px'
        fontSize='18px'
        icon={
          <>
            {icon && <img src={icon} />}
            {IconElement && IconElement}
          </>
        }
      />
    </Button>
  )
}

export default MobileMenuButton
