import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
} from "@chakra-ui/react";
import MembersBox from "../../components/boxes/MembersBox";
import ActionInput from "../../components/inputs/ActionInput";
import { useSuperSearch } from "../../hooks/useSuperSearch";
import { uniqueId } from "lodash";
import MassageBubble from "../../pages/chat/panels/chatScreen/MassageBubble";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { superSearchAtom, userSelected } from "../../store/atoms";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import GikiCard from "../boxes/GikiCard";
import { useNavigate } from "react-router-dom";

// 403

interface SuperSearchDrawerProps { }

const SuperSearchDrawer: React.FunctionComponent<
	SuperSearchDrawerProps
> = () => {
	const navigate = useNavigate();
	const [superChat, setSuperChat] = useRecoilState(superSearchAtom);
	const onClose = () => setSuperChat(false);
	const isOpen = superChat;
	const { t } = useTranslation();
	const [input, setInput] = useState("");
	const listItems = ["All Gikis", "Members", "My Gikis",
		//  "Messages", 
		"Media"];
	const [selectedList, setselectedList] = useState("All Gikis");
	const [debouncedInputValue] = useDebounce(input, 1000);
	const { handleSearch, searchResults, isLoading, setSearchResults } =
		useSuperSearch();
	const [, setSelectedUser] = useRecoilState(userSelected);
	// const messagesHandler = useMessageHandler();

	useEffect(() => {
		if (input !== "") {
			setSearchResults(undefined);
			handleSearch({ keyword: input, type: selectedList });
		}
		if (input === "") {
			setSearchResults(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedInputValue, selectedList]);
	return (
		<Drawer isOpen={isOpen} placement="right" size={"lg"} onClose={onClose}>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerHeader
					h="40px"
					className="flex justify-center items-center"
					bg="#EDF2F7"
				>
					<svg
						onClick={onClose}
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="absolute left-4 top-3 cursor-pointer"
					>
						<path
							d="M4 12L12 4M4 4L12 12"
							stroke="#718096"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span className="text-text-gray text-xs text-center">
						{t("Search")}
					</span>
				</DrawerHeader>

				<DrawerBody p={0} px={{ base: "12px", lg: "0px" }}>
					<div className="flex flex-col lg:w-full px-5 mx-auto pt-5 gap-5">
						<ActionInput
							input={input}
							setInput={setInput}
							isLoading={isLoading}
							placeholder="Search..."
						/>

						<div className="grid grid-cols-3 gap-[6px] justify-center items-center w-full">
							{listItems.map((item, index) => (
								<div
									onClick={() => setselectedList(item)}
									className={`${selectedList === item ? "bg-[#EDF2F7]" : "bg-white"
										} rounded-lg py-[10px] col-span-1 px-[20px] cursor-pointer select-none text-text-gray text-xs`}
									key={`chat_drawer_item_${index}`}
								>
									<div className="flex w-full flex-col justify-center items-center">
										<span>{t(item)}</span>
									</div>
								</div>
							))}
						</div>

						{selectedList === "All Gikis" && (
							<div className="flex flex-col gap-[10px] py-5">
								{searchResults?.length > 0 && (
									<span className="py-2 font-semibold  text-blue-primary">
										{t("All Gikis")}
									</span>
								)}
								{searchResults?.map((item: any) => (
									<GikiCard
										key={item.id}
										coverImage={item.image}
										data={{
											...item,
										}}
										img={item.image}
										className={"!min-w-full"}
									/>
								))}
							</div>
						)}
						{selectedList === "Members" && (
							<div className="flex flex-col gap-[10px] py-5">
								{searchResults?.length > 0 && (
									<span className="py-2 font-semibold  text-blue-primary">
										{t("Members")}
									</span>
								)}
								{searchResults?.map((item: any) => (
									<button
										onClick={() => {
											setSelectedUser(item);
											navigate("/members");
										}}
										key={`${item.id}-member${uniqueId()}`}
									>
										<MembersBox
											key={`${item.id}-member${uniqueId()}`}
											users={{
												first_name: item?.first_name,
												last_name: item?.last_name,
												photo: item.photo,
												id: item?.id,
												profession: "",
											}}
										/>
									</button>
								))}
							</div>
						)}
						{/* {selectedList === "Messages" && (
							<>
								{searchResults?.length > 0 && (
									<span className="py-2 font-semibold  text-blue-primary">
										{t("Messages")}
									</span>
								)}
								{searchResults?.map((item: any, i: number) => (
									<button
										onClick={() =>
											navigate(`/chat/${item.conversation.community_id}`, {
												state: {
													from: "super-search",
													data: {
														pageNumber: item.replied_to.page_number,
														messageId: item?.id,
													},
												},
											})
										}
									>
										<MassageBubble
											key={`${item?.id}-messages-search${i}${uniqueId()}`}
											goIntoMassage={messagesHandler.goIntoMassage}
											replyData={item.replied_to && item.replied_to}
											replied_page={
												item.replied_to.page_number &&
												item.replied_to.page_number
											}
											page_number={item.page_number}
											first_name={item?.user?.first_name}
											hasGoToButton={true}
											last_name={item?.user?.last_name}
											img={item.user.photo}
											userId={item?.user?.id}
											massage={item?.message}
											time={item?.message_time}
											id={item?.id?.toString()}
											isPinnedDrawer
										/>
									</button>
								))}
							</>
						)} */}
						{selectedList === "Media" && (
							<>
								{searchResults?.length > 0 && (
									<span className="py-2 font-semibold  text-blue-primary">
										{t("Media")}
									</span>
								)}
								{searchResults?.map((item: any, i: number) => (
									<MassageBubble
										img={`${process.env.REACT_APP_MEDIA_URL}${item?.attachment?.file}`}
										key={`${item?.id}-messages-search${i}${uniqueId()}`}
										first_name={item?.user?.first_name}
										last_name={item?.user?.last_name}
										massage={item?.message}
										time={item?.message_time}
										id={item?.id?.toString()}
										isPinnedDrawer
										page_number={item.page_number}
										{...(item?.attachment?.type === "Image"
											? {
												massageImg: `${item?.attachment?.url}`,
											}
											: {})}
										{...(item?.attachment?.type !== "Image"
											? {
												file: {
													type: item?.attachment?.type,
													size: item?.attachment?.size,
													img: `${process.env.REACT_APP_MEDIA_URL}${item?.attachment?.file}`,
													name: item?.attachment?.name,
													url: `${process.env.REACT_APP_MEDIA_URL}${item?.attachment?.file}`,
												},
											}
											: {})}
									/>
								))}
							</>
						)}
						{selectedList === "My Gikis" && (
							<div className="flex flex-col gap-[10px] py-5">
								{searchResults?.length > 0 && (
									<span className="py-2 font-semibold  text-blue-primary">
										{t("My Gikis")}
									</span>
								)}
								{searchResults?.map((item: any) => (
									<GikiCard
										key={item.id}
										coverImage={item.image}
										data={{
											...item,
										}}
										img={item.image}
										className={"!min-w-full"}
									/>
								))}
							</div>
						)}
					</div>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default SuperSearchDrawer;
