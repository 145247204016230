export const checkIfEndOfPage = (el: HTMLDivElement | null) => {
  // Get the viewport height
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  // Get the position of the element relative to the viewport
  const elementPosition = el?.getBoundingClientRect().top

  // Calculate the threshold value for the last 20% of the viewport height
  const threshold = viewportHeight * 0.75

  // Check if the element is positioned within the last 20% of the bottom page
  if (elementPosition && elementPosition >= threshold) return true
  else return false
}

export function isElementVisible(element: any) {
  if (!element) return false

  const rect = element.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight

  return rect.top >= 0 && rect.bottom <= windowHeight
}
