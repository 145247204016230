import { useQuery } from "@tanstack/react-query";
import NukaCarousel from "nuka-carousel";
import ConferenceBanner from "../../../components/banners/ConferenceBanner";
import ConferenceSkeleton from "../../../components/banners/ConferenceSkeleton";
import { useApiRequests } from "../../../configs/API/ApiRequests";
import { ConferenceData } from "./conference.types";

const ConferencesBanners = () => {
	const { getAllConferences } = useApiRequests();

	const { data, isLoading } = useQuery({
		queryKey: ["all-conference"],
		queryFn: () => getAllConferences(),
		select: (res) => res.data,
	});

	return (
		<NukaCarousel
			slidesToShow={1.3}
			renderCenterLeftControls={null}
			renderCenterRightControls={null}
			renderBottomCenterControls={null}
		>
			{isLoading
				? [...Array(8)].map((a) => <ConferenceSkeleton key={a} />)
				: data &&
				  data.length > 0 &&
				  data.map(
						(
							item: ConferenceData & {
								community: {
									id: string;
									name: string;
									image: string;
									short_description: string;
									users_count: number;
								};
							}
						) => (
							<ConferenceBanner
								key={item.id}
								{...item}
								{...item.community}
							/>
						)
				  )}
		</NukaCarousel>
	);
};

export default ConferencesBanners;
