import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

interface ModalsWrapperProps {
  ButtonLabel: string
  children: string
  title: string
}

const ModalsWrapper: React.FunctionComponent<ModalsWrapperProps> = ({
  ButtonLabel,
  children,
  title,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text
        className='cursor-pointer select-none underline'
        fontWeight={400}
        fontSize='16px'
        variant='unstyled'
        onClick={onOpen}
      >
        {ButtonLabel}
      </Text>

      <Modal scrollBehavior='inside' size='4xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody px='30px'>
            <h2 className='text-2xl font-semibold pt-2 pb-5'>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: children }}></p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalsWrapper
