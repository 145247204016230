import File from "../../../../../components/icons/File";
import Folder from "../../../../../components/icons/Folder";
import Link from "../../../../../components/icons/Link";
import Video from "../../../../../components/icons/Video";

const PinnedButton = ({
  type,
  link,
  content,
  size,
}: {
  type: "file" | "zip" | "img" | "video" | "link";
  link: string;
  content: string;
  size: number;
}) => {
  const icon = () => {
    switch (type) {
      case "file":
        return <File className='stroke-blue-primary' />;
      case "img":
        return <img src={link} alt={content} className='w-12 h-12 rounded object-cover' />;
      case "link":
        return <Link className='stroke-blue-primary' />;
      case "video":
        return <Video className='stroke-blue-primary' />;
      case "zip":
        return <Folder className='fill-blue-primary' />;
      default:
        break;
    }
  };

  return (
    <div
      className={"flex items-center gap-[10px] cursor-pointer"}
      onClick={() => {
        if (type === "link") {
          window.open(content, "_blank");
        } else {
          window.open(link, "_blank");
        }
      }}
    >
      <p className='w-12 h-12 flex items-center justify-center rounded bg-gray-50 min-w-fit'>
        {icon()}
      </p>
      <div className='flex flex-col justify-between gap-[5px] text-blue-primary flex-1'>
        <p className='line-clamp-1'>
          {content?.length > 20 ? content.split("").splice(0, 17).join("") + "..." : content}
        </p>
        <div className='flex items-center gap-[10px] text-xs overflow-hidden'>
          <p className='leading-[14.4px]'>{type}</p>
          {type !== "link" && <p className='text-gray-200'>{Math.floor(size / 1024)}KB</p>}
        </div>
      </div>
    </div>
  );
};

export default PinnedButton;
