const SelectVariants = {
  'register-outline': {
    field: {
      fontWeight: 400,
      color: 'text-gray',
    },
  },
}

export default SelectVariants
