import { Spinner, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequests } from "../../configs/API/ApiRequests";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedChat } from "../../store/atoms";
import { Giki } from "../../types";
import { useTranslation } from "react-i18next";

interface JoinButtonProps {
	type?: number;
	data: Giki;
}

const JoinButton: React.FunctionComponent<JoinButtonProps> = ({
	type,
	data,
}) => {
	const { onOpen } = useDisclosure();
	const [, setChat] = useRecoilState(selectedChat);
	const { t } = useTranslation();

	const { joinCommunity } = useApiRequests();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const joinMutation = useMutation({ mutationFn: joinCommunity });

	const handleJoin = () => {
		joinMutation.mutate(data.id);
		queryClient.invalidateQueries({ queryKey: ["gikis"] });
	};

	useEffect(() => {
		if (joinMutation.isSuccess) {
			navigate(`/chat/${data.id}`, { state: { ...data } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joinMutation.isSuccess]);

	return (
		<React.Fragment>
			<div
				onClick={onOpen}
				className="flex items-center w-fit cursor-pointer select-none gap-1"
			>
				{type === 1 && (
					<>
						<span
							className="font-semibold text-orange-primary lg:text-xs text-lg"
							onClick={() => {
								setChat({
									id: data.id,
									image: data.image,
									name: data.name,
									users_count: data.users_count,
									type: data.type,
									cover_image: data.cover_image,
									short_description: data.short_description || "",
								});
								navigate(`/chat/${data.id}`, { state: { ...data } });
							}}
						>
							{t("Go to chat")}
						</span>
					</>
				)}
				{type === 0 && (
					<button
						onClick={!joinMutation.isPending ? handleJoin : () => null}
						className="group flex items-center gap-[3px] rounded p-[10px] bg-[#FF8C1E] border-[1px] border-[#FF8C1E] hover:text-[#FF8C1E] hover:bg-transparent text-white transition-colors !duration-300"
					>
						<span className="font-semibold lg:text-xs text-sm">
							{t("Join Now")}
						</span>
						{joinMutation.isPending ? (
							<Spinner size="xs" />
						) : (
							<svg
								onClick={!joinMutation.isPending ? handleJoin : () => null}
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="stroke-white group-hover:stroke-[#FF8C1E] transition-colors duration-300"
							>
								<path
									d="M7.45612 6.4408L9.45612 8.4408L7.45612 10.4408M14 8.4408C14 11.7545 11.3137 14.4408 8 14.4408C4.68629 14.4408 2 11.7545 2 8.4408C2 5.12709 4.68629 2.4408 8 2.4408C11.3137 2.4408 14 5.12709 14 8.4408Z"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</button>
				)}
			</div>
		</React.Fragment>
	);
};

export default JoinButton;
