import React from "react";
import MobileBottomBar from "../../components/boxes/MobileBottomBar";
import MobileTopBar from "../../components/boxes/MobileTopBar";
import { isSelectedChat, topBarMobileMenuState } from "../../store/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { useMediaQuery } from "@chakra-ui/react";

interface MobileLayoutProps {
	children: React.ReactNode;
}

const MobileLayout: React.FunctionComponent<MobileLayoutProps> = ({
	children,
}) => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [isSelected] = useRecoilState(isSelectedChat);
	const topBarState = useRecoilValue(topBarMobileMenuState);

	return (
		<React.Fragment>
			{isMobile && !isSelected && <MobileTopBar />}
			{children}
			{topBarState && (
				<div className="h-dvh fixed inset-0 w-screen bg-[#0000007a]" />
			)}
			{isMobile && !isSelected && <MobileBottomBar />}
		</React.Fragment>
	);
};

export default MobileLayout;
